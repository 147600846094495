<template>
  <div class="tranList">
    <el-row class="vg_mb_20">
      <el-col :md="24">
        <el-form-item label="货物总称" :prop="'tran_bulkname'">
          <el-button type="text" @click="goodsNamesEdit">点击编辑</el-button>
          <el-input disabled v-model="tranForm.tran_bulkname" type="textarea" :rows="3" show-word-limit placeholder="请填写货物总称"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="24">
        <el-form-item label="备注" prop="remark">
          <el-input v-model="tranForm.remark" :rows="5" maxlength="500" placeholder="请填写" show-word-limit type="textarea"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="24">
        <div class="vd_flex">
          <div style="width: 120px; textalign: right; paddingright: 10px">
            <div>船公司联系</div>
            <el-link :disabled="isShow" class="vg_pointer" type="primary" @click="clickSelectClau(2)">选择 </el-link>
          </div>
          <el-input v-model="tranForm.clau_bcontent" type="textarea" :rows="3" show-word-limit placeholder="请填船公司联系"></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="8">
        <el-form-item label="公司抬头" prop="cptt_aname">
          <el-input disabled v-model="tranForm.cptt_aname" placeholder="请选择公司抬头"></el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="经办人" prop="tran_stff_aid">
          <!-- <el-input disabled v-model="tranForm.tran_stff_aname" placeholder="请输入联系人"></el-input> -->
          <el-select filterable v-model="tranForm.tran_stff_aid" placeholder="请选择">
            <el-option v-for="item in stffOptionLists" :key="item.stff_id" :label="item.stff_name" :value="item.stff_id"> </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="联系电话" prop="cptt_phone">
          <el-input disabled v-model="tranForm.cptt_phone" placeholder="请输入联系电话"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="24">
        <el-form-item label="公司地址" :prop="'cptt_address_en'">
          <el-input v-model="tranForm.cptt_address_en" type="textarea" maxlength="255" :rows="3" show-word-limit placeholder="请填写公司地址"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="8">
        <el-form-item label="客户简称" prop="cust_babbr">
          <el-input disabled v-model="tranForm.cust_babbr" placeholder="暂无客户简称">
            <template slot="append">
              <el-link type="primary" :disabled="isShow" class="vg_pointer" @click="clickSelectCust(1)">选择</el-link>
            </template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="联系人" prop="cust_bcontact">
          <el-input disabled v-model="tranForm.cust_bcontact" placeholder="请输入联系人"></el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="联系电话" prop="cust_bphone">
          <el-input disabled v-model="tranForm.cust_bphone" placeholder="请输入联系电话"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="8">
        <el-form-item label="客户全称" prop="cust_bname">
          <el-input v-model="tranForm.cust_bname" placeholder="请输入客户全称"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="24">
        <el-form-item label="客户地址" :prop="'cust_baddr'">
          <el-input v-model="tranForm.cust_baddr" type="textarea" :rows="3" show-word-limit placeholder="请填写客户地址"> </el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row class="vg_mb_20">
      <el-col :md="12">
        <el-form-item label="通知人" prop="inform_name">
          <el-input v-model="tranForm.inform_name" placeholder="暂无通知人简称">
            <template slot="append">
              <el-link type="primary" :disabled="isShow" class="vg_pointer" @click="clickSelectCust(2)">选择</el-link>
            </template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item label="收货人" prop="cnee_name">
          <el-input v-model="tranForm.cnee_name" placeholder="请输入收货人"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="12">
        <el-col :md="24">
          <div class="vd_flex">
            <div style="width: 120px; textalign: right; paddingright: 10px">
              <div>通知人地址</div>
              <el-link :disabled="isShow" class="vg_pointer" type="primary" @click="clickSelectClau(3)">选择 </el-link>
            </div>
            <el-input v-model="tranForm.inform_site" type="textarea" :rows="3" show-word-limit placeholder="请填写通知人地址"> </el-input>
          </div>
        </el-col>
      </el-col>
      <el-col :md="12">
        <div class="vd_flex">
          <div style="width: 120px; textalign: right; paddingright: 10px">
            <div>收货人地址</div>
            <el-link :disabled="isShow" class="vg_pointer" type="primary" @click="clickSelectClau(4)">选择 </el-link>
          </div>
          <el-input v-model="tranForm.cnee_site" type="textarea" :rows="3" show-word-limit placeholder="请输入收货人地址"></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="12">
        <el-form-item label="香港公司抬头" :prop="'hk_cptt_id'">
          <el-select v-model="tranForm.hk_cptt_id" size="small" placeholder="请选择香港公司抬头" filterable @change="updateHkCpttBank">
            <el-option v-for="item in cpttList" :key="item.cptt_id" :value="item.cptt_id" :label="item.cptt_name"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item label="香港发票号码" prop="hk_invoice_no">
          <el-input v-model="tranForm.hk_invoice_no" placeholder="请输入香港发票号码" maxlength="20" show-word-limit></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="24">
        <el-form-item label="香港公司银行信息" prop="hk_cptt_bank">
          <el-input v-model="tranForm.hk_cptt_bank" type="textarea" :rows="3" show-word-limit placeholder="请输入香港公司银行信息" maxlength="500"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="12">
        <el-form-item label="香港公司客户名称" :prop="'hk_cust_id'">
          <el-select v-model="tranForm.hk_cust_id" size="small" placeholder="请选择香港公司抬头" filterable @change="updateHkCustAddr">
            <el-option v-for="item in custList" :key="item.cust_id" :value="item.cust_id" :label="item.cust_name"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="12">
        <el-form-item label="香港公司货物总称" prop="hk_bulkname">
          <el-input v-model="tranForm.hk_bulkname" placeholder="请输入香港发票号码" maxlength="100" show-word-limit></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="24">
        <el-form-item label="香港公司客户地址" prop="hk_cust_addr">
          <el-input v-model="tranForm.hk_cust_addr" type="textarea" :rows="3" show-word-limit maxlength="200" placeholder="请输入香港公司银行信息"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="24">
        <el-form-item label="唛头" :prop="'cust_shipmark'">
          <el-input v-model="tranForm.cust_shipmark" type="textarea" :rows="3" show-word-limit placeholder="唛头"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="24">
        <el-form-item label="银行名称" :prop="'cptt_bank_name'">
          <el-select v-model="tranForm.cptt_bank_name" @change="cpttBankChange">
            <el-option v-for="(item, index) in cptt_bank_list" :key="index" :value="item.cptt_bank_name" :label="item.cptt_bank_name"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="24">
        <el-form-item label="银行信息" :prop="'cptt_bank_account'">
          <el-input v-model="tranForm.cptt_bank_account" type="textarea" :rows="3" show-word-limit placeholder="请填写银行信息"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="8">
        <el-form-item label="送货仓库" prop="inst_cabbr">
          <el-input v-model="tranForm.inst_cabbr" placeholder="暂无送货仓库">
            <template slot="append">
              <el-link type="primary" :disabled="isShow" class="vg_pointer" @click="dialogInstVisible = true">选择</el-link>
            </template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="送货人" prop="inst_ccontact">
          <el-input disabled v-model="tranForm.inst_ccontact" placeholder="请输入负责人"></el-input>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <el-form-item label="送货人电话" prop="inst_ccphone">
          <el-input disabled v-model="tranForm.inst_ccphone" placeholder="请输入联系电话"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row class="vg_mb_20">
      <el-col :md="24">
        <el-form-item label="送货地址" :prop="'inst_caddr'">
          <el-input v-model="tranForm.inst_caddr" type="textarea" :rows="3" show-word-limit placeholder="请填写送货地址"> </el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row class="vg_mb_20">
      <el-col :md="24">
        <div class="vd_flex">
          <div style="width: 120px; textalign: right; paddingright: 10px">
            <div>送货资料</div>
            <el-link :disabled="isShow" class="vg_pointer" type="primary" @click="clickSelectClau(1)">选择 </el-link>
          </div>
          <el-input v-model="tranForm.clau_content" type="textarea" :rows="3" show-word-limit placeholder="请填送货资料"></el-input>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="客户信息" :visible.sync="dialogCustVisible" width="70%">
      <ImportCust @importCustChange="importCustChange" />
    </el-dialog>
    <el-dialog title="仓库信息" :visible.sync="dialogInstVisible" width="70%">
      <ImportWarehouse @importInstChange="importInstChange" />
    </el-dialog>
    <el-dialog title="文本信息" :visible.sync="dialogClauInfoVisible" width="70%">
      <importClauInfo @importClauInfoChange="importClauInfoChange" />
    </el-dialog>
    <el-dialog title="货物总称" :visible.sync="goodsNamesVisible" width="20%" center :before-close="closeDialogAndSaveData">
      <DynamicForm
        v-bind="$attrs"
        :form-list="defaultFrom"
        :split="24"
        :need-btn="false"
        ref="defaultFrom"
        :lableWidth="`100px`"
        class="dynamicForm"
        :selectOptionStyle="`font-size:24px`"
      ></DynamicForm>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goodsNamesSave">确 定</el-button>
        <el-button type="danger" @click="defaultFrom.forEach(item => (item.model = ''))">清 空</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { get } from '@api/request';
import { custAPI } from '@/api/modules/cust';
import { cpttAPI } from '@api/modules/comptitle';
import { useCustMixin, useStffMixin } from '@/assets/js/mixin/initMixin';
import ImportInst from './ImportInst.vue';
import ImportCust from './ImportCust.vue';
import importClauInfo from './importClauInfo.vue';
import DynamicForm from '@/components/DynamicForm.vue';
import { removeAroundSpaces } from '@assets/js/regExUtil';
import ImportWarehouse from '@/views/TransportationManagement/TranManage/TabChild/Component/ImportWarehouse.vue';

export default {
  name: 'tranList',
  mixins: [useStffMixin, useCustMixin],
  props: {
    tranForm: {
      type: Object,
      required: true
    },
    stffOptionLists: {
      type: Array,
      required: true
    },
    isShow: {
      type: Boolean,
      required: true
    },
    cptt_bank_list: {
      type: Array,
      required: true
    },
    custList: {
      type: Array,
      required: true
    },
    cpttList: {
      type: Array,
      required: true
    }
  },
  components: {
    ImportCust,
    ImportInst,
    importClauInfo,
    DynamicForm,
    ImportWarehouse
  },
  data() {
    return {
      defaultFrom: [
        {
          label: '',
          prop: 'tran_bulkname',
          itemType: 'input',
          model: '',
          size: 'small',
          inputAppend: '批量填充',
          appendClick: () => {
            let temp = this.defaultFrom[0].model;
            this.defaultFrom.forEach(item => (item.model = temp));
          }
        },
        { label: '报关发票:', prop: 'declar_invoice', itemType: 'input', model: '', size: 'small' },
        { label: '报关装箱单:', prop: 'declar_packing', itemType: 'input', model: '', size: 'small' },
        { label: '出口货物委托:', prop: 'export_commission', itemType: 'input', model: '', size: 'small' },
        { label: '结汇发票:', prop: 'optim_invoice', itemType: 'input', model: '', size: 'small' },
        { label: '结汇装箱单:', prop: 'optim_packing', itemType: 'input', model: '', size: 'small' },
        { label: '商检发票:', prop: 'inspection_invoce', itemType: 'input', model: '', size: 'small' },
        { label: '商检销售合同:', prop: 'inspe_sales_contr', itemType: 'input', model: '', size: 'small' },
        { label: '商检装箱单:', prop: 'inspection_packing', itemType: 'input', model: '', size: 'small' },
        { label: '报关明细:', prop: 'declar_detail', itemType: 'input', model: '', size: 'small' },
        { label: '辅料信息:', prop: 'ingred_message', itemType: 'input', model: '', size: 'small' }
      ],
      goodsNamesVisible: false,
      selectionsList: [],
      prodUnitList: [],
      value1: '是',
      value2: '否',
      flagShow: true,
      dialogCustVisible: false,
      dialogInstVisible: false,
      dialogClauInfoVisible: false
    };
  },
  created() {
    this.initData();
  },
  methods: {
    goodsNamesEdit() {
      this.tranForm.tran_bulkname.split(',').forEach((item, index) => {
        let i = index + 1;
        if (i < this.defaultFrom.length) {
          this.defaultFrom[i].model = item?.split(':')[1] ?? '';
        }
      });
      this.goodsNamesVisible = true;
    },
    goodsNamesSave() {
      this.tranForm.tran_bulkname = '';
      this.defaultFrom.forEach(item => {
        if (item.label) {
          this.tranForm.tran_bulkname += `${item.label} ${removeAroundSpaces(item.model)}, `;
        }
      });
      this.goodsNamesVisible = false;
    },
    closeDialogAndSaveData() {
      this.tranForm.tranTerms = this.$refs.defaultFrom.getFormModel();
      this.goodsNamesVisible = false;
    },
    initData() {},
    cpttBankChange(val) {
      for (let i = 0; i < this.cptt_bank_list.length; i++) {
        if (this.cptt_bank_list[i].cptt_bank_name === val) {
          this.tranForm.cptt_bank_account = this.cptt_bank_list[i].cptt_bank_account;
        }
      }
    },
    //仓库
    importInstChange(row) {
      this.dialogInstVisible = false;
      this.$emit('tranClauChange2', row);
    },
    //选择客户
    clickSelectCust(val) {
      this.importCustType = val;
      this.dialogCustVisible = true;
    },
    //双击选中 客户
    importCustChange(row) {
      this.$emit('tranClauChange', row, this.importCustType);
      this.dialogCustVisible = false;
    },
    //选择文本信息
    clickSelectClau(val) {
      this.importInfoType = val;
      this.dialogClauInfoVisible = true;
    },
    //选中文本信息
    importClauInfoChange(row) {
      if (this.importInfoType === 2) {
        if (this.tranForm.dept_name === '业务三部') this.tranForm.clau_bcontent += row.clau_content;
        else this.tranForm.clau_bcontent = row.clau_content; //船公司联系
      } else if (this.importInfoType === 1) {
        this.tranForm.clau_content = row.clau_content; //送货资料
      } else if (this.importInfoType === 3) {
        //通知人地址
        this.tranForm.inform_site = row.clau_content;
      } else if (this.importInfoType === 4) {
        //收货人地址
        this.tranForm.cnee_site = row.clau_content;
      }
      this.dialogClauInfoVisible = false;
      this.$emit('tranClauChange3', row, this.importInfoType);
    },
    //联动香港公司银行信息
    updateHkCpttBank(val) {
      this.cpttList
        .find(x => x.cptt_id === val)
        .cptt_bank_list.forEach(item => {
          if ((item.cptt_bank_default_flag = 1)) {
            this.tranForm.hk_cptt_bank = item.cptt_bank_account;
          }
        });
    },
    //联动香港公司客户地址
    updateHkCustAddr(val) {
      this.tranForm.hk_cust_addr = this.custList.find(x => x.cust_id === val).cust_addr;
    }
  },
  //获取公司抬头
  getCptt() {
    get(cpttAPI.getAllCpttsV1)
      .then(res => {
        if (res.data.code === 0) {
          this.cpttList = res.data.data;
          return;
        }
        this.$message.error(res.data.msg);
      })
      .catch(res => {
        this.$message.error(res.data.msg);
      });
  },
  //获取客户信息
  getCust() {
    get(custAPI.getAllCustsV1)
      .then(res => {
        if (res.data.code === 0) {
          this.custList = res.data.data.list;
          return;
        }
        this.$message.error(res.data.code);
      })
      .catch(res => {
        this.$message.error(res.data.code);
      });
  }
};
</script>

<style scoped lang="scss">
::v-deep .showRow {
  display: none;
}

.vd_flex {
  display: flex;
}

.vd_wid {
  width: 50px;
}
</style>
