export const myMixin = {
  data() {
    return {
      fixed: true,
      fixedIndex: -1
    };
  },
  methods: {
    cellClassName({ row, column, rowIndex, columnIndex }) {
      column.index = columnIndex;
      if (column.fixed) {
        return 'fixed-cell';
      }
    },
    headerClickHandel(target, column, event) {
      this.fixed = !this.fixed;
      this.fixedIndex = column.index;
      this.$refs[target].$children.forEach(item => {
        if (item.columnConfig) {
          if (item.columnConfig.index <= this.fixedIndex) {
            item.columnConfig.fixed = true;
          } else {
            item.columnConfig.fixed = false;
          }
        }
      });
    }
  }
};
