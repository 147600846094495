<template>
  <div class="ProdDetail">
    <div class="vg_mb_16">
      <!--<el-button type="primary" plain size="small" @click="clickImport">导入销售合同</el-button>-->
      <el-button type="success" plain @click="getSconSon" size="small">同步销售合同</el-button>
      <el-button type="danger" plain size="small" @click="deleteProd">删除</el-button>
      <el-button plain size="small" @click="initialization">初始化箱封号</el-button>
      <el-button plain size="small" @click="toUpdateBox">更新全部行的外箱商品数、净毛体信息</el-button>
      <el-button plain size="small" @click="toUpdatePrice">更新商品库价格</el-button>
      <el-button class="tb-btn" size="small" @click="moveUp">上移</el-button>
      <el-button class="tb-btn" size="small" @click="moveDown">下移</el-button>
      <el-button class="tb-btn" size="small" :disabled="false" @click="exportToExcel('allTable')">导出到Excel</el-button>
      <el-button type="primary" size="small" @click="copyProd">复制</el-button>
    </div>
    <DynamicUTable
      ref="ProdDetail"
      id="ProdDetail"
      :tableRowKey="'tran_prod_list'"
      :form="tranForm"
      :tableData="tranForm.tran_prod_list"
      :columns="chuyunTableProperties"
      :isShow="isShow"
      :needPagination="false"
      :needSearch="false"
      :show-summary="true"
      :needAllColumnSummaryToFixed="3"
      :summariesColumns="['prod_num', 'prod_ship', 'prod_subtotal', 'prod_box_num', 'prod_tgweight', 'prod_tnweight', 'prod_tvol']"
      :needSummaryValues="['prod_subtotal']"
      :needClosedUpdate="true"
      @getSummary_prod_subtotal="val => $emit('prodTotalPriceChange', val)"
      @selection-change="handleSelectionChange"
      :computed-data="{ tran_prod_fee: calculateTranProdFeed }"
      :row-style="setRowStyle"
      :openDefaultCommonSlots="false"
      :need-index="false"
      @copyChange="({ tIndex, prop }) => (prop === 'prod_qpc' ? prodBoxNum(tIndex) : '')"
    >
      <template v-slot:scon_no="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.scon_no'">
          <el-input disabled v-model.trim="scope.row.scon_no" maxlength="20" show-word-limit placeholder="暂无销售合同号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:podr_no="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.podr_no'">
          <el-input disabled v-model.trim="scope.row.podr_no" maxlength="20" show-word-limit placeholder="暂无采购合同号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:supp_abbr="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.supp_abbr'">
          <el-input disabled v-model.trim="scope.row.supp_abbr" maxlength="20" show-word-limit placeholder="暂无主供应商"></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_cust_ono="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.scon_cust_ono'" :rules="{ required: true, message: ' ' }">
          <el-input disabled v-model="scope.row.scon_cust_ono" maxlength="20" show-word-limit placeholder="暂无原始订单号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_cust_no="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.scon_cust_no'" :rules="{ required: true, message: ' ' }">
          <el-input v-model.trim="scope.row.scon_cust_no" maxlength="20" show-word-limit placeholder="暂无新订单号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_cust_no="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_cust_no'" :rules="{ required: true, message: ' ' }">
          <el-input disabled v-model.trim="scope.row.prod_cust_no" maxlength="20" show-word-limit placeholder="暂无客户货号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_new_cust_no="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_new_cust_no'">
          <el-input v-model.trim="scope.row.prod_new_cust_no" maxlength="20" show-word-limit placeholder="暂无客户货号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_no="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_no'">
          <el-input disabled v-model.trim="scope.row.prod_no" maxlength="20" show-word-limit placeholder="暂无我司货号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_ename="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_ename'" :rules="{ required: true, message: ' ' }">
          <el-input v-model.trim="scope.row.prod_ename" maxlength="100" show-word-limit placeholder="暂无英文名称"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_num="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_num'" :rules="{ required: true, message: ' ' }">
          <el-input
            disabled
            v-model.trim="scope.row.prod_num"
            @input="scope.row.prod_num = helper.keepTNum(scope.row.prod_num)"
            maxlength="10"
            show-word-limit
            placeholder="暂无订单数量"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_ship="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_ship'" :rules="{ required: true, message: ' ' }">
          <!--:max="scope.row.prod_num"-->
          <el-input
            @change="
              prodSubtotal(scope.$index, 0);
              $refs.ProdDetail.$refs.multiTable.updateData();
            "
            v-model.trim="scope.row.prod_ship"
            maxlength="10"
            show-word-limit
            @input="scope.row.prod_ship = keep6Decimal(scope.row.prod_ship)"
            placeholder="暂无出运数量"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_unit="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_unit'" :rules="{ required: true, message: ' ' }">
          <el-select v-model="scope.row.prod_unit" placeholder="请选择数量单位" size="small" filterable>
            <el-option v-for="item in companyList" :value="item.param1" :key="item.id" :label="item.param1"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:prod_qpc="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_qpc'" :rules="{ required: true, message: ' ' }">
          <el-input
            @change="
              prodBoxNum(scope.$index);
              $refs.ProdDetail.$refs.multiTable.updateData();
            "
            v-model.trim="scope.row.prod_qpc"
            maxlength="10"
            show-word-limit
            @input="scope.row.prod_qpc = helper.keepTNum(scope.row.prod_qpc)"
            placeholder="暂无装箱数"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_box_num="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_box_num'">
          <el-input v-model="scope.row.prod_box_num" maxlength="10" show-word-limit placeholder="暂无箱数"></el-input>
        </el-form-item>
      </template>
      <template v-slot:tran_prod_ctns="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.tran_prod_ctns'">
          <el-input disabled v-model.trim="scope.row.tran_prod_ctns" maxlength="20" show-word-limit placeholder="暂无起始箱号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:tran_prod_ctne="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.tran_prod_ctne'">
          <el-input disabled v-model.trim="scope.row.tran_prod_ctne" maxlength="20" show-word-limit placeholder="暂无结束箱号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_usd="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_usd'" :rules="{ required: true, message: ' ' }">
          <el-input
            @change="
              prodSubtotal(scope.$index, 1);
              $refs.ProdDetail.$refs.multiTable.updateData();
            "
            v-model.trim="scope.row.prod_usd"
            @input="val => (scope.row.prod_usd = keep2Decimal(val))"
            maxlength="8"
            show-word-limit
            placeholder="暂无单价(美元)"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_subtotal="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_subtotal'">
          <el-input disabled v-model.trim="scope.row.prod_subtotal" maxlength="20" show-word-limit placeholder="暂无金额(美元)"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_box_unit="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_box_unit'" :rules="{ required: true, message: ' ' }">
          <el-select v-model="scope.row.prod_box_unit" placeholder="请选择数量单位" size="small" filterable>
            <el-option v-for="item in companyList" :value="item.param1" :key="item.id" :label="item.param1"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:prod_gweight="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_gweight'" :rules="{ required: true, message: ' ' }">
          <el-input
            @change="
              totalGrossWeightChange(scope.$index);
              $refs.ProdDetail.$refs.multiTable.updateData();
            "
            v-model.trim="scope.row.prod_gweight"
            maxlength="8"
            show-word-limit
            @blur="scope.row.prod_gweight = helper.calcNum3(scope.row.prod_gweight, 2, 10000)"
            @input="scope.row.prod_gweight = helper.keepTNum2(scope.row.prod_gweight)"
            placeholder="暂无每箱毛重"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_tgweight="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_tgweight'" :rules="{ required: true, message: ' ' }">
          <el-input
            v-model.trim="scope.row.prod_tgweight"
            maxlength="18"
            @input="scope.row.prod_tgweight = helper.keepTNum2(scope.row.prod_tgweight)"
            @blur="scope.row.prod_tgweight = helper.calcNum3(scope.row.prod_tgweight, 2, 10000000)"
            show-word-limit
            placeholder="暂无总毛重"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_nweight="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_nweight'" :rules="{ required: true, message: ' ' }">
          <el-input
            @change="
              totalNetWeight(scope.$index);
              $refs.ProdDetail.$refs.multiTable.updateData();
            "
            v-model.trim="scope.row.prod_nweight"
            maxlength="8"
            show-word-limit
            @input="scope.row.prod_nweight = helper.keepTNum2(scope.row.prod_nweight)"
            @blur="scope.row.prod_nweight = helper.calcNum3(scope.row.prod_nweight, 2, 10000)"
            placeholder="暂无每箱净重"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_tnweight="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_tnweight'" :rules="{ required: true, message: ' ' }">
          <el-input
            v-model.trim="scope.row.prod_tnweight"
            maxlength="18"
            @blur="scope.row.prod_tnweight = helper.calcNum3(scope.row.prod_tnweight, 2, 100000000)"
            @input="scope.row.prod_tnweight = helper.keepTNum2(scope.row.prod_tnweight)"
            show-word-limit
            placeholder="暂无总净重"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_boxl="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_boxl'" :rules="{ required: true, message: ' ', validator: rulesCopy }">
          <el-input
            @change="val => calculateVolume(scope.$index, { val: val, name: 'l', scon_prod_id: scope.row.scon_prod_id })"
            v-model.trim="scope.row.prod_boxl"
            maxlength="7"
            @input="val => (scope.row.prod_boxl = helper.keepTNum2(val))"
            @blur="scope.row.prod_boxl = helper.calcNum2(scope.row.prod_boxl, 2, 10000000)"
            show-word-limit
            placeholder="暂无长度"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_boxw="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_boxw'" :rules="{ required: true, message: ' ', validator: rulesCopy }">
          <el-input
            @change="val => calculateVolume(scope.$index, { val: val, name: 'w', scon_prod_id: scope.row.scon_prod_id })"
            v-model.trim="scope.row.prod_boxw"
            maxlength="7"
            @input="val => (scope.row.prod_boxw = helper.keepTNum2(val))"
            @blur="scope.row.prod_boxw = helper.calcNum2(scope.row.prod_boxw, 2, 10000000)"
            show-word-limit
            placeholder="暂无宽度"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_boxh="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_boxh'" :rules="{ required: true, message: ' ', validator: rulesCopy }">
          <el-input
            @change="val => calculateVolume(scope.$index, { val: val, name: 'h', scon_prod_id: scope.row.scon_prod_id })"
            v-model.trim="scope.row.prod_boxh"
            maxlength="7"
            @input="val => (scope.row.prod_boxh = helper.keepTNum2(val))"
            @blur="scope.row.prod_boxh = helper.calcNum2(scope.row.prod_boxh, 2, 10000000)"
            show-word-limit
            placeholder="暂无高度"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_vol="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_vol'" :rules="{ required: true, message: ' ' }">
          <el-input
            @change="
              totalVolumeChange(scope.$index);
              $refs.ProdDetail.$refs.multiTable.updateData();
            "
            v-model.trim="scope.row.prod_vol"
            maxlength="9"
            show-word-limit
            @blur="scope.row.prod_vol = helper.calcNum3(scope.row.prod_vol, 4, 10000)"
            @input="scope.row.prod_vol = helper.keepTNum2(scope.row.prod_vol)"
            placeholder="暂无每箱体积"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_tvol="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.prod_tvol'" :rules="{ required: true, message: ' ' }">
          <el-input
            v-model.trim="scope.row.prod_tvol"
            maxlength="18"
            @blur="scope.row.prod_tvol = helper.calcNum3(scope.row.prod_tvol, 4, 100000000)"
            @input="scope.row.prod_tvol = helper.keepTNum2(scope.row.prod_tvol)"
            show-word-limit
            placeholder="暂总体积"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:tran_prod_remark="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.tran_prod_remark'">
          <el-input v-model.trim="scope.row.tran_prod_remark" maxlength="50" show-word-limit placeholder="暂无备注"></el-input>
        </el-form-item>
      </template>
      <template v-slot:tran_prod_fee="scope">
        <el-form-item label-width="0" :prop="'tran_prod_list.' + scope.$index + '.tran_prod_fee'">
          <el-input disabled :value="calculateTranProdFeed(scope.$index)" maxlength="13" show-word-limit placeholder="暂无验货费"></el-input>
        </el-form-item>
      </template>
    </DynamicUTable>
    <el-table v-show="false" ref="multiTable1" :data="tranForm.tran_prod_list" id="multiTable2" border>
      <el-table-column label="序号" width="50" align="center">
        <template v-slot="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="销售合同号" prop="scon_no" />
      <el-table-column label="采购合同号" prop="podr_no" />
      <el-table-column label="主要供应商" prop="supp_abbr" />
      <el-table-column label="起始箱号" prop="tran_prod_ctns" />
      <el-table-column label="结束箱号" prop="tran_prod_ctne" />
      <el-table-column label="原始订单号" prop="scon_cust_ono" />
      <el-table-column label="新订单号" prop="scon_cust_no" />
      <el-table-column label="客户货号" prop="prod_cust_no" />
      <el-table-column label="我司货号" prop="prod_no" />
      <!--      <el-table-column label="部件名" prop="prod_partno" />-->
      <el-table-column label="英文名称" prop="prod_ename" />
      <el-table-column label="英文规格" prop="prod_espec" />
      <el-table-column label="订单数量" prop="prod_num" />
      <el-table-column label="出运数量" prop="prod_ship" />
      <el-table-column label="数量单位" prop="prod_unit" />
      <el-table-column label="单价(美元)" prop="prod_usd" />
      <el-table-column label="金额(美元)" prop="prod_subtotal" />
      <el-table-column label="装箱数" prop="prod_qpc" />
      <el-table-column label="箱数" prop="prod_box_num" />
      <el-table-column label="包装单位" prop="prod_box_unit" />
      <el-table-column label="每箱毛重(KGS)" prop="prod_gweight" />
      <el-table-column label="总毛重(KGS)" prop="prod_tgweight" />
      <el-table-column label="每箱净重(KGS)" prop="prod_nweight" />
      <el-table-column label="总净重(KGS)" prop="prod_tnweight" />
      <el-table-column label="长度(CM)" prop="prod_boxl" />
      <el-table-column label="宽度(CM)" prop="prod_boxw" />
      <el-table-column label="高度(CM)" prop="prod_boxh" />
      <el-table-column label="每箱体积(CBM)" prop="prod_vol" />
      <el-table-column label="总体积(CBM)" prop="prod_tvol" />
      <el-table-column label="备注" prop="tran_prod_remark" />
      <el-table-column label="验货费" prop="tran_prod_fee" />
    </el-table>
    <el-dialog title="销售合同" :visible.sync="dialogVisible" width="70%">
      <TranPartImport ref="TranPartImport" @childConfirm="childConfirm" :tranForm="tranForm" @childChanel="childChanel"></TranPartImport>
    </el-dialog>
  </div>
</template>

<script>
import { get, getNoCatch } from '@api/request';
import { tranAPI } from '@/api/modules/tran';
import { sconAPI } from '@/api/modules/scon';
import { myMixin } from '@/assets/js/mixin/tabMixin.js';
import TranPartImport from './TranPartImport.vue';
import { BigNumber } from 'bignumber.js';
import htmlToExcel from '@/plugins/htmlToExcel';
import textEnlargement from '@/views/component/textEnlargement.vue';
import { optnAPI } from '@api/modules/optn';
import { keep2Decimal, keep6Decimal } from '@assets/js/regExUtil';
import SearchTable from '@/components/table/SearchTableV2.vue';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { chuyunTableProperties } from '@/views/TransportationManagement/TranManage/tran';
import { EventBus } from '@/main';
import { cloneDeep, debounce } from 'lodash';

export default {
  name: 'ProdDetail',
  mixins: [myMixin],
  props: {
    tranForm: {
      type: Object,
      required: true
    },
    newTranProdId: {
      type: Number,
      required: true
    },
    companyList: {
      type: Array,
      required: true
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    calculateTranProdFeed() {
      return index => {
        let prodNum = this.tranForm.tran_prod_list[index].prod_num;
        let result = 0;
        this.custCurrencyList.forEach(({ param1, param2, param3 }) => {
          if (prodNum >= param1 && prodNum < param2) {
            this.tranForm.tran_prod_list[index].tran_prod_fee = Number(param3);
            result = param3;
          }
        });
        return result;
      };
    }
  },
  async mounted() {
    // 获取币种
    await getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10027 }).then(({ data }) => {
      this.custCurrencyList = data.form.optn_cntt_list;
    });
    await this.tranForm.tran_prod_list.forEach((item, index) => {
      this.calculateTranProdFeed(index);
    });
  },
  data() {
    var checkAge = (rule, value, callback) => {
      if (Number(value) < 10) {
        return callback(new Error('必须大于10'));
      } else {
        callback();
      }
    };
    return {
      chuyunTableProperties: chuyunTableProperties,
      custCurrencyList: [],
      dialogVisible: false,
      selectionsList: [],
      suppOptionList: [],
      delList: [],
      rulesCopy: checkAge,
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      }
    };
  },
  components: {
    DynamicUTable,
    SearchTable,
    TranPartImport,
    textEnlargement
  },
  methods: {
    keep6Decimal,
    keep2Decimal,
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    //列总和
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      let prod_subtotal_index = '';
      const temp = ['prod_num', 'prod_ship', 'prod_subtotal', 'prod_box_num', 'prod_tgweight', 'prod_tnweight', 'prod_tvol'];
      columns.forEach((column, index) => {
        if (column.property === 'prod_subtotal') {
          prod_subtotal_index = index;
        }
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        let values = data.map(item => Number(item[column.property]));
        if (temp.includes(column.property)) {
          let initValue = new BigNumber(0);
          sums[index] = values
            .reduce((x, item) => {
              return x.plus(item);
            }, initValue)
            .toFixed(4);
        }
      });
      if (Number(sums[prod_subtotal_index])) this.$emit('prodTotalPriceChange', sums[prod_subtotal_index]);
      return sums;
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.tranForm.tran_prod_list[val1].prod_espec;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.tranForm.tran_prod_list[this.textEnlargementNum].prod_espec = val;
      }
    },
    //导入外销合同
    clickImport() {
      this.dialogVisible = true;
      setTimeout(() => {
        this.$refs.TranPartImport.searchForm.cust_id = this.tranForm.cust_id;
        this.$refs.TranPartImport.initData();
      }, 100);
    },
    // 关闭弹框
    childChanel() {
      this.dialogVisible = false;
    },
    //确认传值
    childConfirm(value) {
      let val = JSON.parse(JSON.stringify(value));
      let newTranProdId = this.newTranProdId;
      let sconNoList = [];
      val = val.filter(item => this.tranForm.tran_prod_list.every(item1 => item.scon_prod_id !== item1.scon_prod_id));
      for (let i in val) {
        val[i].tran_prod_ctns = null;
        val[i].tran_prod_ctne = null;
        val[i].scon_cust_ono = null;
        val[i].prod_tgweight = null;
        // val[i].prod_ship = val[i].prod_ship;
        val[i].prod_box_unit = 'CTNS';
        val[i].prod_tnweight = null;
        val[i].prod_tvol = null;
        val[i].tran_prod_remark = null;
        val[i].tran_prod_fee = null;
        val[i].scon_cust_no = val[i].scon_cust_no + '';
        val[i].scon_cust_ono = val[i].scon_cust_no;
        if (Number(val[i].prod_num) && Number(val[i].prod_qpc)) {
          val[i].prod_box_num = Math.ceil(Number(val[i].prod_num) / Number(val[i].prod_qpc));
        } else {
          val[i].prod_box_num = 0;
        }
        sconNoList.push(val[i].scon_no);
        val[i].tran_prod_id = newTranProdId;
        val[i].prod_subtotal = this.helper.calcNum2((Number(val[i].prod_usd) * 100000 * Number(val[i].prod_ship)) / 100000);
        val[i].prod_box_num = Math.ceil(Number(val[i].prod_ship) / Number(val[i].prod_qpc));
        val[i].prod_tgweight = this.helper.calcNum3((Number(val[i].prod_gweight) * 100000 * Number(val[i].prod_box_num)) / 100000);
        val[i].prod_tnweight = this.helper.calcNum3((Number(val[i].prod_nweight) * 100000 * Number(val[i].prod_box_num)) / 100000);
        let numVol = new BigNumber(1);
        numVol = numVol
          .times(val[i].prod_boxl ? val[i].prod_boxl : 0)
          .times(val[i].prod_boxw ? val[i].prod_boxw : 0)
          .times(val[i].prod_boxh ? val[i].prod_boxh : 0)
          .div(1000000)
          .toNumber();
        val[i].prod_vol = this.helper.calcNum3(numVol);
        val[i].prod_tvol = this.helper.calcNum3((Number(val[i].prod_vol) * 100000 * Number(val[i].prod_box_num)) / 100000);
        let freeList = val[i].scon_fees_list.filter(item => this.tranForm.tran_fees_list.every(item1 => item.scon_fees_id !== item1.scon_fees_id));
        // let mtrbList = val[i].scon_mtrb_list.filter(item =>
        //   this.tranForm.tran_mtrb_list.every(item1 => item.scon_mtrb_id !== item1.scon_mtrb_id)
        // );
        for (let j = 0; j < val[i].scon_part_list.length; j++) {
          val[i].scon_part_list[j].tran_prod_id = val[i].tran_prod_id;
          val[i].scon_part_list[j].prod_box_num = Math.ceil(Number(val[i].scon_part_list[j].prod_ship) / Number(val[i].scon_part_list[j].prod_qpc));
          val[i].scon_part_list[j].scon_cust_ono = val[i].scon_part_list[j].scon_cust_no;
          val[i].scon_part_list[j].tran_part_main = val[i].scon_part_list[j].tran_part_main + '';
          val[i].scon_part_list[j].tran_part_claim = val[i].scon_part_list[j].tran_part_main;
          val[i].scon_part_list[j].scon_cust_ono = val[i].scon_part_list[j].scon_cust_no;
          // val[i].scon_part_list[j].prod_ship = val[i].scon_part_list[j].prod_num;
          val[i].scon_part_list[j].prod_box_unit = 'CTNS';
          val[i].scon_part_list[j].prod_subtotal = this.helper.calcNum2(
            (Number(val[i].scon_part_list[j].prod_dusd) * 100000 * Number(val[i].scon_part_list[j].prod_ship)) / 100000
          );
          val[i].scon_part_list[j].prod_box_num = Math.ceil(Number(val[i].scon_part_list[j].prod_ship) / Number(val[i].scon_part_list[j].prod_qpc));
          val[i].scon_part_list[j].prod_tgweight = this.helper.calcNum3(
            (Number(val[i].scon_part_list[j].prod_gweight) * 100000 * Number(val[i].scon_part_list[j].prod_box_num)) / 100000
          );
          val[i].scon_part_list[j].prod_tnweight = this.helper.calcNum3(
            (Number(val[i].scon_part_list[j].prod_nweight) * 100000 * Number(val[i].scon_part_list[j].prod_box_num)) / 100000
          );
          val[i].scon_part_list[j].prod_saprice = this.helper.haveFour(val[i].scon_part_list[j].prod_usd);
          if (val[i].scon_part_list[j].tran_part_claim === '0') {
            val[i].scon_part_list[j].prod_boxl = '0';
            val[i].scon_part_list[j].prod_boxw = '0';
            val[i].scon_part_list[j].prod_boxh = '0';
            val[i].scon_part_list[j].prod_vol = '0';
            val[i].scon_part_list[j].prod_tvol = '0';
          } else if (val[i].scon_part_list[j].tran_part_claim === '1') {
            let num = new BigNumber(1);
            num = num
              .times(val[i].scon_part_list[j].prod_boxl ? val[i].scon_part_list[j].prod_boxl : 0)
              .times(val[i].scon_part_list[j].prod_boxw ? val[i].scon_part_list[j].prod_boxw : 0)
              .times(val[i].scon_part_list[j].prod_boxh ? val[i].scon_part_list[j].prod_boxh : 0)
              .div(1000000)
              .toNumber();
            val[i].scon_part_list[j].prod_vol = this.helper.calcNum3(num);
            val[i].scon_part_list[j].prod_tvol = this.helper.calcNum3(
              (Number(val[i].scon_part_list[j].prod_vol) * 100000 * Number(val[i].scon_part_list[j].prod_box_num)) / 100000
            );
          }
        }
        this.tranForm.tran_fees_list = this.tranForm.tran_fees_list.concat(freeList);
        // this.tranForm.tran_mtrb_list = this.tranForm.tran_mtrb_list.concat(mtrbList);
        this.$set(this.tranForm, 'tran_part_list', this.tranForm.tran_part_list.concat(val[i].scon_part_list));
        newTranProdId--;
      }
      this.$set(this.tranForm, 'tran_prod_list', this.tranForm.tran_prod_list.concat(val));
      let listCopy = val.filter(item => this.tranForm.tran_insp_list.every(item1 => item.scon_prod_id !== item1.scon_prod_id));
      this.tranForm.tran_insp_list = this.tranForm.tran_insp_list.concat(listCopy);
      this.reductionKey();
      this.$emit('newTranProdIdChange', newTranProdId);
      this.$emit('sconNoTypeListChange', sconNoList);
      this.dialogVisible = false;
      EventBus.$emit('updatePart', this.tranForm.tran_part_list);
      EventBus.$emit('updateInsp', this.tranForm.tran_insp_list);
    },
    // 导出excel
    exportToExcel(val) {
      htmlToExcel.getExcel(`#multiTable2`, '出运信息');
    },
    //复制的数据变绿色
    setRowStyle({ row, rowIndex }) {
      row.index = rowIndex;
      switch (row.is_copy) {
        case 1:
          return { background: 'rgb(199,230,165)' };
      }
    },
    //复制出运明细
    copyProd() {
      if (this.selectionsList.length === 0) {
        return this.$message.warning('请至少选择一条数据');
      }
      let is_copy = this.selectionsList.filter(x => x.is_copy === 1);
      if (is_copy.length > 0) {
        return this.$message.warning('无法使用复制的数据复制！');
      }
      this.$confirm('确认复制选中数据？ 复制后此单出运全部联动则消失', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          let selectionsList = cloneDeep(this.selectionsList);
          let prodIndex = this.tranForm.tran_prod_list.length + 1;
          selectionsList.forEach(item => {
            item.tran_prod_id = null;
            //赋值复制字段为1
            item.is_copy = 1;
            item.sub_index = prodIndex;
            prodIndex++;
            //联动复制报关
            let part_list = cloneDeep(this.tranForm.tran_part_list.filter(x => x.is_copy === 0 && x.scon_prod_id === item.scon_prod_id));
            let partIndex = this.tranForm.tran_part_list.length + 1;
            part_list.forEach(part => {
              //赋值复制字段为1
              part.is_copy = 1;
              part.tran_part_id = null;
              part.sub_index = partIndex;
              partIndex++;
            });
            this.tranForm.tran_part_list = this.tranForm.tran_part_list.concat(part_list);
            //联动复制商检
            let insp_list = cloneDeep(this.tranForm.tran_insp_list.filter(x => x.is_copy === 0 && x.scon_prod_id === item.scon_prod_id));
            let inspIndex = this.tranForm.tran_insp_list.length + 1;
            insp_list.forEach(insp => {
              //赋值复制字段为1
              insp.is_copy = 1;
              insp.tran_insp_id = null;
              insp.sub_index = inspIndex;
              inspIndex++;
            });
            this.tranForm.tran_insp_list = this.tranForm.tran_insp_list.concat(insp_list);
          });
          this.tranForm.tran_prod_list = this.tranForm.tran_prod_list.concat(selectionsList);
          //复制后无法联动，改为不联动
          this.tranForm.is_linkage = 1;
          //重置选择框
          this.$refs.ProdDetail.clearSelection();
          //key值重置
          this.reductionKey();
        })
        .catch(err => console.error(err));
    },
    getSconSon() {
      this.$emit('getSconSon');
    },
    // 删除
    deleteProd() {
      if (this.selectionsList.length === 0) {
        return this.$message.info('至少选择一条信息');
      }
      let temp = [];
      let temp1 = [];
      let temp2 = [];
      for (let i = 0; i < this.selectionsList.length; i++) {
        for (let j = 0; j < this.tranForm.tran_prod_list.length; j++) {
          if (this.selectionsList[i].key === this.tranForm.tran_prod_list[j].key) {
            temp.push(j);
          }
        }
        for (let j = 0; j < this.tranForm.tran_part_list.length; j++) {
          if (this.selectionsList[i].is_copy === 0 && this.selectionsList[i].scon_prod_id === this.tranForm.tran_part_list[j].scon_prod_id) {
            temp1.push(j);
          }
        }
        for (let j = 0; j < this.tranForm.tran_insp_list.length; j++) {
          if (this.selectionsList[i].is_copy === 0 && this.selectionsList[i].scon_prod_id === this.tranForm.tran_insp_list[j].scon_prod_id) {
            temp2.push(j);
          }
        }
      }
      temp = temp.sort((a, b) => {
        return b - a;
      });
      temp1 = temp1.sort((a, b) => {
        return b - a;
      });
      temp2 = temp2.sort((a, b) => {
        return b - a;
      });
      for (let i of temp) {
        this.tranForm.tran_prod_list.splice(i, 1);
      }
      for (let i of temp1) {
        this.tranForm.tran_part_list.splice(i, 1);
      }
      for (let i of temp2) {
        this.tranForm.tran_insp_list.splice(i, 1);
      }
      //计算序号和key
      this.subIndexCompute();
      EventBus.$emit('updatePart', this.tranForm.tran_part_list); //刷新部件
      EventBus.$emit('updateInsp', this.tranForm.tran_insp_list); //刷新商检
    },
    subIndexCompute() {
      this.tranForm.tran_prod_list.sort((a, b) => {
        if (a.prod_new_cust_no < b.prod_new_cust_no) return -1;
        else if (a.prod_new_cust_no < b.prod_new_cust_no) return 1;
        else return 0;
      });
      this.tranForm.tran_part_list.sort((a, b) => {
        if (a.prod_new_cust_no < b.prod_new_cust_no) return -1;
        else if (a.prod_new_cust_no < b.prod_new_cust_no) return 1;
        else return 0;
      });
      this.tranForm.tran_insp_list.sort((a, b) => {
        if (a.prod_new_cust_no < b.prod_new_cust_no) return -1;
        else if (a.prod_new_cust_no < b.prod_new_cust_no) return 1;
        else return 0;
      });
      for (let i = 0; i < this.tranForm.tran_prod_list.length; i++) {
        this.tranForm.tran_prod_list[i].key = Number(i);
        this.tranForm.tran_prod_list[i].sub_index = Number(i + 1);
      }
      for (let i = 0; i < this.tranForm.tran_part_list.length; i++) {
        this.tranForm.tran_part_list[i].key = Number(i);
        this.tranForm.tran_part_list[i].sub_index = Number(i + 1);
      }
      for (let i = 0; i < this.tranForm.tran_insp_list.length; i++) {
        this.tranForm.tran_insp_list[i].key = Number(i);
        this.tranForm.tran_insp_list[i].sub_index = Number(i + 1);
      }
    },
    // key值重置
    reductionKey() {
      for (let i = 0; i < this.tranForm.tran_prod_list.length; i++) {
        this.tranForm.tran_prod_list[i].key = Number(i);
      }
      for (let i = 0; i < this.tranForm.tran_part_list.length; i++) {
        this.tranForm.tran_part_list[i].key = Number(i);
      }
      for (let i = 0; i < this.tranForm.tran_insp_list.length; i++) {
        this.tranForm.tran_insp_list[i].key = Number(i);
      }
      for (let i = 0; i < this.tranForm.tran_fees_list.length; i++) {
        this.tranForm.tran_fees_list[i].key = Number(i);
      }
      this.$refs.ProdDetail.clearSelection();
    },
    // 初始化箱封号
    initialization() {
      // let flag = true;
      // for (let i = 0; i < this.tranForm.tran_prod_list.length; i++) {
      //   if (
      // !this.tranForm.tran_prod_list[i].prod_ship ||
      // !this.tranForm.tran_prod_list[i].prod_qpc ||
      //     !this.tranForm.tran_prod_list[i].prod_box_num
      //   ) {
      //     flag = false;
      //   }
      // }
      // if (!flag || this.tranForm.tran_prod_list.length < 1) {
      //   return this.$message.warning('请将出运数量和箱数补充完整且不能为0');
      // }
      for (let i = 0; i < this.tranForm.tran_prod_list.length; i++) {
        let prod = this.tranForm.tran_prod_list.find(item => item.sub_index === i + 1);
        if (i + 1 === 1) {
          prod.tran_prod_ctns = 1;
          prod.tran_prod_ctne = 0 + Number(prod.prod_box_num);
        } else {
          let prod2 = this.tranForm.tran_prod_list.find(item => item.sub_index === i);
          prod.tran_prod_ctns = Number(prod2.tran_prod_ctne) + 1;
          prod.tran_prod_ctne = Number(prod2.tran_prod_ctne) + Number(prod.prod_box_num);
        }
      }
    },
    // 更新行外箱商品数
    toUpdateBox() {
      let params1 = [];
      let params2 = [];
      let params3 = [];
      for (let i = 0; i < this.tranForm.tran_prod_list.length; i++) {
        params1.push(this.tranForm.tran_prod_list[i].scon_prod_id);
      }
      for (let i = 0; i < this.tranForm.tran_part_list.length; i++) {
        params2.push(this.tranForm.tran_part_list[i].scon_part_id);
      }
      for (let i = 0; i < this.tranForm.tran_insp_list.length; i++) {
        params3.push(this.tranForm.tran_insp_list[i].scon_prod_id);
      }
      let ids1 = params1.join(',');
      let ids2 = params2.join(',');
      let ids3 = params3.join(',');
      get(sconAPI.getSconProdsByIds, { scon_prod_ids: ids1, scon_part_ids: ids2, tran_insp_ids: ids3 })
        .then(res => {
          if (res.data.code === 0) {
            let list = res.data.data.list;
            let sconPartList = res.data.data.scon_part_list;
            let tranInspList = res.data.data.tran_insp_list;
            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < this.tranForm.tran_prod_list.length; j++) {
                if (list[i].scon_prod_id === this.tranForm.tran_prod_list[j].scon_prod_id) {
                  this.tranForm.tran_prod_list[j].prod_gweight = list[i].prod_gweight;
                  this.tranForm.tran_prod_list[j].prod_nweight = list[i].prod_nweight;
                  this.tranForm.tran_prod_list[j].prod_boxl = list[i].prod_boxl;
                  this.tranForm.tran_prod_list[j].prod_boxw = list[i].prod_boxw;
                  this.tranForm.tran_prod_list[j].prod_boxh = list[i].prod_boxh;
                  this.tranForm.tran_prod_list[j].prod_tgweight = this.helper.calcNum3(
                    (Number(this.tranForm.tran_prod_list[j].prod_gweight) * 100000 * Number(this.tranForm.tran_prod_list[j].prod_box_num)) / 100000
                  );
                  this.tranForm.tran_prod_list[j].prod_tnweight = this.helper.calcNum3(
                    (Number(this.tranForm.tran_prod_list[j].prod_nweight) * 100000 * Number(this.tranForm.tran_prod_list[j].prod_box_num)) / 100000
                  );
                  let numVol = new BigNumber(1);
                  numVol = numVol
                    .times(this.tranForm.tran_prod_list[j].prod_boxl ? this.tranForm.tran_prod_list[j].prod_boxl : 0)
                    .times(this.tranForm.tran_prod_list[j].prod_boxw ? this.tranForm.tran_prod_list[j].prod_boxw : 0)
                    .times(this.tranForm.tran_prod_list[j].prod_boxh ? this.tranForm.tran_prod_list[j].prod_boxh : 0)
                    .div(1000000)
                    .toNumber();
                  this.tranForm.tran_prod_list[j].prod_vol = this.helper.calcNum3(numVol);
                  this.tranForm.tran_prod_list[j].prod_tvol = this.helper.calcNum3(
                    (Number(this.tranForm.tran_prod_list[j].prod_vol) * 100000 * Number(this.tranForm.tran_prod_list[j].prod_box_num)) / 100000
                  );
                }
              }
            }
            for (let i = 0; i < sconPartList.length; i++) {
              for (let j = 0; j < this.tranForm.tran_part_list.length; j++) {
                if (sconPartList[i].scon_part_id === this.tranForm.tran_part_list[j].scon_part_id && this.tranForm.tran_part_list[j].tran_part_claim !== 0) {
                  this.tranForm.tran_part_list[j].prod_gweight = sconPartList[i].prod_gweight;
                  this.tranForm.tran_part_list[j].prod_nweight = sconPartList[i].prod_nweight;
                  this.tranForm.tran_part_list[j].prod_boxl = sconPartList[i].prod_boxl;
                  this.tranForm.tran_part_list[j].prod_boxw = sconPartList[i].prod_boxw;
                  this.tranForm.tran_part_list[j].prod_boxh = sconPartList[i].prod_boxh;
                  //计算总毛重
                  this.tranForm.tran_part_list[j].prod_tgweight = this.helper.calcNum3(
                    (Number(this.tranForm.tran_part_list[j].prod_gweight) * 100000 * Number(this.tranForm.tran_part_list[j].prod_box_num)) / 100000
                  );
                  //计算总净重
                  this.tranForm.tran_part_list[j].prod_tnweight = this.helper.calcNum3(
                    (Number(this.tranForm.tran_part_list[j].prod_nweight) * 100000 * Number(this.tranForm.tran_part_list[j].prod_box_num)) / 100000
                  );
                  let numVol = new BigNumber(1);
                  numVol = numVol
                    .times(this.tranForm.tran_part_list[j].prod_boxl ? this.tranForm.tran_part_list[j].prod_boxl : 0)
                    .times(this.tranForm.tran_part_list[j].prod_boxw ? this.tranForm.tran_part_list[j].prod_boxw : 0)
                    .times(this.tranForm.tran_part_list[j].prod_boxh ? this.tranForm.tran_part_list[j].prod_boxh : 0)
                    .div(1000000)
                    .toNumber();
                  this.tranForm.tran_part_list[j].prod_vol = this.helper.calcNum3(numVol);
                  this.tranForm.tran_part_list[j].prod_tvol = this.helper.calcNum3(
                    (Number(this.tranForm.tran_part_list[j].prod_vol) * 100000 * Number(this.tranForm.tran_part_list[j].prod_box_num)) / 100000
                  );
                }
              }
            }
            for (let i = 0; i < tranInspList.length; i++) {
              for (let j = 0; j < this.tranForm.tran_insp_list.length; j++) {
                if (tranInspList[i].scon_prod_id === this.tranForm.tran_insp_list[j].scon_prod_id) {
                  this.tranForm.tran_insp_list[j].prod_gweight = tranInspList[i].prod_gweight;
                  this.tranForm.tran_insp_list[j].prod_nweight = tranInspList[i].prod_nweight;
                  this.tranForm.tran_insp_list[j].prod_boxl = tranInspList[i].prod_boxl;
                  this.tranForm.tran_insp_list[j].prod_boxw = tranInspList[i].prod_boxw;
                  this.tranForm.tran_insp_list[j].prod_boxh = tranInspList[i].prod_boxh;
                  this.tranForm.tran_insp_list[j].prod_tgweight = this.helper.calcNum3(
                    (Number(this.tranForm.tran_insp_list[j].prod_gweight) * 100000 * Number(this.tranForm.tran_insp_list[j].prod_box_num)) / 100000
                  );
                  this.tranForm.tran_insp_list[j].prod_tnweight = this.helper.calcNum3(
                    (Number(this.tranForm.tran_insp_list[j].prod_nweight) * 100000 * Number(this.tranForm.tran_insp_list[j].prod_box_num)) / 100000
                  );
                  let numVol = new BigNumber(1);
                  numVol = numVol
                    .times(this.tranForm.tran_insp_list[j].prod_boxl ? this.tranForm.tran_insp_list[j].prod_boxl : 0)
                    .times(this.tranForm.tran_insp_list[j].prod_boxw ? this.tranForm.tran_insp_list[j].prod_boxw : 0)
                    .times(this.tranForm.tran_insp_list[j].prod_boxh ? this.tranForm.tran_insp_list[j].prod_boxh : 0)
                    .div(1000000)
                    .toNumber();
                  this.tranForm.tran_insp_list[j].prod_vol = this.helper.calcNum3(numVol);
                  this.tranForm.tran_insp_list[j].prod_tvol = this.helper.calcNum3(
                    (Number(this.tranForm.tran_insp_list[j].prod_vol) * 100000 * Number(this.tranForm.tran_insp_list[j].prod_box_num)) / 100000
                  );
                }
              }
            }
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //更新商品库价格
    toUpdatePrice() {
      let params = [];
      for (let i = 0; i < this.tranForm.tran_prod_list.length; i++) {
        params.push(this.tranForm.tran_prod_list[i].tran_prod_id);
      }
      let tranProdIds = params.join(',');
      get(tranAPI.syncProdPrice, { tranProdIds: tranProdIds })
        .then(res => {
          if (res.data.code === 0) {
            res.data.data.list.forEach(item => {
              this.tranForm.tran_prod_list.forEach(item2 => {
                if (item.tran_prod_id === item2.tran_prod_id) {
                  item2.prod_usd = item.prod_usd; //单价
                  item2.prod_subtotal = this.helper.calcNum2((Number(item2.prod_usd) * 100000 * Number(item2.prod_ship)) / 100000);
                }
              });
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 计算金额
    prodSubtotal(val, val1) {
      this.tranForm.tran_prod_list[val].prod_subtotal = this.helper.calcNum2(
        (Number(this.tranForm.tran_prod_list[val].prod_usd) * 100000 * Number(this.tranForm.tran_prod_list[val].prod_ship)) / 100000
      );
      if (val1 === 0) {
        // if (this.tranForm.tran_prod_list[val].prod_ship > this.tranForm.tran_prod_list[val].prod_num) {
        //   this.tranForm.tran_prod_list[val].prod_ship = this.tranForm.tran_prod_list[val].prod_num;
        // }
        this.tranForm.tran_prod_list[val].prod_ship = new BigNumber(this.tranForm.tran_prod_list[val].prod_ship).toFixed(2, 0);
        this.prodBoxNum(val);
        this.$emit('changeProdSubtotal', this.tranForm.tran_prod_list[val].scon_prod_id, this.tranForm.tran_prod_list[val].prod_ship);
      }
    },
    // 计算总毛重
    totalGrossWeightChange(val) {
      this.tranForm.tran_prod_list[val].prod_tgweight = this.helper.calcNum3(
        (Number(this.helper.calcNum3(this.tranForm.tran_prod_list[val].prod_gweight)) * 100000 * Number(this.tranForm.tran_prod_list[val].prod_box_num)) /
          100000
      );
      this.$emit('linkageGrossWeight', this.tranForm.tran_prod_list[val].scon_prod_id, this.tranForm.tran_prod_list[val].prod_gweight);
    },
    // 计算总净重
    totalNetWeight(val) {
      this.tranForm.tran_prod_list[val].prod_tnweight = this.helper.calcNum3(
        (Number(this.helper.calcNum3(this.tranForm.tran_prod_list[val].prod_nweight)) * 100000 * Number(this.tranForm.tran_prod_list[val].prod_box_num)) /
          100000
      );
      this.$emit('linkageNWeight', this.tranForm.tran_prod_list[val].scon_prod_id, this.tranForm.tran_prod_list[val].prod_nweight);
    },
    // 总体积
    totalVolumeChange(val) {
      this.tranForm.tran_prod_list[val].prod_tvol = this.helper.calcNum3(
        (Number(this.tranForm.tran_prod_list[val].prod_vol) * 100000 * Number(this.tranForm.tran_prod_list[val].prod_box_num)) / 100000
      );
      this.$emit('linkageTvol', this.tranForm.tran_prod_list[val].scon_prod_id, this.tranForm.tran_prod_list[val].prod_vol);
      this.$refs.ProdDetail.$refs.multiTable.updateData();
    },
    // 计算体积
    calculateVolume(val, obj) {
      let num = new BigNumber(1);
      num = num
        .times(this.tranForm.tran_prod_list[val].prod_boxl ? this.tranForm.tran_prod_list[val].prod_boxl : 0)
        .times(this.tranForm.tran_prod_list[val].prod_boxw ? this.tranForm.tran_prod_list[val].prod_boxw : 0)
        .times(this.tranForm.tran_prod_list[val].prod_boxh ? this.tranForm.tran_prod_list[val].prod_boxh : 0)
        .div(1000000)
        .toNumber();
      this.tranForm.tran_prod_list[val].prod_vol = this.helper.calcNum3(num);
      this.totalVolumeChange(val);
      if (obj) this.$emit('changeListLWH', obj);
    },
    message: debounce(function () {
      this.$message.warning('箱数未能整除，已向上取整');
    }, 1000),
    // 计算箱数
    prodBoxNum(val) {
      if (this.tranForm.tran_prod_list[val].prod_qpc === '' || this.tranForm.tran_prod_list[val].prod_qpc === '0') {
        this.tranForm.tran_prod_list[val].prod_qpc = 0;
        this.tranForm.tran_prod_list[val].prod_box_num = 0;
        return;
      }
      if (Number(this.tranForm.tran_prod_list[val].prod_ship) % Number(this.tranForm.tran_prod_list[val].prod_qpc) !== 0) {
        this.message();
      }
      this.tranForm.tran_prod_list[val].prod_box_num = Math.ceil(
        Number(this.tranForm.tran_prod_list[val].prod_ship) / Number(this.tranForm.tran_prod_list[val].prod_qpc)
      );
      this.totalGrossWeightChange(val); // 计算总毛重
      this.totalNetWeight(val); // 计算总净重
      this.calculateVolume(val); // 计算体积
    },
    moveUp() {
      if (this.selectionsList.length < 1) {
        this.$message.warning('请选择一条数据');
      } else if (this.selectionsList.length > 1) {
        this.$message.warning('只能选择一条数据');
      } else {
        let temp = JSON.parse(JSON.stringify(this.selectionsList[0]));
        if (temp.key === 0) {
          this.$message.warning('已经是第一条');
        } else {
          let ind = temp.key;
          this.tranForm.tran_prod_list.splice(ind, 1);
          this.tranForm.tran_prod_list.splice(ind - 1, 0, temp);
        }
      }
      this.reductionKey();
    },
    moveDown() {
      if (this.selectionsList.length < 1) {
        this.$message.warning('请选择一条数据');
      } else if (this.selectionsList.length > 1) {
        this.$message.warning('只能选择一条数据');
      } else {
        let temp = JSON.parse(JSON.stringify(this.selectionsList[0]));
        if (temp.key === this.tranForm.tran_prod_list.length - 1) {
          this.$message.warning('已经是最后一条');
        } else {
          let ind = temp.key;
          this.tranForm.tran_prod_list.splice(ind, 1);
          this.tranForm.tran_prod_list.splice(ind + 1, 0, temp);
        }
      }
      this.reductionKey();
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .elx-table.elx-editable.size--medium .elx-body--column,
.elx-table.size--medium .elx-body--column.col--ellipsis,
.elx-table.size--medium .elx-footer--column.col--ellipsis,
.elx-table.size--medium .elx-header--column.col--ellipsis {
  height: 34px;
}

::v-deep .el-form-item.is-required .el-form-item__label:before {
  content: '';
  display: none;
}

::v-deep .showRow {
  display: none;
}

::v-deep .fixed-cell {
  //  color:red !important
  background-color: #ccc !important;
}
</style>
