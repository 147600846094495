<template>
  <div class="vg_wrapper">
    <el-row>
      <el-col :md="24" class="vd_disriht">
        <span>关键字搜索：</span>
        <el-input
          v-model="searchContent"
          placeholder="请输入匹配关键字"
          size="medium"
          style="width: 200px; margin-bottom: 10px"
          @focus="focusInput"
          @blur="blurInput"
          :class="{ aa: active, bb: !active }"
        >
        </el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <el-table class="vg_cursor" ref="multiTable" :data="filterData" @row-dblclick="dbClickJp" border v-loading="loadFlag">
          <el-table-column label="序号" width="120" align="center">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="海关编码" prop="param1" align="center"></el-table-column>
          <el-table-column label="报关中文品名" prop="param2" show-overflow-tooltip align="center"></el-table-column>
          <el-table-column label="报关英文品名" prop="param3" align="center"></el-table-column>
          <el-table-column label="增值税率" prop="param4" align="center"></el-table-column>
          <el-table-column label="退税率" prop="param5" align="center"> </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { optnAPI } from '@api/modules/optn';
export default {
  name: 'ProdRequList',
  components: {},
  data() {
    return {
      tableData: [],
      totalPage: 0,
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      searchContent: '',
      value: '',
      active: true
    };
  },
  computed: {
    filterData: function () {
      let input = this.searchContent;
      let items = this.tableData;
      let items1;
      if (input) {
        items1 = items.filter(function (item) {
          return Object.keys(item).some(function (key1) {
            return String(item[key1]).match(input);
          });
        });
      } else {
        items1 = items;
      }
      return items1;
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getRequsList();
    },
    getRequsList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10019 })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.form.optn_cntt_list;
            this.loadFlag = false;
          }
        })
        .catch(() => {});
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.$emit('changeCustom', row);
    },
    blurInput() {
      this.active = true;
    },
    focusInput() {
      this.active = false;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

::v-deep .aa input {
  border-color: #ccc !important;
}
::v-deep .bb input {
  border-color: rgb(120, 143, 210) !important;
}
.vd_disriht {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
}
.vd_disriht span {
  margin-top: -10px;
}
</style>
