<template>
  <div class="importCust">
    <el-form :model="searchForm" :inline="true" ref="searchForm" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="客户编号" prop="cust_no">
            <el-input v-model.trim="searchForm.cust_no" placeholder="请输入客户编号" clearable></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="8">
          <el-form-item label="客户简称" prop="cust_abbr">
            <el-input v-model.trim="searchForm.cust_abbr" placeholder="请输入客户简称" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="所属国家:">
            <el-select v-model="searchForm.cust_country" placeholder="请选择所属国家" size="small" clearable filterable>
              <el-option v-for="item in contry" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_8">
        <el-col>
          <el-form-item>
            <el-button size="small" type="primary" icon="el-icon-search" @click="getQutbNow()" class="vg_ml_16">查询</el-button>
            <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
              ><i class="el-icon-refresh-right"></i> 刷新</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="vg_mt_16">
      <el-col :md="24">
        <el-table
          ref="multiTable"
          :data="tableData"
          :row-key="getRowKey"
          border
          v-loading="loading"
          class="vg_pointer"
          @cell-dblclick="cellDblClick"
        >
          <!-- <el-table-column type="selection" width="48"  align="center" :reserve-selection="true"/> -->
          <el-table-column label="客户编号" prop="cust_no"></el-table-column>
          <el-table-column label="客户简称" prop="cust_abbr"></el-table-column>
          <el-table-column label="客户全称" prop="cust_name"></el-table-column>
          <el-table-column label="所属国家" prop="cust_country"></el-table-column>
          <el-table-column label="联系人" prop="cust_contact"></el-table-column>
          <el-table-column label="联系方式" :formatter="formatterNmae" />
          <el-table-column label="指定外销员" prop="cust_stff_name" />
        </el-table>
      </el-col>
    </el-row>
    <el-row class="vg_mt_16">
      <el-col :md="24">
        <pubPagination :totalPage="total" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import pubPagination from '@/components/common/pubPagination';
import { get, post } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { custAPI } from '@/api/modules/cust';
export default {
  name: 'importCust',
  components: {
    pubPagination
  },
  data() {
    return {
      searchForm: {
        page_no: 1,
        cust_no: null, //客户编号
        cust_abbr: null, //客户名称
        cust_country: null //客户国家
      },
      total: 0,
      tableData: [],
      loading: false,
      contry: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getCust();
      this.getContry();
    },
    getContry() {
      get(optnAPI.getOptnByPermId, { perm_id: 10003 })
        .then(res => {
          if (res.data.code === 0) {
            this.contry = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    getCust() {
      this.loading = true;
      get(custAPI.getAllCustsV3, {
        page_no: this.searchForm.page_no,
        cust_no: this.searchForm.cust_no,
        cust_abbr: this.searchForm.cust_abbr,
        cust_country: this.searchForm.cust_country
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.total = res.data.data.total;
            this.loading = false;
            return;
          }
          this.$message.error(res.data.msg);
          this.loading = false;
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    buttonRefresh() {
      this.$refs.searchForm.resetFields();
      this.searchForm.page_no = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.getCust();
    },
    getQutbNow() {
      this.searchForm.page_no = 1;
      this.$refs.pubPagination.currentPage = 1;
      this.getCust();
    },
    //弹框 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getCust();
    },
    getRowKey(row) {
      return row.cust_no;
    },
    // 联系人
    formatterNmae(val) {
      return val.cust_contact + ',' + val.cust_phone;
    },
    cellDblClick(row) {
      this.$emit('importCustChange', row);
    }
  }
};
</script>

<style></style>
