<template>
  <div class="importCust">
    <search-table
      ref="multiTable"
      :data="tableData"
      :tableRowKey="'clau_no'"
      :columns="tableProperties"
      v-loading="loading"
      :totalPage="total"
      @row-dblclick="row => $emit('importClauInfoChange', row)"
      @getTableData="getTableData"
    />
  </div>
</template>

<script>
import pubPagination from '@/components/common/pubPagination';
import { getNoCatch } from '@api/request';
import { clauAPI } from '@/api/modules/clau';
import SearchTable from '@/components/table/SearchTableV2.vue';

export default {
  name: 'importCust',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      total: 0,
      tableData: [],
      loading: false,
      tableProperties: [
        this.$store.state.index,
        { label: '文本部门', prop: 'clau_dept_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '文本名称', prop: 'clau_name', itemType: 'input', input: true, sortable: false, widthAuto: true }
        // { label: '条款内容', prop: 'clau_content', itemType: 'input', input: true, sortable: false, widthAuto: true }
      ]
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    async getTableData() {
      await (this.loading = true);
      await getNoCatch(clauAPI.getAllClausV1, this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.total = data.total;
        this.loading = false;
      });
    }
  }
};
</script>

<style></style>
