<template>
  <div class="importInst">
    <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="getInstsNow()">
      <el-row>
        <el-col :md="16">
          <el-form-item>
            <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
              ><i class="el-icon-refresh-right"></i> 刷新
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row class="vg_mt_16">
      <el-col :md="24">
        <el-table
          ref="multiTable"
          :data="tableData"
          :row-key="getRowKey"
          border
          v-loading="loading"
          class="vg_pointer"
          @cell-dblclick="cellDblClick"
        >
          <el-table-column type="index" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="仓库名称" prop="param1"></el-table-column>
          <el-table-column label="地址" prop="param2"></el-table-column>
          <el-table-column label="电话" prop="param3"></el-table-column>
          <el-table-column label="联系人" prop="param4"></el-table-column>
          <el-table-column label="手机" prop="param5"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import pubPagination from '@/components/common/pubPagination';
import { get } from '@api/request';
import { optnAPI } from '@api/modules/optn';

export default {
  name: 'importInst',
  components: {
    pubPagination
  },
  data() {
    return {
      searchForm: {
        page_no: 1,
        inst_type: '货代机构', //机构类型
        inst_no: null, //机构编号
        inst_abbr: null, //机构简称
        inst_cname: null, //中文名称
        inst_ename: null //英文名称
        // inst_type: '货代机构', //机构类型
        // inst_no: null, //机构编号
        // inst_abbr: null, //机构简称
        // inst_cname: null, //中文名称
        // inst_ename: null //英文名称
      },
      total: 0,
      tableData: [],
      loading: false,
      instTypes: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getInstAttrList();
    },
    // 获取仓库
    getInstAttrList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10029 })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.form.optn_cntt_list;
          }
        })
        .catch(() => {});
    },
    // 刷新
    buttonRefresh() {
      this.$refs.searchForm.resetFields();
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getCustsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getInst();
    },
    getInstsNow() {
      this.getCustsNow();
    },
    //弹框 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getInst();
    },
    getRowKey(row) {
      return row.inst_no;
    },
    cellDblClick(row) {
      this.$emit('importInstChange', row);
    }
  }
};
</script>

<style></style>
