<template>
  <div class="importInst">
    <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="getInstsNow()">
      <el-row>
        <el-col :md="8">
          <el-form-item label="机构类型:" prop="inst_type">
            <!-- <el-input size="small" v-model.trim="searchForm.inst_type" clearable placeholder="请选择机构类别"></el-input> -->

            <el-select size="small" filterable v-model="searchForm.inst_type" placeholder="请选择机构类型" clearable>
              <el-option v-for="item in instTypes" :key="item.inst_type" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="机构编号:" prop="inst_no">
            <el-input size="small" v-model.trim="searchForm.inst_no" clearable placeholder="请填写机构编号"></el-input>
          </el-form-item>
        </el-col>

        <el-col :md="8">
          <el-form-item label="机构简称:" prop="inst_abbr">
            <el-input v-model.trim="searchForm.inst_abbr" placeholder="请填写机构简称" clearable size="small"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="中文名称:" prop="inst_cname">
            <el-input size="small" v-model.trim="searchForm.inst_cname" clearable placeholder="请填写中文名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="16">
          <el-form-item label="英文名称:" prop="inst_ename">
            <el-input v-model.trim="searchForm.inst_ename" placeholder="请填写英文名称" clearable size="small"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" icon="el-icon-search" @click="getCustsNow()" class="vg_ml_16">查询 </el-button>
            <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
              ><i class="el-icon-refresh-right"></i> 刷新</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row class="vg_mt_16">
      <el-col :md="24">
        <el-table
          ref="multiTable"
          :data="tableData"
          :row-key="getRowKey"
          border
          v-loading="loading"
          class="vg_pointer"
          @cell-dblclick="cellDblClick"
        >
          <el-table-column type="index" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="机构类型" prop="inst_type"></el-table-column>
          <el-table-column label="机构编号" prop="inst_no"></el-table-column>
          <el-table-column label="机构简称" prop="inst_abbr"></el-table-column>
          <el-table-column label="中文名称" prop="inst_cname"></el-table-column>
          <el-table-column label="英文名称" prop="inst_ename"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row class="vg_mt_16">
      <el-col :md="24">
        <pubPagination :totalPage="total" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import pubPagination from '@/components/common/pubPagination';
import { get, post } from '@api/request';
import { instAPI } from '@api/modules/inst';
import { optnAPI } from '@api/modules/optn';
export default {
  name: 'importInst',
  components: {
    pubPagination
  },
  data() {
    return {
      searchForm: {
        page_no: 1,
        inst_type: '货代机构', //机构类型
        inst_no: null, //机构编号
        inst_abbr: null, //机构简称
        inst_cname: null, //中文名称
        inst_ename: null //英文名称
      },
      total: 0,
      tableData: [],
      loading: false,
      instTypes: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getInst();
      this.getInstAttrList();
    },
    // 获取机构类别
    getInstAttrList() {
      get(optnAPI.getOptnByPermId, { perm_id: 10012 })
        .then(res => {
          if (res.data.code === 0) {
            this.instTypes = res.data.data.form.optn_cntt_list;
            console.log(this.instTypes);
          }
        })
        .catch(() => {});
    },

    getInst() {
      this.loading = true;
      get(instAPI.getAllInstsV1, {
        page_no: this.searchForm.page_no,
        inst_type: this.searchForm.inst_type,
        inst_no: this.searchForm.inst_no,
        inst_abbr: this.searchForm.inst_abbr,
        inst_cname: this.searchForm.inst_cname,
        inst_ename: this.searchForm.inst_ename
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.total = res.data.data.total;
            this.loading = false;
            return;
          }
          this.$message.error(res.data.msg);
          this.loading = false;
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.searchForm.resetFields();
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getCustsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getInst();
    },
    getInstsNow() {
      this.getCustsNow();
    },
    //弹框 分页查询
    changePageSearch(val) {
      this.searchForm.page_no = val;
      this.getInst();
    },
    getRowKey(row) {
      return row.inst_no;
    },

    cellDblClick(row) {
      this.$emit('importInstChange', row);
    }
  }
};
</script>

<style></style>
