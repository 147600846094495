<template>
  <div class="vg_wrapper">
    <div class="vd_search_group">
      <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchForm">
        <el-row>
          <el-col :md="8">
            <el-form-item label="客户订单号:">
              <el-input size="small" v-model.trim="searchForm.scon_cust_no" clearable placeholder="请填写客户订单号"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="客户货号:">
              <el-input size="small" v-model.trim="searchForm.prod_cust_no" clearable placeholder="请填写客户货号"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="我司货号:">
              <el-input size="small" v-model.trim="searchForm.prod_no" clearable placeholder="请填写我司货号"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="12">
            <el-form-item label="销售合同号:">
              <el-input size="small" v-model.trim="searchForm.scon_no" clearable placeholder="请填写销售合同号"> </el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getSconsNow()" class="vg_ml_16">查询 </el-button>
              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                ><i class="el-icon-refresh-right"></i> 刷新</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="vd_button_group vg_mtb_16">
      <el-row class="vd_mar10">
        <el-col :md="16">
          <el-button size="mini" type="danger" @click="cancel">取消选择</el-button>
          <el-button class="vg_mr_8" size="mini" type="primary" @click="confirmIn">确认选择</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :md="24">
        <el-table
          border
          ref="multiTable"
          :data="tableData"
          @selection-change="handleSelectionChange"
          v-loading="loadFlag"
          :row-key="row => row.podr_id"
        >
          <el-table-column type="selection" width="48" align="center" :reserve-selection="true" />
          <el-table-column label="出运状态" prop="prod_shipstat" :formatter="formatterShipstat" />
          <el-table-column label="销售合同号" :show-overflow-tooltip="true" prop="scon_no" />
          <el-table-column label="公司代码" prop="cust_name" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-if="scope.row.cust_name">
                {{ scope.row.cust_name }}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="我司货号" prop="prod_no" :show-overflow-tooltip="true" />
          <el-table-column label="货号后缀" prop="prod_poststfix" :show-overflow-tooltip="true" />
          <el-table-column label="客户订单号" prop="scon_cust_no" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <span v-if="scope.row.scon_cust_no">
                {{ scope.row.scon_cust_no }}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="客户简称" prop="cust_abbr" :show-overflow-tooltip="true" />
          <el-table-column label="客户货号" prop="prod_cust_no" :show-overflow-tooltip="true" />
          <el-table-column
            label="装运日期"
            prop="scon_shdate"
            :show-overflow-tooltip="true"
            :formatter="({ scon_shdate }) => getDateNoTime(scon_shdate, true)"
          ></el-table-column>
          <el-table-column label="预计出运数量" width="120" prop="prod_num" :show-overflow-tooltip="true" />
          <!--          <el-table-column label="需出运数量" width="80" prop="prod_ship" :show-overflow-tooltip="true" />-->
          <!--          <el-table-column label="已出运数量" width="80" prop="prod_ship_already" :show-overflow-tooltip="true" />-->
          <el-table-column label="目的港" prop="cust_dport" :show-overflow-tooltip="true" />
          <el-table-column label="总体积" prop="prod_vol" :show-overflow-tooltip="true" />
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { useSuppMixin, useCustMixin, useStffMixin, useDeptMixin } from '@/assets/js/mixin/initMixin';
import helper from '@assets/js/helper.js';
import { get, post } from '@api/request';
import { spinAPI } from '@api/modules/spin';
import pubPagination from '@/components/common/pubPagination';
import { getDateNoTime } from '@assets/js/dateUtils';
import { tranAPI } from '@api/modules/tran';

export default {
  name: 'TranPartImport',
  mixins: [useStffMixin, useCustMixin],
  components: {
    pubPagination
  },
  props: {
    tranForm: Object
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        scon_cust_no: null,
        prod_cust_no: null,
        cust_id: null,
        scon_no: null,
        prod_no: null
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      prodTypes: [],
      contry: [],
      prodId: null
    };
  },
  created() {
    // this.initData()
  },
  filters: {
    // 是否出运
    formatShipStat(row) {
      if (row === 0) {
        return '未出运';
      } else if (row === 1) {
        return '已出运';
      } else if (row === 2) {
        return '部分出运';
      }
    }
  },
  methods: {
    getDateNoTime,
    initData() {
      this.getSconsList();
    },
    getSconsList() {
      // let scon_nos = '';
      // this.tranForm.tran_prod_list.forEach(item => {
      //   scon_nos += item.scon_no + ',';
      // });
      // scon_nos = scon_nos.slice(0, -1); //去除最后一位逗号
      get(tranAPI.getSconProdList, {
        cust_id: this.searchForm.cust_id,
        prod_no: this.searchForm.prod_no,
        scon_no: this.searchForm.scon_no,
        // scon_nos: scon_nos, //销售合同号s默认筛选条件
        prod_cust_no: this.searchForm.prod_cust_no,
        scon_cust_no: this.searchForm.scon_cust_no,
        // scon_cust_nos: this.tranForm.scon_cust_nos, //客户订单号s默认筛选条件
        // act_type: 1, //操作类型(导入销售合同)默认筛选条件
        // prod_shipstat: 0,
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 出运状态返回值
    formatterShipstat(val) {
      if (val.prod_shipstat === 0) {
        return '未出运';
      } else if (val.prod_shipstat === 1) {
        return '已出运';
      } else if (val.prod_shipstat === 2) {
        return '部分出运';
      }
    },
    // 刷新
    buttonRefresh() {
      let custId = JSON.parse(JSON.stringify(this.searchForm.cust_id));
      this.searchForm = {
        scon_cust_no: null,
        prod_cust_no: null,
        scon_no: null,
        prod_no: null,
        cust_id: custId
      };
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getSconsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.initData();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    // 取消选择
    cancel() {
      this.clear();
      this.$emit('childChanel');
    },
    // 清空
    clear() {
      this.$refs.multiTable.clearSelection();
    },
    // 确认选择
    confirmIn() {
      if (this.multiSelection.length > 0) {
        this.$emit('childConfirm', this.multiSelection);
        this.clear();
        // console.log(row.searchForm.scon_no);
      } else {
        this.$message({
          type: 'warning',
          message: '至少选中一条数据'
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
