<template>
  <div>
    <el-input
      ref="input"
      type="textarea"
      :disabled="disabledFlag"
      :autosize="{ minRows: 5, maxRows: 10 }"
      v-model="textEnlargementString.str"
      clearable
      @input="inputChange"
      :maxlength="maxlength"
      placeholder="暂无"
    ></el-input>
  </div>
</template>

<script>
export default {
  name: 'textEnlargement',
  props: {
    textEnlargementString: {
      type: Object,
      required: true
    },
    disabledFlag: {
      type: Boolean,
      default: true,
      required: true
    },
    maxlength: {
      type: Number
    }
  },
  data() {
    return {
      publicString: ''
    };
  },
  mounted() {
    this.$refs.input.focus();
  },
  methods: {
    inputChange() {
      this.$emit('textEnlargementChange', this.textEnlargementString.str);
    }
  }
};
</script>

<style></style>
