<template>
  <div class="tranFeesList">
    <div class="vg_mb_16">
      <el-button type="primary" plain size="small" @click="clickAdd">新增</el-button>
      <el-button type="danger" plain size="small" @click="clickDel">删除</el-button>
      <!--:disabled="tranForm.tran_fees_list.some(({ is_invoice }) => !is_invoice)"-->
      <el-button type="success" size="small" class="vg_mt_8" @click="exportExcel()">导出Excel</el-button>
      <el-button :disabled="false" icon="el-icon-printer" type="info" size="small" @click="pdfOperation"> 打印PDF</el-button>
    </div>
    <el-table id="costDetails" :data="tranForm.tran_fees_list" border @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary>
      <el-table-column type="selection" width="48" align="center"></el-table-column>
      <el-table-column label="序号" width="48" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="销售合同号">
        <template #header>
          <span class="">销售合同号</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tran_fees_list.' + scope.$index + '.scon_no'">
            <!--            <el-select v-model="tranForm.tran_fees_list[scope.$index].scon_no" placeholder="请选择销售合同号" size="small" filterable>
              <el-option v-for="item in sconNoTypeList" :value="item.label" :key="item.id" :label="item.label"></el-option>
            </el-select>-->
            <span style="display: none">{{ scope.row.scon_no }}</span>
            <el-input v-model.trim="tranForm.tran_fees_list[scope.$index].scon_no" maxlength="30" show-word-limit placeholder="请输入"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="费用类型">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tran_fees_list.' + scope.$index + '.expense_type'">
            <span style="display: none">{{ scope.row.expense_type === 0 ? '佣金(折扣)' : '其他(模具费等)' }}</span>
            <el-select v-model="tranForm.tran_fees_list[scope.$index].expense_type" placeholder="请选择" size="small" filterable>
              <el-option
                v-for="item in [
                  { value: 0, label: '佣金(折扣)' },
                  { value: 1, label: '其他(模具费等)' }
                ]"
                :value="item.value"
                :key="item.value + item.label"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="费用英文名">
        <template #header>
          <span class="vg_deep_red">费用英文名</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tran_fees_list.' + scope.$index + '.scon_fees_name'" :rules="{ required: true, message: ' ' }">
            <template slot="label">
              <span style="display: none">费用英文名</span>
            </template>
            <span style="display: none">{{ scope.row.scon_fees_name }}</span>
            <el-input v-model="tranForm.tran_fees_list[scope.$index].scon_fees_name" maxlength="500" show-word-limit placeholder="暂无英文名"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="报关中文品名">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tran_fees_list.' + scope.$index + '.prod_hsname'">
            <span style="display: none">{{ scope.row.prod_hsname }}</span>
            <el-select v-model="tranForm.tran_fees_list[scope.$index].prod_hsname" placeholder="请选择" size="small" filterable>
              <el-option v-for="item in extractDataList" :value="item" :key="item" :label="item"></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="金额(美元)" prop="scon_fees_usd">
        <template #header>
          <span class="vg_deep_red">金额(美元)</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tran_fees_list.' + scope.$index + '.scon_fees_usd'" :rules="{ required: true, message: ' ' }">
            <span style="display: none">{{ scope.row.scon_fees_usd }}</span>
            <template slot="label">
              <span style="display: none">金额(美元)</span>
            </template>
            <el-input
              v-model="tranForm.tran_fees_list[scope.$index].scon_fees_usd"
              maxlength="13"
              @input="tranForm.tran_fees_list[scope.$index].scon_fees_usd = helper.keepTNum2(tranForm.tran_fees_list[scope.$index].scon_fees_usd)"
              @blur="
                tranForm.tran_fees_list[scope.$index].scon_fees_usd = helper.calcPriceT(tranForm.tran_fees_list[scope.$index].scon_fees_usd, 4, 1000000000)
              "
              show-word-limit
              placeholder="暂无金额"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="单独发票号">
        <template #header>
          <span class="">单独发票号</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tran_fees_list.' + scope.$index + '.scon_fees_invoice'">
            <span style="display: none">{{ scope.row.scon_fees_invoice }}</span>
            <el-input v-model="tranForm.tran_fees_list[scope.$index].scon_fees_invoice" maxlength="20" show-word-limit placeholder="暂无发票号"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="是否单独发票" show-word-limit>
        <template #header>
          <span class="vg_deep_red">是否单独发票</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tran_fees_list.' + scope.$index + '.is_invoice'" :rules="{ required: true, message: ' ' }">
            <span style="display: none">{{ scope.row.is_invoice === 1 ? '是' : '否' }}</span>
            <el-select size="mini" filterable v-model="tranForm.tran_fees_list[scope.$index].is_invoice" placeholder="是否单独发票">
              <el-option
                v-for="(item, index) in [
                  { label: '是', value: 1 },
                  { label: '否', value: 0 }
                ]"
                :key="index + 'prodMainSup'"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="费用备注">
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'tran_fees_list.' + scope.$index + '.scon_fees_remark'">
            <span style="display: none">{{ scope.row.scon_fees_remark }}</span>
            <el-input v-model="tranForm.tran_fees_list[scope.$index].scon_fees_remark" maxlength="30" show-word-limit placeholder="暂无备注"></el-input>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { BigNumber } from 'bignumber.js';
import { tranAPI } from '@api/modules/tran';
import htmlToExcel from '@/plugins/htmlToExcel';

export default {
  name: 'tranFeesList',
  props: {
    tranForm: Object,
    sconNoTypeList: {
      type: Array,
      required: true
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    extractDataList() {
      return [...new Set(Array.from(this.tranForm.tran_part_list, ({ prod_hsname }) => prod_hsname))];
    }
  },
  data() {
    return {
      selectionsList: [],
      delList: []
    };
  },
  methods: {
    //列总和
    getSummaries(param) {
      const { columns, data } = param;
      let totalList = [];
      for (let i = 0; i < 7; i++) {
        let temp = '';
        if (i === 0) {
          temp = '合计';
        } else {
          temp = '';
        }
        totalList.push(temp);
      }
      columns.forEach((column, index) => {
        let datas = data;
        let totalA = new BigNumber(0);
        for (let i in datas) {
          totalA = totalA.plus(datas[i].scon_fees_usd ? datas[i].scon_fees_usd : 0); //总金额
        }
        totalA = totalA.toNumber(); //总订单数量
        totalList[6] = this.helper.calcPriceT(totalA + '');
      });
      this.$emit('feesTotalPriceChange', totalList[6]);
      return totalList;
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    // 新增
    clickAdd() {
      let item = {
        scon_no: null,
        scon_fees_name: null,
        scon_fees_usd: null,
        scon_fees_invoice: null,
        scon_fees_remark: null
      };
      this.tranForm.tran_fees_list.push(item);
      this.reductionKey();
    },
    // 删除
    clickDel() {
      if (this.selectionsList.length === 0) {
        return this.$message.warning('至少选择一条信息');
      }
      let temp = [];
      for (let i = 0; i < this.selectionsList.length; i++) {
        for (let j = 0; j < this.tranForm.tran_fees_list.length; j++) {
          if (this.selectionsList[i].key === this.tranForm.tran_fees_list[j].key) {
            temp.push(j);
          }
        }
      }
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let i of temp) {
        this.tranForm.tran_fees_list.splice(i, 1);
      }
      this.reductionKey();
    },
    // key值重置
    reductionKey() {
      for (let i = 0; i < this.tranForm.tran_fees_list.length; i++) {
        this.tranForm.tran_fees_list[i].key = Number(i);
      }
    },
    pdfOperation() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          tranAPI
            .export_tran_cost({ tran_id: this.tranForm.tran_id, type: 0 })
            .then(({ data }) => {
              if (process.env.NODE_ENV === 'production') {
                window.open('/erpfile' + data.pdf_url + '.pdf');
              } else {
                window.open('/file' + data.pdf_url + '.pdf');
              }
            })
            .catch(err => this.$message.error(err));
        })
        .catch(() => {});
    },
    exportExcel() {
      htmlToExcel.getExcel(`#costDetails`, this.tranForm.tran_no + '费用明细');
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .showRow {
  display: none;
}
::v-deep .el-form-item.is-required .el-form-item__label:before {
  content: '';
  display: none;
}
</style>
