<template>
  <div class="InspDetail">
    <div class="vg_mb_16">
      <!--<el-button type="primary" plain size="small" @click="clickImport">导入销售合同</el-button>-->
      <el-button type="danger" plain size="small" @click="deleteInsp">删除</el-button>
      <el-button plain size="small" @click="initialization">初始化箱封号</el-button>
      <el-button class="tb-btn" size="small" @click="moveUp">上移</el-button>
      <el-button class="tb-btn" size="small" @click="moveDown">下移</el-button>
      <el-button class="tb-btn" size="small" :disabled="false" @click="exportToExcel('allTable')">导出到Excel</el-button>
      <el-button class="tb-btn" size="small" @click="calculatedAtDiscountedPrice">按折扣价计算</el-button>
      <el-button type="primary" size="small" @click="copyProd">复制</el-button>
    </div>
    <DynamicUTable
      ref="InspDetail"
      :tableRowKey="'tran_insp_list'"
      :form="tranForm"
      :tableData="tranForm.tran_insp_list"
      :columns="shangjianTableProperties"
      :isShow="isShow"
      :needPagination="false"
      :needSearch="false"
      :show-summary="true"
      :needClosedUpdate="true"
      :needAllColumnSummaryToFixed="3"
      :summariesColumns="['prod_num', 'prod_ship', 'prod_subtotal', 'prod_box_num', 'prod_tgweight', 'prod_tnweight', 'prod_tvol']"
      @selection-change="handleSelectionChange"
      :row-style="setRowStyle"
      :openDefaultCommonSlots="false"
      :need-index="false"
      @copyChange="({ tIndex, prop }) => (prop === 'prod_qpc' ? prodBoxNum(tIndex) : '')"
    >
      <template v-slot:supp_abbr="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.supp_abbr'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_insp_list[scope.$index].supp_abbr"
            maxlength="20"
            show-word-limit
            placeholder="暂无供应商简称"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_no="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.scon_no'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_insp_list[scope.$index].scon_no"
            maxlength="20"
            show-word-limit
            placeholder="暂无销售合同号"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:podr_no="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.podr_no'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_insp_list[scope.$index].podr_no"
            maxlength="20"
            show-word-limit
            placeholder="暂无采购合同号"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_cust_no="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.scon_cust_no'" :rules="{ required: true, message: ' ' }">
          <el-input v-model.trim="tranForm.tran_insp_list[scope.$index].scon_cust_no" maxlength="20" show-word-limit placeholder="暂无新订单号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_cust_ono="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.scon_cust_ono'" :rules="{ required: true, message: ' ' }">
          <el-input
            disabled
            v-model.trim="tranForm.tran_insp_list[scope.$index].scon_cust_ono"
            maxlength="20"
            show-word-limit
            placeholder="暂无原始订单号"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_cust_no="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_cust_no'" :rules="{ required: true, message: ' ' }">
          <el-input
            disabled
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_cust_no"
            maxlength="20"
            show-word-limit
            placeholder="暂无客户货号"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_new_cust_no="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_new_cust_no'">
          <el-input
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_new_cust_no"
            maxlength="20"
            show-word-limit
            placeholder="暂无新客户货号"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_no="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_no'" :rules="{ required: true, message: ' ' }">
          <el-input disabled v-model.trim="tranForm.tran_insp_list[scope.$index].prod_no" maxlength="20" show-word-limit placeholder="暂无我司货号"></el-input>
        </el-form-item>
      </template>
      <!--      <template v-slot:prod_poststfix="scope">-->
      <!--        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_poststfix'">-->
      <!--          <el-input-->
      <!--            disabled-->
      <!--            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_poststfix"-->
      <!--            maxlength="20"-->
      <!--            show-word-limit-->
      <!--            placeholder="暂无我司货号"-->
      <!--          ></el-input>-->
      <!--        </el-form-item>-->
      <!--      </template>-->
      <template v-slot:prod_ename="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_ename'" :rules="{ required: true, message: ' ' }">
          <el-input
            disabled
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_ename"
            maxlength="20"
            show-word-limit
            placeholder="暂无英文名称"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_espec="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_espec'" :rules="{ required: true, message: ' ' }">
          <el-input v-model.trim="tranForm.tran_insp_list[scope.$index].prod_espec" show-word-limit placeholder="暂无英文规格">
            <template slot="append">
              <el-popover placement="bottom" width="600" @show="textEnlargementShow(0, scope.$index)" trigger="click">
                <textEnlargement
                  ref="textEnlargement"
                  :disabledFlag="false"
                  @textEnlargementChange="textEnlargementChange"
                  :textEnlargementString="textEnlargementString"
                ></textEnlargement>
                <span slot="reference"
                  ><el-link type="info"><i class="el-icon-more"></i></el-link></span
              ></el-popover>
            </template>
          </el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_num="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_num'" :rules="{ required: true, message: ' ' }">
          <el-input
            disabled
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_num"
            @input="tranForm.tran_insp_list[scope.$index].prod_num = helper.keepTNum(tranForm.tran_insp_list[scope.$index].prod_num)"
            maxlength="10"
            show-word-limit
            placeholder="暂无订单数量"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_ship="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_ship'" :rules="{ required: true, message: ' ' }">
          <el-input
            @change="
              prodSubtotal(scope.$index, 0);
              $refs.InspDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_ship"
            maxlength="10"
            show-word-limit
            @input="tranForm.tran_insp_list[scope.$index].prod_ship = keep6Decimal(tranForm.tran_insp_list[scope.$index].prod_ship)"
            placeholder="暂无出运数量"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_qpc="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_qpc'" :rules="{ required: true, message: ' ' }">
          <el-input
            @change="
              prodBoxNum(scope.$index);
              $refs.InspDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_qpc"
            maxlength="10"
            show-word-limit
            @input="tranForm.tran_insp_list[scope.$index].prod_qpc = helper.keepTNum(tranForm.tran_insp_list[scope.$index].prod_qpc)"
            placeholder="暂无装箱数"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_box_num="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_box_num'">
          <el-input v-model.trim="tranForm.tran_insp_list[scope.$index].prod_box_num" maxlength="20" show-word-limit placeholder="暂无箱数"></el-input>
        </el-form-item>
      </template>
      <template v-slot:tran_insp_ctns="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.tran_insp_ctns'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_insp_list[scope.$index].tran_insp_ctns"
            maxlength="20"
            show-word-limit
            placeholder="暂无起始箱号"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:tran_insp_ctne="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.tran_insp_ctne'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_insp_list[scope.$index].tran_insp_ctne"
            maxlength="20"
            show-word-limit
            placeholder="暂无结束箱号"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_unit="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_unit'" :rules="{ required: true, message: ' ' }">
          <el-select v-model="tranForm.tran_insp_list[scope.$index].prod_unit" placeholder="请选择数量单位" size="small" filterable>
            <el-option v-for="item in companyList" :value="item.param1" :key="item.id" :label="item.param1"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:prod_usd="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_usd'" :rules="{ required: true, message: ' ' }">
          <el-input
            @change="
              prodSubtotal(scope.$index, 1);
              $refs.InspDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_usd"
            @blur="tranForm.tran_insp_list[scope.$index].prod_usd = helper.calcPrice(tranForm.tran_insp_list[scope.$index].prod_usd, 4, 10000)"
            @input="tranForm.tran_insp_list[scope.$index].prod_usd = helper.keepTNum2(tranForm.tran_insp_list[scope.$index].prod_usd)"
            maxlength="9"
            show-word-limit
            placeholder="暂无单价(美元)"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_subtotal="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_subtotal'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_subtotal"
            maxlength="13"
            show-word-limit
            @blur="tranForm.tran_insp_list[scope.$index].prod_subtotal = helper.calcNum2(tranForm.tran_insp_list[scope.$index].prod_subtotal, 4, 100000000)"
            @input="tranForm.tran_insp_list[scope.$index].prod_subtotal = helper.keepTNum2(tranForm.tran_insp_list[scope.$index].prod_subtotal)"
            placeholder="暂无金额(美元)"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_box_unit="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_box_unit'" :rules="{ required: true, message: ' ' }">
          <el-select v-model="tranForm.tran_insp_list[scope.$index].prod_box_unit" placeholder="请选择数量单位" size="small" filterable>
            <el-option v-for="item in companyList" :value="item.param1" :key="item.id" :label="item.param1"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:prod_gweight="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_gweight'" :rules="{ required: true, message: ' ' }">
          <el-input
            @change="
              totalGrossWeightChange(scope.$index);
              $refs.InspDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_gweight"
            maxlength="8"
            show-word-limit
            @blur="tranForm.tran_insp_list[scope.$index].prod_gweight = helper.calcNum3(tranForm.tran_insp_list[scope.$index].prod_gweight, 2, 10000)"
            @input="tranForm.tran_insp_list[scope.$index].prod_gweight = helper.keepTNum2(tranForm.tran_insp_list[scope.$index].prod_gweight)"
            placeholder="暂无每箱毛重"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_tgweight="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_tgweight'" :rules="{ required: true, message: ' ' }">
          <el-input
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_tgweight"
            maxlength="18"
            @blur="tranForm.tran_insp_list[scope.$index].prod_tgweight = helper.calcNum3(tranForm.tran_insp_list[scope.$index].prod_tgweight, 2, 100000000)"
            @input="tranForm.tran_insp_list[scope.$index].prod_tgweight = helper.keepTNum2(tranForm.tran_insp_list[scope.$index].prod_tgweight)"
            show-word-limit
            placeholder="暂无总毛重"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_nweight="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_nweight'" :rules="{ required: true, message: ' ' }">
          <el-input
            @change="
              totalNetWeight(scope.$index);
              $refs.InspDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_nweight"
            maxlength="8"
            show-word-limit
            @blur="tranForm.tran_insp_list[scope.$index].prod_nweight = helper.calcNum3(tranForm.tran_insp_list[scope.$index].prod_nweight, 2, 10000)"
            @input="tranForm.tran_insp_list[scope.$index].prod_nweight = helper.keepTNum2(tranForm.tran_insp_list[scope.$index].prod_nweight)"
            placeholder="暂无每箱净重"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_tnweight="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_tnweight'" :rules="{ required: true, message: ' ' }">
          <el-input
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_tnweight"
            maxlength="18"
            @blur="tranForm.tran_insp_list[scope.$index].prod_tnweight = helper.calcNum3(tranForm.tran_insp_list[scope.$index].prod_tnweight, 2, 100000000)"
            @input="tranForm.tran_insp_list[scope.$index].prod_tnweight = helper.keepTNum2(tranForm.tran_insp_list[scope.$index].prod_tnweight)"
            show-word-limit
            placeholder="暂无总净重"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_boxl="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_boxl'" :rules="{ required: true, message: ' ', validator: rulesCopy }">
          <el-input
            @change="
              calculateVolume(scope.$index);
              $refs.InspDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_boxl"
            maxlength="7"
            show-word-limit
            @blur="tranForm.tran_insp_list[scope.$index].prod_boxl = helper.calcNum2(tranForm.tran_insp_list[scope.$index].prod_boxl, 2, 10000)"
            @input="tranForm.tran_insp_list[scope.$index].prod_boxl = helper.keepTNum2(tranForm.tran_insp_list[scope.$index].prod_boxl)"
            placeholder="暂无长度"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_boxw="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_boxw'" :rules="{ required: true, message: ' ', validator: rulesCopy }">
          <el-input
            @change="
              calculateVolume(scope.$index);
              $refs.InspDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_boxw"
            maxlength="7"
            show-word-limit
            @blur="tranForm.tran_insp_list[scope.$index].prod_boxw = helper.calcNum2(tranForm.tran_insp_list[scope.$index].prod_boxw, 2, 10000)"
            @input="tranForm.tran_insp_list[scope.$index].prod_boxw = helper.keepTNum2(tranForm.tran_insp_list[scope.$index].prod_boxw)"
            placeholder="暂无宽度"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_boxh="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_boxh'" :rules="{ required: true, message: ' ', validator: rulesCopy }">
          <el-input
            @change="
              calculateVolume(scope.$index);
              $refs.InspDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_boxh"
            maxlength="7"
            show-word-limit
            @blur="tranForm.tran_insp_list[scope.$index].prod_boxh = helper.calcNum2(tranForm.tran_insp_list[scope.$index].prod_boxh, 2, 10000)"
            @input="tranForm.tran_insp_list[scope.$index].prod_boxh = helper.keepTNum2(tranForm.tran_insp_list[scope.$index].prod_boxh)"
            placeholder="暂无高度"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_vol="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_vol'" :rules="{ required: true, message: ' ' }">
          <el-input
            @change="
              totalVolumeChange(scope.$index);
              $refs.InspDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_vol"
            maxlength="9"
            show-word-limit
            @blur="tranForm.tran_insp_list[scope.$index].prod_vol = helper.calcNum3(tranForm.tran_insp_list[scope.$index].prod_vol, 4, 10000)"
            @input="tranForm.tran_insp_list[scope.$index].prod_vol = helper.keepTNum2(tranForm.tran_insp_list[scope.$index].prod_vol)"
            placeholder="暂无每箱体积"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_tvol="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.prod_tvol'" :rules="{ required: true, message: ' ' }">
          <el-input
            v-model.trim="tranForm.tran_insp_list[scope.$index].prod_tvol"
            maxlength="18"
            show-word-limit
            @blur="tranForm.tran_insp_list[scope.$index].prod_tvol = helper.calcNum3(tranForm.tran_insp_list[scope.$index].prod_tvol, 4, 100000000)"
            @input="tranForm.tran_insp_list[scope.$index].prod_tvol = helper.keepTNum2(tranForm.tran_insp_list[scope.$index].prod_tvol)"
            placeholder="暂总体积"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:tran_insp_remark="scope">
        <el-form-item label-width="0" :prop="'tran_insp_list.' + scope.$index + '.tran_insp_remark'">
          <el-input v-model.trim="tranForm.tran_insp_list[scope.$index].tran_insp_remark" maxlength="50" show-word-limit placeholder="暂无备注"></el-input>
        </el-form-item>
      </template>
    </DynamicUTable>
    <el-table v-show="false" ref="multiTable1" :data="tranForm.tran_insp_list" id="multiTable1" border>
      <el-table-column label="序号" width="50" align="center">
        <template v-slot="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="销售合同号" prop="scon_no" />
      <el-table-column label="采购合同号" prop="podr_no" />
      <el-table-column label="供应商简称" prop="supp_abbr" />
      <el-table-column label="起始箱号" prop="tran_insp_ctns" />
      <el-table-column label="结束箱号" prop="tran_insp_ctne" />
      <el-table-column label="原始订单号" prop="scon_cust_ono" />
      <el-table-column label="新订单号" prop="scon_cust_no" />
      <el-table-column label="客户货号" prop="prod_cust_no" />
      <el-table-column label="我司货号" prop="prod_no" />
      <!--      <el-table-column label="货号后缀" prop="prod_poststfix" />-->
      <!--      <el-table-column label="部件名" prop="prod_partno" />-->
      <el-table-column label="英文名称" prop="prod_ename" />
      <el-table-column label="英文规格" prop="prod_espec" />
      <el-table-column label="订单数量" prop="prod_num" />
      <el-table-column label="出运数量" prop="prod_ship" />
      <el-table-column label="数量单位" prop="prod_unit" />
      <el-table-column label="单价(美元)" prop="prod_usd" />
      <el-table-column label="金额(美元)" prop="prod_subtotal" />
      <el-table-column label="装箱数" prop="prod_qpc" />
      <el-table-column label="箱数" prop="prod_box_num" />
      <el-table-column label="包装单位" prop="prod_box_unit" />
      <el-table-column label="每箱毛重(KGS)" prop="prod_gweight" />
      <el-table-column label="总毛重(KGS)" prop="prod_tgweight" />
      <el-table-column label="每箱净重(KGS)" prop="prod_nweight" />
      <el-table-column label="总净重(KGS)" prop="prod_tnweight" />
      <el-table-column label="长度(CM)" prop="prod_boxl" />
      <el-table-column label="宽度(CM)" prop="prod_boxw" />
      <el-table-column label="高度(CM)" prop="prod_boxh" />
      <el-table-column label="每箱体积(CBM)" prop="prod_vol" />
      <el-table-column label="总体积(CBM)" prop="prod_tvol" />
      <el-table-column label="备注" prop="tran_insp_remark" />
    </el-table>
    <el-dialog title="销售合同" :visible.sync="dialogVisible" width="70%">
      <TranPartImport :tranForm="tranForm" ref="TranPartImport" @childConfirm="childConfirm" @childChanel="childChanel"></TranPartImport>
    </el-dialog>
  </div>
</template>

<script>
import TranPartImport from './TranPartImport.vue';
import { myMixin } from '@/assets/js/mixin/tabMixin.js';
import { BigNumber } from 'bignumber.js';
import htmlToExcel from '@/plugins/htmlToExcel';
import textEnlargement from '@/views/component/textEnlargement.vue';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { shangjianTableProperties } from '@/views/TransportationManagement/TranManage/tran';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { EventBus } from '@/main';
import { keep6Decimal } from '@assets/js/regExUtil';
import { cloneDeep, debounce } from 'lodash';
import helper from '@assets/js/helper';

export default {
  name: 'InspDetail',
  computed: {
    helper() {
      return helper;
    }
  },
  props: {
    tranForm: {
      type: Object,
      required: true
    },
    companyList: {
      type: Array,
      required: true
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  mixins: [myMixin],
  data() {
    var checkAge = (rule, value, callback) => {
      if (Number(value) < 10) {
        return callback(new Error('必须大于10'));
      } else {
        callback();
      }
    };
    return {
      shangjianTableProperties: shangjianTableProperties,
      dialogVisible: false,
      selectionsList: [],
      delList: [],
      rulesCopy: checkAge,
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      }
    };
  },
  components: {
    DynamicUTable,
    SearchTable,
    TranPartImport,
    textEnlargement
  },
  created() {
    this.initData();
    EventBus.$on('updateInsp', val => {
      this.$set(this.tranForm, 'tran_insp_list', val);
    });
  },
  methods: {
    keep6Decimal,
    initData() {},
    //列总和
    getSummaries(param) {
      const { columns, data } = param;
      let totalList = [];
      for (let i = 0; i < 33; i++) {
        let temp = '';
        if (i === 0) {
          temp = '合计';
        } else {
          temp = '';
        }
        totalList.push(temp);
      }
      columns.forEach((column, index) => {
        let datas = data;
        let totalA = new BigNumber(0);
        let totalB = new BigNumber(0);
        let totalC = new BigNumber(0);
        let totalD = new BigNumber(0);
        let totalE = new BigNumber(0);
        let totalF = new BigNumber(0);
        let totalG = new BigNumber(0);
        for (let i in datas) {
          totalA = totalA.plus(datas[i].prod_num ? datas[i].prod_num : 0); //总订单数量
          totalB = totalB.plus(datas[i].prod_ship ? datas[i].prod_ship : 0); //总数量
          totalC = totalC.plus(datas[i].prod_subtotal ? datas[i].prod_subtotal : 0); //总金额
          totalD = totalD.plus(datas[i].prod_box_num ? datas[i].prod_box_num : 0); //总箱数
          totalE = totalE.plus(datas[i].prod_tgweight ? datas[i].prod_tgweight : 0); //总毛重
          totalF = totalF.plus(datas[i].prod_tnweight ? datas[i].prod_tnweight : 0); //总净重
          totalG = totalG.plus(datas[i].prod_tvol ? datas[i].prod_tvol : 0); //总体积
        }
        totalA = totalA.toNumber();
        totalB = totalB.toNumber();
        totalC = totalC.toNumber();
        totalD = totalD.toNumber();
        totalE = totalE.toNumber();
        totalF = totalF.toNumber();
        totalG = totalG.toNumber();
        totalList[13] = totalA;
        totalList[14] = totalB;
        totalList[21] = this.helper.haveFour(totalC + '');
        totalList[16] = totalD;
        totalList[24] = this.helper.haveFour(totalE + '');
        totalList[26] = this.helper.haveFour(totalF + '');
        totalList[31] = this.helper.haveFour(totalG + '');
      });
      // this.$emit('totalPriceChange',totalList[15])
      return totalList;
    },
    // 导出excel
    exportToExcel(val) {
      htmlToExcel.getExcel(`#multiTable1`, '商检明细');
    },
    //复制的数据变绿色
    setRowStyle({ row, rowIndex }) {
      row.index = rowIndex;
      switch (row.is_copy) {
        case 1:
          return { background: 'rgb(199,230,165)' };
      }
    },
    //复制商检明细
    copyProd() {
      if (this.selectionsList.length === 0) {
        return this.$message.warning('请至少选择一条数据');
      }
      let is_copy = this.selectionsList.filter(x => x.is_copy === 1);
      if (is_copy.length > 0) {
        return this.$message.warning('无法使用复制的数据复制！');
      }
      this.$confirm('确认复制选中数据？ 复制后此单出运则联动消失', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          let selectionsList = cloneDeep(this.selectionsList);
          let index = this.tranForm.tran_insp_list.length + 1;
          selectionsList.forEach(item => {
            //赋值复制字段为1
            item.is_copy = 1;
            item.tran_insp_id = null;
            item.sub_index = index;
            index++;
          });
          this.tranForm.tran_insp_list = this.tranForm.tran_insp_list.concat(selectionsList);
          //复制后无法联动，改为不联动
          this.tranForm.is_linkage = 1;
          //重置选择框
          this.$refs.InspDetail.clearSelection();
          //key值重置
          this.reductionKey();
        })
        .catch(err => console.error(err));
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.tranForm.tran_insp_list[val1].prod_espec;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.tranForm.tran_insp_list[this.textEnlargementNum].prod_espec = val;
      }
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    clickImport() {
      this.dialogVisible = true;
      setTimeout(() => {
        this.$refs.TranPartImport.searchForm.cust_id = this.tranForm.cust_id;
        this.$refs.TranPartImport.initData();
      }, 100);
    },
    //确认传值
    childConfirm(value) {
      let val = JSON.parse(JSON.stringify(value));
      val = val.filter(item => this.tranForm.tran_insp_list.every(item1 => item.scon_prod_id !== item1.scon_prod_id));
      for (let i in val) {
        val[i].prod_num = '0.0000';
        val[i].scon_cust_no = val[i].scon_cust_no + '';
        val[i].scon_cust_ono = val[i].scon_cust_no;
        val[i].prod_ship = val[i].prod_num;
        val[i].prod_box_unit = 'CTNS';
        val[i].prod_subtotal = this.helper.calcNum2((Number(val[i].prod_usd) * 100000 * Number(val[i].prod_ship)) / 100000);
        val[i].prod_box_num = Math.ceil(Number(val[i].prod_ship) / Number(val[i].prod_qpc));
        val[i].prod_tgweight = this.helper.calcNum3((Number(this.helper.calcNum3(val[i].prod_gweight)) * 100000 * Number(val[i].prod_box_num)) / 100000);
        val[i].prod_tnweight = this.helper.calcNum3((Number(this.helper.calcNum3(val[i].prod_nweight)) * 100000 * Number(val[i].prod_box_num)) / 100000);
        let numVol = new BigNumber(1);
        numVol = numVol
          .times(val[i].prod_boxl ? val[i].prod_boxl : 0)
          .times(val[i].prod_boxw ? val[i].prod_boxw : 0)
          .times(val[i].prod_boxh ? val[i].prod_boxh : 0)
          .div(1000000)
          .toNumber();
        val[i].prod_vol = this.helper.calcNum3(numVol);
        val[i].prod_tvol = this.helper.calcNum3((Number(val[i].prod_vol) * 100000 * Number(val[i].prod_box_num)) / 100000);
        let freeList = val[i].scon_fees_list.filter(item => this.tranForm.tran_fees_list.every(item1 => item.scon_fees_id !== item1.scon_fees_id));
        this.tranForm.tran_fees_list = this.tranForm.tran_fees_list.concat(freeList);
      }
      this.tranForm.tran_insp_list = this.tranForm.tran_insp_list.concat(val);
      this.reductionKey();
      this.dialogVisible = false;
    },
    // 删除
    deleteInsp() {
      if (this.selectionsList.length === 0) {
        return this.$message.info('至少选择一条信息');
      }
      let temp = [];
      for (let i = 0; i < this.selectionsList.length; i++) {
        for (let j = 0; j < this.tranForm.tran_insp_list.length; j++) {
          if (this.selectionsList[i].key === this.tranForm.tran_insp_list[j].key) {
            temp.push(j);
          }
        }
      }
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let i of temp) {
        this.tranForm.tran_insp_list.splice(i, 1);
      }
      //计算序号和key
      this.subIndexCompute();
    },
    subIndexCompute() {
      this.tranForm.tran_insp_list.sort((a, b) => {
        if (a.prod_new_cust_no < b.prod_new_cust_no) return -1;
        else if (a.prod_new_cust_no < b.prod_new_cust_no) return 1;
        else return 0;
      });
      for (let i = 0; i < this.tranForm.tran_insp_list.length; i++) {
        this.tranForm.tran_insp_list[i].key = Number(i);
        this.tranForm.tran_insp_list[i].sub_index = Number(i + 1);
      }
    },
    // 关闭弹框
    childChanel() {
      this.dialogVisible = false;
    },
    calculatedAtDiscountedPrice() {
      this.tranForm.tran_insp_list.forEach(async (item, index) => {
        item.prod_dusd_status = 1;
        await this.prodSubtotal(index);
      });
    },
    // 计算金额
    prodSubtotal(val, val1) {
      let tranInspListElement = this.tranForm.tran_insp_list[val];
      tranInspListElement.prod_subtotal = this.helper.calcNum2(
        (Number(tranInspListElement.prod_dusd_status === 1 ? tranInspListElement.prod_dusd : tranInspListElement.prod_usd) *
          100000 *
          Number(tranInspListElement.prod_ship)) /
          100000
      );
      if (val1 === 0) {
        if (tranInspListElement.prod_ship > tranInspListElement.prod_num) {
          tranInspListElement.prod_ship = tranInspListElement.prod_num;
        } else {
          tranInspListElement.prod_ship = new BigNumber(tranInspListElement.prod_ship).toFixed(2, 0);
        }
        //计算箱数
        this.prodBoxNum(val);
      }
    },
    // 计算总毛重
    totalGrossWeightChange(val) {
      this.tranForm.tran_insp_list[val].prod_tgweight = this.helper.calcNum3(
        (Number(this.helper.calcNum3(this.tranForm.tran_insp_list[val].prod_gweight)) * 100000 * Number(this.tranForm.tran_insp_list[val].prod_box_num)) /
          100000
      );
    },
    // 计算总净重
    totalNetWeight(val) {
      this.tranForm.tran_insp_list[val].prod_tnweight = this.helper.calcNum3(
        (Number(this.helper.calcNum3(this.tranForm.tran_insp_list[val].prod_nweight)) * 100000 * Number(this.tranForm.tran_insp_list[val].prod_box_num)) /
          100000
      );
    },
    // 总体积
    totalVolumeChange(val) {
      this.tranForm.tran_insp_list[val].prod_tvol = this.helper.calcNum3(
        (Number(this.tranForm.tran_insp_list[val].prod_vol) * 100000 * Number(this.tranForm.tran_insp_list[val].prod_box_num)) / 100000
      );
    },
    // 计算体积
    calculateVolume(val) {
      let num = new BigNumber(1);
      num = num
        .times(this.tranForm.tran_insp_list[val].prod_boxl ? this.tranForm.tran_insp_list[val].prod_boxl : 0)
        .times(this.tranForm.tran_insp_list[val].prod_boxw ? this.tranForm.tran_insp_list[val].prod_boxw : 0)
        .times(this.tranForm.tran_insp_list[val].prod_boxh ? this.tranForm.tran_insp_list[val].prod_boxh : 0)
        .div(1000000)
        .toNumber();
      this.tranForm.tran_insp_list[val].prod_vol = this.helper.calcNum3(num);
      this.totalVolumeChange(val);
    },
    message: debounce(function () {
      this.$message.warning('箱数未能整除，已向上取整');
    }, 1000),
    // 计算箱数
    prodBoxNum(val) {
      if (this.tranForm.tran_insp_list[val].prod_qpc === '' || this.tranForm.tran_insp_list[val].prod_qpc === '0') {
        this.tranForm.tran_insp_list[val].prod_qpc = 0;
        this.tranForm.tran_insp_list[val].prod_box_num = 0;
        return;
      }
      if (Number(this.tranForm.tran_insp_list[val].prod_ship) % Number(this.tranForm.tran_insp_list[val].prod_qpc) !== 0) {
        this.message();
      }
      this.tranForm.tran_insp_list[val].prod_box_num = Math.ceil(
        Number(this.tranForm.tran_insp_list[val].prod_ship) / Number(this.tranForm.tran_insp_list[val].prod_qpc)
      );
      this.totalGrossWeightChange(val);
      this.totalNetWeight(val);
      this.calculateVolume(val);
    },
    //上移
    moveUp() {
      if (this.selectionsList.length < 1) {
        this.$message.warning('请选择一条数据');
      } else if (this.selectionsList.length > 1) {
        this.$message.warning('只能选择一条数据');
      } else {
        let temp = JSON.parse(JSON.stringify(this.selectionsList[0]));
        if (temp.key === 0) {
          this.$message.warning('已经是第一条');
        } else {
          let ind = temp.key;
          this.tranForm.tran_insp_list.splice(ind, 1);
          this.tranForm.tran_insp_list.splice(ind - 1, 0, temp);
        }
      }
      this.reductionKey();
    },
    moveDown() {
      if (this.selectionsList.length < 1) {
        this.$message.warning('请选择一条数据');
      } else if (this.selectionsList.length > 1) {
        this.$message.warning('只能选择一条数据');
      } else {
        let temp = JSON.parse(JSON.stringify(this.selectionsList[0]));
        if (temp.key === this.tranForm.tran_insp_list.length - 1) {
          this.$message.warning('已经是最后一条');
        } else {
          let ind = temp.key;
          this.tranForm.tran_insp_list.splice(ind, 1);
          this.tranForm.tran_insp_list.splice(ind + 1, 0, temp);
        }
      }
      this.reductionKey();
    },
    // 初始化箱封号
    initialization() {
      // let flag = true;
      // for (let i = 0; i < this.tranForm.tran_insp_list.length; i++) {
      //   if (
      // !this.tranForm.tran_insp_list[i].prod_ship ||
      // !this.tranForm.tran_insp_list[i].prod_qpc ||
      //     !this.tranForm.tran_insp_list[i].prod_box_num
      //   ) {
      //     flag = false;
      //   }
      // }
      // if (!flag || this.tranForm.tran_insp_list.length < 1) {
      //   return this.$message.warning('请将出运数量和箱数补充完整且不能为0');
      // }
      for (let i = 0; i < this.tranForm.tran_insp_list.length; i++) {
        let insp = this.tranForm.tran_insp_list.find(item => item.sub_index === i + 1);
        if (i + 1 === 1) {
          insp.tran_insp_ctns = 1;
          insp.tran_insp_ctne = 0 + Number(insp.prod_box_num);
        } else {
          let insp2 = this.tranForm.tran_insp_list.find(item => item.sub_index === i);
          insp.tran_insp_ctns = Number(insp2.tran_insp_ctne) + 1;
          insp.tran_insp_ctne = Number(insp2.tran_insp_ctne) + Number(insp.prod_box_num);
        }
      }
    },
    // key值重置
    reductionKey() {
      for (let i = 0; i < this.tranForm.tran_insp_list.length; i++) {
        this.tranForm.tran_insp_list[i].key = Number(i);
      }
      for (let i = 0; i < this.tranForm.tran_fees_list.length; i++) {
        this.tranForm.tran_fees_list[i].key = Number(i);
      }
      this.$refs.InspDetail.clearSelection();
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .elx-table.elx-editable.size--medium .elx-body--column,
.elx-table.size--medium .elx-body--column.col--ellipsis,
.elx-table.size--medium .elx-footer--column.col--ellipsis,
.elx-table.size--medium .elx-header--column.col--ellipsis {
  height: 34px;
}

::v-deep .showRow {
  display: none;
}

::v-deep .fixed-cell {
  //  color:red !important
  background-color: #ccc !important;
}

::v-deep .el-form-item.is-required .el-form-item__label:before {
  content: '';
  display: none;
}
</style>
