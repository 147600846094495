<template>
  <div class="PartDetail">
    <div class="vg_mb_16">
      <el-button type="danger" plain size="small" @click="deletePart">删除</el-button>
      <el-button type="primary" plain size="small" @click="prodToPart">需要商检</el-button>
      <el-button type="primary" plain size="small" @click="importTarnVisible = true">导入待出运清单</el-button>
      <el-button plain size="small" @click="initialization">初始化箱封号</el-button>
      <el-button plain size="small" @click="calculatePrice">计算报关单价</el-button>
      <el-button plain size="small" @click="sortTableData">一键排序</el-button>
      <el-button class="tb-btn" size="small" @click="moveUp">上移</el-button>
      <el-button class="tb-btn" size="small" @click="moveDown">下移</el-button>
      <el-button class="tb-btn" size="small" :disabled="false" @click="exportToExcel('allTable')">导出到Excel </el-button>
      <el-button type="primary" size="small" @click="copyProd">复制</el-button>
      <el-button :disabled="false" size="small" type="warning" @click="copySelectedColumnsToClipboard"> 托书专用复制 </el-button>
    </div>
    <DynamicUTable
      ref="PartDetail"
      :row-move="false"
      :form="tranForm"
      v-loading="sortFlag"
      :tableData="tranForm.tran_part_list"
      :columns="baoguaTableProperties"
      :isShow="isShow"
      :needPagination="false"
      :needSearch="false"
      :show-summary="true"
      :needClosedUpdate="true"
      :summariesColumns="['prod_num', 'prod_ship', 'prod_subtotal', 'prod_box_num', 'prod_tgweight', 'prod_tnweight', 'prod_tvol']"
      :needAllColumnSummaryToFixed="3"
      @selection-change="handleSelectionChange"
      :computed-data="{ tran_part_fee: calculateTranProdFeed }"
      :row-style="setRowStyle"
      :openDefaultCommonSlots="false"
      @otherSortChange="otherSortChange"
      :need-index="false"
      @copyChange="({ tIndex, prop }) => (prop === 'prod_qpc' ? prodBoxNum(tIndex) : '')"
    >
      <template v-slot:podr_no="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.podr_no'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_part_list[scope.$index].podr_no"
            maxlength="20"
            show-word-limit
            placeholder="暂无采购合同号"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_no="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.scon_no'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_part_list[scope.$index].scon_no"
            maxlength="20"
            show-word-limit
            placeholder="暂无销售合同号"
          ></el-input>
        </el-form-item>
      </template>
      <!--      <template v-slot:header-tran_part_main>
        <div class="vg_dis_flex">
          <span class="vg_deep_red">是否是主产品</span>
          <div class="vg_ml_8 vd_dirc vg_cursor_hander" @click.stop="sortChange()">
            <i class="el-icon-caret-top" id="sortNum1"></i>
            <i class="el-icon-caret-bottom" id="sortNum2"></i>
          </div>
        </div>
      </template>-->
      <template v-slot:special-tran_part_main="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.tran_part_main'" :rules="{ required: true, message: ' ' }">
          <div class="vd_disFlex">
            <el-radio @change="tranPartMainChange(scope.$index)" v-model="tranForm.tran_part_list[scope.$index].tran_part_main" :label="0"> 否 </el-radio>
            <el-radio @change="tranPartMainChange(scope.$index)" v-model="tranForm.tran_part_list[scope.$index].tran_part_main" :label="1"> 是 </el-radio>
          </div>
        </el-form-item>
      </template>
      <!--      <template v-slot:header-tran_part_claim>
        <div class="vg_dis_flex">
          <span class="vg_deep_red">是否报关</span>
          <div class="vg_ml_8 vd_dirc vg_cursor_hander" @click.stop="sortChange1()">
            <i class="el-icon-caret-top" id="sortNum3"></i>
            <i class="el-icon-caret-bottom" id="sortNum4"></i>
          </div>
        </div>
      </template>-->
      <template v-slot:special-tran_part_claim="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.tran_part_claim'" :rules="{ required: true, message: ' ' }">
          <div class="vd_disFlex">
            <el-radio @change="tranPartMainChange(scope.$index)" v-model="tranForm.tran_part_list[scope.$index].tran_part_claim" :label="0"> 否 </el-radio>
            <el-radio @change="tranPartMainChange(scope.$index)" v-model="tranForm.tran_part_list[scope.$index].tran_part_claim" :label="1"> 是 </el-radio>
          </div>
        </el-form-item>
      </template>
      <template v-slot:scon_cust_no="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.scon_cust_no'" :rules="{ required: true, message: ' ' }">
          <el-input v-model.trim="tranForm.tran_part_list[scope.$index].scon_cust_no" maxlength="20" show-word-limit placeholder="暂无新订单号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:scon_cust_ono="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.scon_cust_ono'" :rules="{ required: true, message: ' ' }">
          <el-input
            disabled
            v-model.trim="tranForm.tran_part_list[scope.$index].scon_cust_ono"
            maxlength="20"
            show-word-limit
            placeholder="暂无原始订单号"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_cust_no="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_cust_no'" :rules="{ required: true, message: ' ' }">
          <el-input
            disabled
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_cust_no"
            maxlength="20"
            show-word-limit
            placeholder="暂无客户货号"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_new_cust_no="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_new_cust_no'">
          <el-input
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_new_cust_no"
            maxlength="20"
            show-word-limit
            placeholder="暂无新客户货号"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_no="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_no'">
          <el-input disabled v-model.trim="tranForm.tran_part_list[scope.$index].prod_no" maxlength="20" show-word-limit placeholder="暂无我司货号"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_poststfix="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_poststfix'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_poststfix"
            maxlength="20"
            show-word-limit
            placeholder="暂无货号后缀"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_qpc="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_qpc'" :rules="{ required: true, message: ' ' }">
          <el-input
            @change="
              prodBoxNum(scope.$index);
              $refs.PartDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_qpc"
            maxlength="10"
            show-word-limit
            @input="tranForm.tran_part_list[scope.$index].prod_qpc = helper.keepTNum(tranForm.tran_part_list[scope.$index].prod_qpc)"
            placeholder="暂无装箱数"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_box_num="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_box_num'">
          <el-input v-model.trim="tranForm.tran_part_list[scope.$index].prod_box_num" maxlength="20" show-word-limit placeholder="暂无箱数"></el-input>
        </el-form-item>
      </template>
      <template v-slot:tran_part_ctns="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.tran_part_ctns'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_part_list[scope.$index].tran_part_ctns"
            maxlength="20"
            show-word-limit
            placeholder="暂无起始箱号"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:tran_part_ctne="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.tran_part_ctne'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_part_list[scope.$index].tran_part_ctne"
            maxlength="20"
            show-word-limit
            placeholder="暂无结束箱号"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_name="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_name'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_name"
            maxlength="100"
            show-word-limit
            placeholder="暂无中文名称"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_ename="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_ename'">
          <el-tooltip class="item" effect="light" :content="tranForm.tran_part_list[scope.$index].prod_ename" placement="top-start">
            <el-input v-model="tranForm.tran_part_list[scope.$index].prod_ename" show-word-limit placeholder="暂无英文名称"></el-input>
          </el-tooltip>
        </el-form-item>
      </template>
      <template v-slot:prod_saprice="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_saprice'">
          <el-input
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_saprice"
            @input="tranForm.tran_part_list[scope.$index].prod_saprice = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_saprice)"
            @blur="tranForm.tran_part_list[scope.$index].prod_saprice = helper.calcPrice(tranForm.tran_part_list[scope.$index].prod_saprice, 4, 10000)"
            maxlength="9"
            show-word-limit
            placeholder="暂无成品销售价"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_partno="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_partno'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_partno"
            maxlength="20"
            show-word-limit
            placeholder="暂无部件名"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_num="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_num'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_num"
            @input="tranForm.tran_part_list[scope.$index].prod_num = helper.keepTNum(tranForm.tran_part_list[scope.$index].prod_num)"
            maxlength="10"
            show-word-limit
            placeholder="暂无订单数量"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_usd="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_usd'" :rules="{ required: true, message: ' ' }">
          <el-input
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_usd"
            maxlength="9"
            show-word-limit
            @input="tranForm.tran_part_list[scope.$index].prod_usd = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_usd)"
            @blur="tranForm.tran_part_list[scope.$index].prod_usd = helper.calcPrice(tranForm.tran_part_list[scope.$index].prod_usd, 4, 10000)"
            placeholder="暂无部件销售价(美元)"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:header-prod_dusd="scope">
        <div class="flexHV">
          <span class="vg_deep_red">部件折扣价(美元)</span>
          <el-tooltip style="margin-left: auto" effect="dark" content="归零" placement="right">
            <i class="el-icon-delete vg_cursor" @click="tranForm.tran_part_list.forEach(x => (x.prod_dusd = 0))"></i>
          </el-tooltip>
        </div>
      </template>
      <template v-slot:prod_dusd="scope">
        <el-form-item
          label-width="0"
          :prop="'tran_part_list.' + scope.$index + '.prod_dusd'"
          :rules="
            tranForm.tran_part_list[scope.$index].tran_part_claim === 1 && tranForm.tran_part_list[scope.$index].tran_part_main === 1
              ? { required: true, message: ' ' }
              : { required: false }
          "
        >
          <el-input
            @change="
              prodSubtotal(scope.$index, 1);
              $refs.PartDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_dusd"
            @input="tranForm.tran_part_list[scope.$index].prod_dusd = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_dusd)"
            @blur="tranForm.tran_part_list[scope.$index].prod_dusd = helper.calcPrice(tranForm.tran_part_list[scope.$index].prod_dusd, 4, 10000)"
            maxlength="9"
            show-word-limit
            placeholder="暂无部件折扣价(美元)"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:price_gap="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.price_gap'">
          <el-input
            @change="
              prodSubtotal(scope.$index, 1);
              $refs.PartDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_part_list[scope.$index].price_gap"
            :disabled="tranForm.tran_part_list[scope.$index].tran_part_claim === 0 && tranForm.tran_part_list[scope.$index].tran_part_main === 0"
            @input="tranForm.tran_part_list[scope.$index].price_gap = helper.keepTNum2(tranForm.tran_part_list[scope.$index].price_gap)"
            @blur="tranForm.tran_part_list[scope.$index].price_gap = helper.calcPrice(tranForm.tran_part_list[scope.$index].price_gap, 4, 10000)"
            maxlength="9"
            show-word-limit
            placeholder="暂无部件折扣价(美元)"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_ship="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_ship'" :rules="{ required: true, message: ' ' }">
          <el-input
            @change="
              prodSubtotal(scope.$index, 0);
              $refs.PartDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_ship"
            maxlength="10"
            show-word-limit
            @input="tranForm.tran_part_list[scope.$index].prod_ship = keep6Decimal(tranForm.tran_part_list[scope.$index].prod_ship)"
            placeholder="暂无出运数量"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_unit="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_unit'" :rules="{ required: true, message: ' ' }">
          <el-select v-model="tranForm.tran_part_list[scope.$index].prod_unit" placeholder="请选择部件单位" size="small" filterable>
            <el-option v-for="item in companyList" :value="item.param1" :key="item.id" :label="item.param1"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:prod_subtotal="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_subtotal'">
          <el-input
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_subtotal"
            maxlength="13"
            @input="tranForm.tran_part_list[scope.$index].prod_subtotal = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_subtotal)"
            show-word-limit
            placeholder="暂无金额(美元)"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_price="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_price'" :rules="{ required: true, message: ' ' }">
          <el-input
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_price"
            maxlength="9"
            show-word-limit
            @input="tranForm.tran_part_list[scope.$index].prod_price = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_price)"
            @blur="tranForm.tran_part_list[scope.$index].prod_price = helper.calcPrice(tranForm.tran_part_list[scope.$index].prod_price, 4, 100000000)"
            placeholder="暂无采购单价"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_hscode="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_hscode'" :rules="{ required: true, message: ' ' }">
          <el-input
            @focus="
              dialogTableVisible = true;
              openIndex = scope.$index;
            "
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_hscode"
            maxlength="30"
            show-word-limit
            placeholder="暂无海关编码"
          >
            <el-link
              type="primary"
              slot="append"
              :disabled="isShow"
              @click="
                dialogTableVisible = true;
                openIndex = scope.$index;
              "
            >
              选择
            </el-link>
          </el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_hsname="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_hsname'" :rules="{ required: true, message: ' ' }">
          <el-input v-model.trim="tranForm.tran_part_list[scope.$index].prod_hsname" maxlength="30" show-word-limit placeholder="暂无海关中文品名"></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_ehsname="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_ehsname'" :rules="{ required: true, message: ' ' }">
          <el-input v-model.trim="tranForm.tran_part_list[scope.$index].prod_ehsname" maxlength="30" placeholder="暂无海关英文品名" show-word-limit></el-input>
        </el-form-item>
      </template>
      <template v-slot:supp_abbr="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.supp_abbr'">
          <el-input
            disabled
            v-model.trim="tranForm.tran_part_list[scope.$index].supp_abbr"
            maxlength="20"
            show-word-limit
            placeholder="暂无供应商简称"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_box_unit="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_box_unit'" :rules="{ required: true, message: ' ' }">
          <el-select v-model="tranForm.tran_part_list[scope.$index].prod_box_unit" placeholder="请选择数量单位" size="small" filterable>
            <el-option v-for="item in companyList" :value="item.param1" :key="item.id" :label="item.param1"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <template v-slot:prod_gweight="scope">
        <el-form-item
          label-width="0"
          :prop="'tran_part_list.' + scope.$index + '.prod_gweight'"
          :rules="
            tranForm.tran_part_list[scope.$index].tran_part_claim === 1 && tranForm.tran_part_list[scope.$index].tran_part_main === 1
              ? { required: true, message: ' ' }
              : { required: false }
          "
        >
          <el-input
            @change="
              totalGrossWeightChange(scope.$index);
              $refs.PartDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_gweight"
            maxlength="8"
            show-word-limit
            @blur="tranForm.tran_part_list[scope.$index].prod_gweight = helper.calcNum3(tranForm.tran_part_list[scope.$index].prod_gweight, 2, 10000)"
            @input="tranForm.tran_part_list[scope.$index].prod_gweight = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_gweight)"
            placeholder="暂无每箱毛重"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_tgweight="scope">
        <el-form-item
          label-width="0"
          :prop="'tran_part_list.' + scope.$index + '.prod_tgweight'"
          :rules="
            tranForm.tran_part_list[scope.$index].tran_part_claim === 1 && tranForm.tran_part_list[scope.$index].tran_part_main === 1
              ? { required: true, message: ' ' }
              : { required: false }
          "
        >
          <el-input
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_tgweight"
            maxlength="18"
            @blur="tranForm.tran_part_list[scope.$index].prod_tgweight = helper.calcNum3(tranForm.tran_part_list[scope.$index].prod_tgweight, 2, 10000000000)"
            @input="tranForm.tran_part_list[scope.$index].prod_tgweight = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_tgweight)"
            show-word-limit
            placeholder="暂无总毛重"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_nweight="scope">
        <el-form-item
          label-width="0"
          :prop="'tran_part_list.' + scope.$index + '.prod_nweight'"
          :rules="
            tranForm.tran_part_list[scope.$index].tran_part_claim === 1 && tranForm.tran_part_list[scope.$index].tran_part_main === 1
              ? { required: true, message: ' ' }
              : { required: false }
          "
        >
          <el-input
            @change="
              totalNetWeight(scope.$index);
              $refs.PartDetail.$refs.multiTable.updateData();
            "
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_nweight"
            maxlength="8"
            show-word-limit
            @blur="tranForm.tran_part_list[scope.$index].prod_nweight = helper.calcNum3(tranForm.tran_part_list[scope.$index].prod_nweight, 2, 10000)"
            @input="tranForm.tran_part_list[scope.$index].prod_nweight = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_nweight)"
            placeholder="暂无每箱净重"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_tnweight="scope">
        <el-form-item
          label-width="0"
          :prop="'tran_part_list.' + scope.$index + '.prod_tnweight'"
          :rules="
            tranForm.tran_part_list[scope.$index].tran_part_claim === 1 && tranForm.tran_part_list[scope.$index].tran_part_main === 1
              ? { required: true, message: ' ' }
              : { required: false }
          "
        >
          <el-input
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_tnweight"
            maxlength="18"
            @blur="tranForm.tran_part_list[scope.$index].prod_tnweight = helper.calcNum3(tranForm.tran_part_list[scope.$index].prod_tnweight, 2, 10000000000)"
            @input="tranForm.tran_part_list[scope.$index].prod_tnweight = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_tnweight)"
            show-word-limit
            placeholder="暂无总净重"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:special-prod_boxl="scope">
        <div style="display: flex">
          <el-form-item
            label-width="0"
            :prop="'tran_part_list.' + scope.$index + '.prod_boxl'"
            :rules="tranForm.tran_part_list[scope.$index].tran_part_main === 1 ? { required: true, message: ' ', validator: rulesCopy } : { required: false }"
          >
            <el-input
              @change="
                calculateVolume(scope.$index);
                $refs.PartDetail.$refs.multiTable.updateData();
              "
              v-model.trim="tranForm.tran_part_list[scope.$index].prod_boxl"
              @blur="tranForm.tran_part_list[scope.$index].prod_boxl = helper.calcNum2(tranForm.tran_part_list[scope.$index].prod_boxl, 2, 10000)"
              maxlength="7"
              show-word-limit
              @input="tranForm.tran_part_list[scope.$index].prod_boxl = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_boxl)"
              placeholder="暂无"
            ></el-input>
          </el-form-item>
          <span>*</span>
          <el-form-item
            label-width="0"
            :prop="'tran_part_list.' + scope.$index + '.prod_boxw'"
            :rules="tranForm.tran_part_list[scope.$index].tran_part_main === 1 ? { required: true, message: ' ', validator: rulesCopy } : { required: false }"
          >
            <el-input
              @change="
                calculateVolume(scope.$index);
                $refs.PartDetail.$refs.multiTable.updateData();
              "
              v-model.trim="tranForm.tran_part_list[scope.$index].prod_boxw"
              @blur="tranForm.tran_part_list[scope.$index].prod_boxw = helper.calcNum2(tranForm.tran_part_list[scope.$index].prod_boxw, 2, 10000)"
              maxlength="7"
              show-word-limit
              @input="tranForm.tran_part_list[scope.$index].prod_boxw = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_boxw)"
              placeholder="暂无"
            ></el-input>
          </el-form-item>
          <span>*</span>
          <el-form-item
            label-width="0"
            :prop="'tran_part_list.' + scope.$index + '.prod_boxh'"
            :rules="tranForm.tran_part_list[scope.$index].tran_part_main === 1 ? { required: true, message: ' ', validator: rulesCopy } : { required: false }"
          >
            <el-input
              @change="
                calculateVolume(scope.$index);
                $refs.PartDetail.$refs.multiTable.updateData();
              "
              v-model.trim="tranForm.tran_part_list[scope.$index].prod_boxh"
              @blur="tranForm.tran_part_list[scope.$index].prod_boxh = helper.calcNum2(tranForm.tran_part_list[scope.$index].prod_boxh, 2, 10000)"
              maxlength="7"
              show-word-limit
              @input="tranForm.tran_part_list[scope.$index].prod_boxh = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_boxh)"
              placeholder="暂无"
            ></el-input>
          </el-form-item>
        </div>
      </template>
      <template v-slot:prod_vol="scope">
        <el-form-item
          label-width="0"
          :prop="'tran_part_list.' + scope.$index + '.prod_vol'"
          :rules="tranForm.tran_part_list[scope.$index].tran_part_main === 1 ? { required: true, message: ' ' } : { required: false }"
        >
          <el-input
            @change="
              totalVolumeChange(scope.$index);
              $refs.PartDetail.$refs.multiTable.updateData();
            "
            :disabled="tranForm.tran_part_list[scope.$index].tran_part_main === 0"
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_vol"
            maxlength="20"
            show-word-limit
            @blur="tranForm.tran_part_list[scope.$index].prod_vol = helper.calcNum3(tranForm.tran_part_list[scope.$index].prod_vol, 2, 10000)"
            @input="tranForm.tran_part_list[scope.$index].prod_vol = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_vol)"
            placeholder="暂无每箱体积"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_tvol="scope">
        <el-form-item
          label-width="0"
          :prop="'tran_part_list.' + scope.$index + '.prod_tvol'"
          :rules="tranForm.tran_part_list[scope.$index].tran_part_main === 1 ? { required: true, message: ' ' } : { required: false }"
        >
          <el-input
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_tvol"
            maxlength="18"
            show-word-limit
            @blur="tranForm.tran_part_list[scope.$index].prod_tvol = helper.calcNum3(tranForm.tran_part_list[scope.$index].prod_tvol, 4, 100000000)"
            @input="tranForm.tran_part_list[scope.$index].prod_tvol = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_tvol)"
            placeholder="暂总体积"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:default-prod_tvol="scope">
        <el-form-item
          label-width="0"
          :prop="'tran_part_list.' + scope.$index + '.prod_tvol'"
          :rules="tranForm.tran_part_list[scope.$index].tran_part_main === 1 ? { required: true, message: ' ' } : { required: false }"
        >
          <el-input
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_tvol"
            maxlength="13"
            show-word-limit
            @blur="tranForm.tran_part_list[scope.$index].prod_tvol = helper.calcNum3(tranForm.tran_part_list[scope.$index].prod_tvol, 4, 100000000)"
            @input="tranForm.tran_part_list[scope.$index].prod_tvol = helper.keepTNum2(tranForm.tran_part_list[scope.$index].prod_tvol)"
            placeholder="暂总体积"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:prod_hsoutrate="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_hsoutrate'" :rules="{ required: true, message: ' ' }">
          <el-input
            disabled
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_hsoutrate"
            maxlength="20"
            show-word-limit
            placeholder="暂无退税率"
          ></el-input>
          <!-- <el-select v-model="tranForm.tran_part_list[scope.$index].prod_hsoutrate" placeholder="请选择数量单位" size="small"                  filterable>                  <el-option v-for="item in customsList" :value="item.param5" :key="item.id"                    :label="item.param5"></el-option>                </el-select> -->
        </el-form-item>
      </template>
      <template v-slot:prod_hsinrate="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.prod_hsinrate'" :rules="{ required: true, message: ' ' }">
          <el-input
            disabled
            v-model.trim="tranForm.tran_part_list[scope.$index].prod_hsinrate"
            maxlength="20"
            show-word-limit
            placeholder="暂增值税率"
          ></el-input>
        </el-form-item>
      </template>
      <template v-slot:tran_part_fee="scope">
        <el-form-item label-width="0" :prop="'tran_part_list.' + scope.$index + '.tran_part_fee'">
          <el-input disabled :value="calculateTranProdFeed(scope.$index)" maxlength="13" show-word-limit placeholder="暂无验货费"></el-input>
        </el-form-item>
      </template>
    </DynamicUTable>
    <el-table v-show="false" ref="multiTable" :data="tranForm.tran_part_list" id="multiTable" border>
      <el-table-column label="序号" width="50" align="center">
        <template v-slot="scope">
          <span>{{ scope.$index + 1 }}</span>
        </template>
      </el-table-column>
      <el-table-column label="销售合同号" prop="scon_no" />
      <el-table-column label="采购合同号" prop="podr_no" />
      <el-table-column label="供应商简称" prop="supp_abbr" />
      <el-table-column label="是否主产品">
        <template v-slot="scope">
          <span v-if="scope.row.tran_part_main === 0">否</span>
          <span v-if="scope.row.tran_part_main === 1">是</span>
        </template>
      </el-table-column>
      <el-table-column label="是否报关">
        <template v-slot="scope">
          <span v-if="scope.row.tran_part_claim === 0">否</span>
          <span v-if="scope.row.tran_part_claim === 1">是</span>
        </template>
      </el-table-column>
      <el-table-column label="起始箱号" prop="tran_part_ctns" />
      <el-table-column label="结束箱号" prop="tran_part_ctne" />
      <el-table-column label="原始订单号" prop="scon_cust_ono" />
      <el-table-column label="新订单号" prop="scon_cust_no" />
      <el-table-column label="客户货号" prop="prod_cust_no" />
      <el-table-column label="新客户货号" prop="prod_new_cust_no" />
      <el-table-column label="我司货号" prop="prod_no" />
      <el-table-column label="货号后缀" prop="prod_poststfix" />
      <el-table-column label="部件名" prop="prod_partno" />
      <el-table-column label="中文名称" prop="prod_name" />
      <el-table-column label="中文规格" prop="prod_spec" />
      <el-table-column label="海关编码" prop="prod_hscode" />
      <el-table-column label="海关中文品名" prop="prod_hsname" />
      <el-table-column label="海关英文品名" prop="prod_ehsname" />
      <el-table-column label="订单数量" prop="prod_num" />
      <el-table-column label="出运数量" prop="prod_ship" />
      <el-table-column label="部件单位" prop="prod_unit" />
      <el-table-column label="部件销售价($)" prop="prod_usd" />
      <el-table-column label="部件折扣价($)" prop="prod_dusd" />
      <el-table-column label="销售价差($)" prop="prod_dusd" />
      <el-table-column label="金额($)" prop="prod_subtotal" />
      <el-table-column label="采购单价" prop="prod_price" />
      <el-table-column label="装箱数" prop="prod_qpc" />
      <el-table-column label="箱数" prop="prod_box_num" />
      <el-table-column label="包装单位" prop="prod_box_unit" />
      <el-table-column label="每箱毛重(KGS)" prop="prod_gweight" />
      <el-table-column label="总毛重(KGS)" prop="prod_tgweight" />
      <el-table-column label="每箱净重(KGS)" prop="prod_nweight" />
      <el-table-column label="总净重(KGS)" prop="prod_tnweight" />
      <el-table-column label="长*宽*高(CM)">
        <template v-slot="scope">
          <span>{{ tranForm.tran_part_list[scope.$index].prod_boxl }}</span>
          *
          <span>{{ tranForm.tran_part_list[scope.$index].prod_boxw }}</span>
          *
          <span>{{ tranForm.tran_part_list[scope.$index].prod_boxh }}</span>
        </template>
      </el-table-column>
      <el-table-column label="每箱体积(CBM)" prop="prod_vol" />
      <el-table-column label="总体积(CBM)" prop="prod_tvol" />
      <el-table-column label="退税率" prop="prod_hsoutrate" />
      <el-table-column label="增值税率" prop="prod_hsinrate" />
      <el-table-column label="验货费" prop="tran_part_fee" />
    </el-table>
    <el-dialog title="海关编码" width="70%" :visible.sync="dialogTableVisible">
      <customsList @changeCustom="changeCustom"></customsList>
    </el-dialog>
    <el-dialog title="导入出运清单" width="90%" :visible.sync="importTarnVisible" @opened="spinListRefresh">
      <SpinList @importData="importData" :tranForm="tranForm" ref="spinList"></SpinList>
    </el-dialog>
  </div>
</template>

<script>
import { getNoCatch } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import { myMixin } from '@/assets/js/mixin/tabMixin.js';
import { BigNumber } from 'bignumber.js';
import CustomsList from './CustomsList.vue';
import htmlToExcel from '@/plugins/htmlToExcel';
import textEnlargement from '@/views/component/textEnlargement.vue';
import { baoguaTableProperties } from '@/views/TransportationManagement/TranManage/tran';
import SearchTable from '@/components/table/SearchTableV2.vue';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { EventBus } from '@/main';
import TranPartImport from '@/views/TransportationManagement/TranManage/TabChild/Component/TranPartImport.vue';
import PartGroup from '@/views/TransportationManagement/TranManage/TabChild/Component/PartGroup.vue';
import SpinList from '@/views/TransportationManagement/TranManage/TabChild/Component/SpinList.vue';
import { cloneDeep, debounce } from 'lodash';
import { keep6Decimal } from '@assets/js/regExUtil';
import helper from '@assets/js/helper';

export default {
  name: 'PartDetail',
  mixins: [myMixin],
  components: {
    SpinList,
    PartGroup,
    TranPartImport,
    DynamicUTable,
    SearchTable,
    CustomsList,
    textEnlargement
  },
  props: {
    tranForm: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      required: true
    },
    companyList: {
      type: Array,
      required: true
    }
  },
  computed: {
    helper() {
      return helper;
    },
    // 验货费
    calculateTranProdFeed() {
      return index => {
        let prodNum = this.tranForm.tran_part_list[index].prod_num;
        let result = 0;
        this.custCurrencyList.forEach(({ param1, param2, param3 }) => {
          if (prodNum >= param1 && prodNum < param2) {
            this.tranForm.tran_part_list[index].tran_part_fee = Number(param3);
            result = param3;
          }
        });
        return result;
      };
    }
  },
  created() {
    this.initData();
    EventBus.$on('updatePart', val => {
      this.$set(this.tranForm, 'tran_part_list', val);
    });
  },
  async mounted() {
    // 获取币种
    await getNoCatch(optnAPI.getOptnByPermId, { perm_id: 10027 }).then(({ data }) => {
      this.custCurrencyList = data.form.optn_cntt_list;
    });
    await this.tranForm.tran_part_list.forEach((item, index) => {
      this.calculateTranProdFeed(index);
    });
  },
  data() {
    var checkAge = (rule, value, callback) => {
      if (Number(value) < 10) {
        return callback(new Error('必须大于10'));
      } else {
        callback();
      }
    };
    return {
      baoguaTableProperties: baoguaTableProperties,
      custCurrencyList: [],
      selectionsList: [],
      rulesCopy: checkAge,
      customsList: [],
      dialogTableVisible: false,
      importTarnVisible: false,
      openIndex: 0,
      textEnlargementNum: 0,
      textEnlargementFlag: 0,
      textEnlargementString: {
        str: ''
      },
      sortNum: 0,
      sortNum1: 1,
      sortFlag: false
    };
  },
  methods: {
    keep6Decimal,
    async initData() {
      //let deptIds = await getDept(['业务一部', '业务三部', '业务四部', '电商部', 'IT部'], 'ids');
      let find = this.baoguaTableProperties.find(x => x.prop === 'cust_brand');
      find.subItem.options = Array.from(this.tranForm.cust_brand_list, item => {
        return { value: item, label: item };
      });
      //find.visible = deptIds.includes(this.$cookies.get('userInfo').dept_id);
      setTimeout(() => {
        if (this.tranForm.cust_brand_list.length === 1) this.tranForm.tran_part_list.forEach(item => (item.cust_brand = this.tranForm.cust_brand_list[0]));
        // this.$refs.PartDetail.$refs.multiTable.refreshColumn();
      });
    },
    sortTableData() {
      // return a.scon_cust_no > b.scon_cust_no;
      this.tranForm.tran_part_list.sort((a, b) => {
        if (a.scon_cust_no < b.scon_cust_no) return -1;
        else if (a.scon_cust_no > b.scon_cust_no) return 1;
        else {
          if (a.prod_new_cust_no < b.prod_new_cust_no) return -1;
          else if (a.prod_new_cust_no < b.prod_new_cust_no) return 1;
          else return 0;
        }
      });
    },
    //列总和
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      const temp = ['prod_num', 'prod_ship', 'prod_subtotal', 'prod_box_num', 'prod_tgweight', 'prod_tnweight', 'prod_tvol'];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        let values = data.map(item => Number(item[column.property]));
        if (temp.includes(column.property)) {
          let initValue = new BigNumber(0);
          sums[index] = values
            .reduce((x, item) => {
              return x.plus(item);
            }, initValue)
            .toFixed(4);
        }
      });
      return sums;
    },
    // 导出excel
    exportToExcel(val) {
      htmlToExcel.getExcel(`#multiTable`, '报关明细');
    },
    //复制的数据变绿色
    setRowStyle({ row, rowIndex }) {
      row.index = rowIndex;
      switch (row.is_copy) {
        case 1:
          return { background: 'rgb(199,230,165)' };
      }
    },
    //复制报关明细
    copyProd() {
      if (this.selectionsList.length === 0) {
        return this.$message.warning('请至少选择一条数据');
      }
      let is_copy = this.selectionsList.filter(x => x.is_copy === 1);
      if (is_copy.length > 0) {
        return this.$message.warning('无法使用复制的数据复制！');
      }
      this.$confirm('确认复制选中数据？ 复制后此单出运则联动消失', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          let selectionsList = cloneDeep(this.selectionsList);
          let partIndex = this.tranForm.tran_part_list.length + 1;
          selectionsList.forEach(item => {
            //赋值复制字段为1
            item.is_copy = 1;
            item.tran_part_id = null;
            item.sub_index = partIndex;
            partIndex++;
          });
          this.tranForm.tran_part_list = this.tranForm.tran_part_list.concat(selectionsList);
          //复制后无法联动，改为不联动
          this.tranForm.is_linkage = 1;
          //重置选择框
          this.$refs.PartDetail.clearSelection();
          //key值重置
          this.reductionKey();
        })
        .catch(err => console.error(err));
    },
    // 框显示效果
    textEnlargementShow(val, val1) {
      this.textEnlargementFlag = val;
      this.textEnlargementNum = val1;
      if (val === 0) {
        this.textEnlargementString.str = this.tranForm.tran_part_list[val1].prod_spec;
      }
    },
    // 更改值
    textEnlargementChange(val) {
      if (this.textEnlargementFlag === 0) {
        this.tranForm.tran_part_list[this.textEnlargementNum].prod_spec = val;
      }
    },
    //导入带出运清单确认导入
    importData(rowList) {
      rowList.forEach(item => {
        let scon_part_list = cloneDeep(item.scon_part_list);
        let scon_prod_list = cloneDeep(item.scon_prod_list);
        let scon_insp_list = cloneDeep(item.scon_insp_list);
        let tran_mtrb_list = cloneDeep(item.tran_mtrb_list);
        scon_part_list.forEach(async item => {
          item.sub_index = this.tranForm.tran_part_list.length + 1;
          this.tranForm.tran_part_list.push(item);
          await this.prodSubtotal(this.tranForm.tran_part_list.length - 1, 0);
          await this.calculateVolume(this.tranForm.tran_part_list.length - 1);
        });
        scon_prod_list.forEach(async item => {
          item.sub_index = this.tranForm.tran_prod_list.length + 1;
          this.tranForm.tran_prod_list.push(item);
        });
        scon_insp_list.forEach(async item => {
          item.sub_index = this.tranForm.tran_insp_list.length + 1;
          this.tranForm.tran_insp_list.push(item);
        });
        tran_mtrb_list.forEach(async item => {
          //有复制的数据不计算
          if (this.tranForm.tran_prod_list.filter(s => s.scon_prod_id === item.scon_prod_id).length === 1) {
            //计算开票数量 = 出运数量 / 基数 * 耗量
            let sconProd = this.tranForm.tran_prod_list.find(x => x.scon_prod_id === item.scon_prod_id);
            item.inv_num = new BigNumber((Number(sconProd.prod_ship) / Number(item.prod_mtrb_per)) * Number(item.prod_mtrb_use)).toFixed(2);
            //计算开票金额
            item.inv_money = parseFloat(new BigNumber(Number(item.inv_num)).times(Number(item.prod_mtrb_price)).toFixed(12)).toString();
          }
          this.tranForm.tran_mtrb_list.push(item);
        });
      });
      this.$emit('prodReductionKey'); //计算key
      // this.tranForm.tran_part_list = this.tranForm.tran_part_list.concat();
      this.importTarnVisible = false;
    },
    // 更换海关编码
    changeCustom(row) {
      let trabPartRow = this.tranForm.tran_part_list[this.openIndex];
      trabPartRow.prod_hscode = row.param1;
      trabPartRow.prod_hsname = row.param2;
      trabPartRow.prod_ehsname = row.param3;
      trabPartRow.prod_hsoutrate = row.param5;
      trabPartRow.prod_hsinrate = row.param4;
      this.dialogTableVisible = false;
    },
    handleSelectionChange(selection) {
      this.selectionsList = selection;
    },
    // 删除
    deletePart() {
      if (this.selectionsList.length === 0) {
        return this.$message.warning('至少选择一条信息');
      }
      let temp = [];
      for (let i = 0; i < this.selectionsList.length; i++) {
        for (let j = 0; j < this.tranForm.tran_part_list.length; j++) {
          if (this.selectionsList[i].key === this.tranForm.tran_part_list[j].key) {
            temp.push(j);
          }
        }
      }
      temp = temp.sort((a, b) => {
        return b - a;
      });
      for (let i of temp) {
        this.tranForm.tran_part_list.splice(i, 1);
      }
      //计算序号和key
      this.subIndexCompute();
    },
    subIndexCompute() {
      this.tranForm.tran_part_list.sort((a, b) => {
        if (a.prod_new_cust_no < b.prod_new_cust_no) return -1;
        else if (a.prod_new_cust_no < b.prod_new_cust_no) return 1;
        else return 0;
      });
      for (let i = 0; i < this.tranForm.tran_part_list.length; i++) {
        this.tranForm.tran_part_list[i].key = Number(i);
        this.tranForm.tran_part_list[i].sub_index = Number(i + 1);
      }
    },
    // 计算金额
    prodSubtotal(val, val1) {
      let tranPartRow = this.tranForm.tran_part_list[val];
      let bigNum = new BigNumber(tranPartRow.prod_dusd).times(tranPartRow.prod_ship).plus(tranPartRow.price_gap);
      tranPartRow.prod_subtotal = bigNum.isFinite() ? bigNum.toFixed(2) : '';
      tranPartRow.prod_ship = new BigNumber(tranPartRow.prod_ship).toFixed(2, 0);
      if (val1 === 0) {
        this.prodBoxNum(val);
      }
    },
    // 计算总毛重
    totalGrossWeightChange(val) {
      let tranPartRow = this.tranForm.tran_part_list[val];
      if (tranPartRow.tran_part_main === 0 && tranPartRow.tran_part_claim === 1) {
        let prodBoxNum = this.tranForm.tran_prod_list.find(x => x.scon_prod_id === tranPartRow.scon_prod_id).prod_box_num;
        tranPartRow.prod_tgweight = new BigNumber(tranPartRow.prod_gweight).times(prodBoxNum).toFixed(3);
        return;
      }
      if (tranPartRow.tran_part_main === 1 || tranPartRow.tran_part_claim === 1) {
        tranPartRow.prod_tgweight = new BigNumber(tranPartRow.prod_gweight).times(tranPartRow.prod_box_num).toFixed(3);
      }
    },
    // 计算总净重
    totalNetWeight(val) {
      let tranPartRow = this.tranForm.tran_part_list[val];
      if (tranPartRow.tran_part_main === 0 && tranPartRow.tran_part_claim === 1) {
        let prodBoxNum = this.tranForm.tran_prod_list.find(x => x.scon_prod_id === tranPartRow.scon_prod_id).prod_box_num;
        tranPartRow.prod_tnweight = new BigNumber(tranPartRow.prod_nweight).times(prodBoxNum).toFixed(3);
        return;
      }
      if (tranPartRow.tran_part_main === 1 || tranPartRow.tran_part_claim === 1) {
        tranPartRow.prod_tnweight = new BigNumber(tranPartRow.prod_nweight).times(tranPartRow.prod_box_num).toFixed(3);
      }
    },
    // 总体积
    totalVolumeChange(val) {
      let trabPartRow = this.tranForm.tran_part_list[val];
      trabPartRow.prod_tvol = this.helper.calcNum3((Number(trabPartRow.prod_vol) * 100000 * Number(trabPartRow.prod_box_num)) / 100000);
    },
    // 计算体积
    calculateVolume(val) {
      let num = new BigNumber(1);
      let tranPartRow = this.tranForm.tran_part_list[val];
      num = num
        .times(tranPartRow.prod_boxl ? tranPartRow.prod_boxl : 0)
        .times(tranPartRow.prod_boxw ? tranPartRow.prod_boxw : 0)
        .times(tranPartRow.prod_boxh ? tranPartRow.prod_boxh : 0)
        .div(1000000)
        .toNumber();
      tranPartRow.prod_vol = this.helper.calcNum3(num);
      this.totalVolumeChange(val);
    },
    message: debounce(function () {
      this.$message.warning('箱数未能整除，已向上取整');
    }, 1000),
    // 计算箱数
    prodBoxNum(val) {
      let tranPartRow = this.tranForm.tran_part_list[val];
      if (tranPartRow.prod_qpc === '' || tranPartRow.prod_qpc === '0') {
        tranPartRow.prod_qpc = 0;
        tranPartRow.prod_box_num = 0;
        return;
      }
      if (Number(tranPartRow.prod_ship) % Number(tranPartRow.prod_qpc) !== 0) {
        this.message();
      }
      //非主产品时不计算箱数
      if (tranPartRow.tran_part_main === 0) {
        tranPartRow.prod_box_num = 0;
      } else {
        tranPartRow.prod_box_num = Math.ceil(Number(tranPartRow.prod_ship) / Number(tranPartRow.prod_qpc));
      }
      this.totalGrossWeightChange(val);
      this.totalNetWeight(val);
      if (tranPartRow.tran_part_claim === 1) {
        this.calculateVolume(val);
      }
    },
    // 更改是否主产品和报关
    tranPartMainChange(val) {
      //非报关
      let tranPartRow = this.tranForm.tran_part_list[val];
      if (tranPartRow.tran_part_claim === 0) {
        tranPartRow.prod_boxl = '0.000';
        tranPartRow.prod_boxw = '0.00';
        tranPartRow.prod_boxh = '0.00';
        tranPartRow.prod_vol = '0.000';
        tranPartRow.prod_tvol = '0.000';
        // tranPartRow.prod_gweight = '0.000';
        tranPartRow.prod_tgweight = '0.000';
        // tranPartRow.prod_nweight = '0.000';
        tranPartRow.prod_tnweight = '0.000';
      } else {
        this.totalGrossWeightChange(val);
        this.totalNetWeight(val);
      }
      // //非主产品
      if (tranPartRow.tran_part_main === 0) {
        tranPartRow.tran_part_ctne = '0';
        tranPartRow.tran_part_ctns = '0';
        tranPartRow.prod_box_num = '0';
        tranPartRow.prod_boxl = '0.000';
        tranPartRow.prod_boxw = '0.00';
        tranPartRow.prod_boxh = '0.00';
        tranPartRow.prod_vol = '0.000';
        tranPartRow.prod_tvol = '0.000';
      } else {
        tranPartRow.prod_box_num = Math.ceil(Number(tranPartRow.prod_ship) / Number(tranPartRow.prod_qpc));
      }
    },
    // 上移
    moveUp() {
      if (this.selectionsList.length < 1) {
        this.$message.warning('请选择一条数据');
      } else if (this.selectionsList.length > 1) {
        this.$message.warning('只能选择一条数据');
      } else {
        let temp = JSON.parse(JSON.stringify(this.selectionsList[0]));
        if (temp.key === 0) {
          this.$message.warning('已经是第一条');
        } else {
          let ind = temp.key;
          this.tranForm.tran_part_list.splice(ind, 1);
          this.tranForm.tran_part_list.splice(ind - 1, 0, temp);
        }
      }
      this.reductionKey();
    },
    // 下移
    moveDown() {
      if (this.selectionsList.length < 1) {
        return this.$message.warning('请选择一条数据');
      }
      if (this.selectionsList.length > 1) {
        return this.$message.warning('只能选择一条数据');
      }
      let temp = JSON.parse(JSON.stringify(this.selectionsList[0]));
      if (temp.key === this.tranForm.tran_part_list.length - 1) {
        this.$message.warning('已经是最后一条');
      } else {
        let ind = temp.key;
        this.tranForm.tran_part_list.splice(ind, 1);
        this.tranForm.tran_part_list.splice(ind + 1, 0, temp);
      }
      this.reductionKey();
    },
    //需要商检按钮
    prodToPart() {
      if (this.selectionsList.length === 0) {
        return this.$message.warning('至少选择一条信息');
      }
      for (let i = 0; i < this.selectionsList.length; i++) {
        if (!this.selectionsList[i].prod_ename) {
          return this.$message.warning('英文名称不能为空！');
        }
      }
      //英文规格赋值
      this.selectionsList.forEach(item => {
        this.tranForm.tran_prod_list.forEach(item2 => {
          if (item.tran_prod_id === item2.tran_prod_id) {
            item.prod_espec = item2.prod_espec;
          }
        });
      });
      //添加到商检
      this.tranForm.tran_insp_list = this.tranForm.tran_insp_list.concat(JSON.parse(JSON.stringify(this.selectionsList)));
      //重新计算商检key值
      for (let i = 0; i < this.tranForm.tran_insp_list.length; i++) {
        this.tranForm.tran_insp_list[i].key = Number(i);
      }
      this.$message.success('需要商检成功！');
      EventBus.$emit('updateInsp', this.tranForm.tran_insp_list);
    },
    // key值重置
    reductionKey() {
      for (let i = 0; i < this.tranForm.tran_part_list.length; i++) {
        this.tranForm.tran_part_list[i].key = Number(i);
      }
      this.$refs.PartDetail.clearSelection();
    },
    // 计算报关单价
    calculatePrice() {
      this.tranForm.tran_part_list.forEach(item => {
        if (item.tran_part_claim === 0) {
          item.prod_dusd = 0;
          item.prod_subtotal = 0;
        } else {
          item.prod_dusd = new BigNumber(item.prod_price).div(this.tranForm.tran_usdrate).toFixed(2);
          item.prod_subtotal = new BigNumber(item.prod_ship).times(item.prod_dusd).toFixed(2);
        }
      });
    },
    // 初始化箱封号
    initialization() {
      let cumulativeBoxNum = 1;
      for (let i = 0; i < this.tranForm.tran_part_list.length; i++) {
        //初始化箱封号  根据固定序号开始计算
        let tranPartRow = this.tranForm.tran_part_list.find(item => item.sub_index === i + 1);
        const isEligible = tranPartRow.tran_part_claim === 1 && tranPartRow.tran_part_main === 1 && tranPartRow.prod_box_num !== 0;
        if (isEligible) {
          const boxNum = Number(tranPartRow.prod_box_num);
          tranPartRow.tran_part_ctns = cumulativeBoxNum;
          tranPartRow.tran_part_ctne = cumulativeBoxNum + boxNum - 1;
          cumulativeBoxNum += boxNum;
        } else {
          tranPartRow.tran_part_ctns = 0;
          tranPartRow.tran_part_ctne = 0;
        }
      }
    },
    otherSortChange(val) {
      this.tranForm.tran_part_list = val;
    },
    async copySelectedColumnsToClipboard() {
      const columnsToCopy = [
        'prod_hsname', // 海关编码
        'prod_new_cust_no' // 新客户货号
      ];
      // 构建表头
      const headers = columnsToCopy
        .map(prop => {
          return this.baoguaTableProperties.find(item => item.prop === prop)?.label || prop;
        })
        .join('\t');
      let dataAsText = ''; // 首先添加表头，末尾添加换行符
      // 遍历每一行数据
      for (const row of this.tranForm.tran_part_list) {
        let rowData = '';
        for (const col of columnsToCopy) {
          let value = row[col];
          rowData += `${value}\t`; // 每个字段后添加制表符分隔
        }
        // 每行数据末尾移除最后一个多余的制表符，并添加换行符
        dataAsText += rowData.slice(0, -1) + '\n';
      }
      try {
        await navigator.clipboard.writeText(dataAsText.trim());
        this.$message.success('已复制到剪贴板');
      } catch (err) {
        this.$message.error('复制到剪贴板失败，请重试');
        console.error('复制到剪贴板失败:', err);
      }
    },
    spinListRefresh() {
      this.$refs.spinList.buttonRefresh();
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .elx-table.elx-editable.size--medium .elx-body--column,
.elx-table.size--medium .elx-body--column.col--ellipsis,
.elx-table.size--medium .elx-footer--column.col--ellipsis,
.elx-table.size--medium .elx-header--column.col--ellipsis {
  height: 34px;
}

::v-deep .showRow {
  display: none;
}

::v-deep .fixed-cell {
  //  color:red !important
  background-color: #ccc !important;
}

::v-deep .el-form-item.is-required .el-form-item__label:before {
  content: '';
  display: none;
}

::v-deep .vd_sortColor {
  color: rgb(0, 196, 250);
}

.vd_dirc {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
</style>
