import store from '@/store';
import helper from '@assets/js/helper';
import { getDateNoTime } from '@assets/js/dateUtils';
import { BigNumber } from 'bignumber.js';
import { keep12Decimal, keep4Decimal, keepNumber } from '@assets/js/regExUtil';

export const listTableProperties = [
  { prop: 'selection', type: 'selection', labelWidth: '48px' },
  { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  },
  { prop: 'tran_no', label: '出运发票号', itemType: 'input', formatter: val => helper.transverseTranNo(val), labelWidth: '150px' },
  {
    prop: 'tran_ivdate',
    label: '发票日期',
    itemType: 'date',
    formatter: val => getDateNoTime(val, true)
  },
  { prop: 'cust_abbr', label: '客户简称', itemType: 'input' },
  { prop: 'cust_cabbr', label: '最终客户', itemType: 'input', input: false },
  { prop: 'scon_nos', label: '销售合同号', itemType: 'input', overflowTooltip: true },
  { prop: 'scon_cust_nos', label: '客户订单号', itemType: 'input', overflowTooltip: true },
  { prop: 'prod_cust_no', label: '客户货号', itemType: 'input', sortable: false, overflowTooltip: true },
  { prop: 'prod_nos', label: '我司货号', itemType: 'input', sortable: false, overflowTooltip: true },
  { prop: 'tran_num', label: '总出运量', itemType: 'input', sortable: false, input: false },
  { prop: 'supp_abbrs', label: '主供应商', itemType: 'input', sortable: false },
  {
    prop: 'tran_bldate',
    label: '放单日期',
    itemType: 'date',
    formatter: val => getDateNoTime(val, true)
  },
  {
    prop: 'tran_dpdate',
    label: '离港日',
    itemType: 'date',
    labelWidth: '120px',
    formatter: val => getDateNoTime(val, true)
  },
  {
    prop: 'tran_eta',
    label: '到港日',
    itemType: 'date',
    labelWidth: '120px',
    formatter: val => getDateNoTime(val, true)
  },
  { prop: 'tran_flight', label: '航名/航班信息', itemType: 'input', sortable: false, input: false },
  { prop: 'cust_paylaw', label: '价格条款', itemType: 'input', sortable: false, input: false },
  { prop: 'cust_payway', label: '付款方式', itemType: 'input', sortable: false, input: false },
  { prop: 'tran_fetotal', label: '费用金额', itemType: 'input', sortable: false, input: false },
  { prop: 'tran_prtotal', label: '出运金额', itemType: 'input', sortable: false, input: false },
  { prop: 'tran_usdrate', label: '汇率', itemType: 'input', labelWidth: '100px', sortable: false, input: false },
  { prop: 'cptt_aname', label: '公司抬头', itemType: 'input', overflowTooltip: true },
  { prop: 'cust_edcountry', label: '目的国', itemType: 'input', labelWidth: '120px', sortable: false, input: false },
  { prop: 'cust_dport', label: '目的港', itemType: 'input', labelWidth: '120px' },
  {
    prop: 'tran_cldate',
    label: '报关日期',
    itemType: 'date',
    formatter: val => getDateNoTime(val, true)
  },
  { prop: 'stff_name', label: '录入人', itemType: 'input', labelWidth: '120px' },
  { prop: 'create_time', label: '录入时间', itemType: 'date', formatter: val => getDateNoTime(val, true) },
  { prop: 'tran_no_remark', label: '发票号备注', itemType: 'input', input: false }
];
export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '材料采购合同号', prop: 'modr_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '材料名称', prop: 'mtrb_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '包材品类', prop: 'mtrb_pctype', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '材料规格', prop: 'mtrb_spec', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '耗量', prop: 'prod_mtrb_use', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '基数', prop: 'prod_mtrb_per', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '单耗比', prop: 'prod_mtrb_peruse', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '订单数量', prop: 'mtrb_num', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '开票数量',
    prop: 'inv_num',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 20,
      input: (val, row) => (row.inv_num = keepNumber(val))
    }
  },
  { label: '已开票数量', prop: 'ready_inv_num', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '采购单价',
    prop: 'prod_mtrb_price',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: true,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 20,
      input: (val, row) => (row.prod_mtrb_price = keep12Decimal(val))
    }
  },
  { label: '开票金额', prop: 'inv_money', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '备注',
    prop: 'tran_modr_remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '140',
    subItem: { required: false, disabled: false, type: 'input' }
  }
];
export const modrDialogTableProperties = [
  { label: '销售合同号', prop: 'scon_no', itemType: 'select', options: [], input: true, sortable: false, widthAuto: true },
  { label: '材料采购合同号', prop: 'modr_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '材料名称', prop: 'mtrb_name', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '包材品类', prop: 'mtrb_pctype', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '材料规格', prop: 'modr_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '订单数量', prop: 'mtrb_num', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
export const chuyunTableProperties = [
  {
    label: '序号',
    prop: 'sub_index',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: '70',
    fixed: 'left'
  },
  {
    label: '起始箱号',
    prop: 'tran_prod_ctns',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '100',
    subItem: {
      disabled: true
    }
  },
  {
    label: '结束箱号',
    prop: 'tran_prod_ctne',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '100',
    subItem: {
      disabled: true
    }
  },
  {
    label: '销售合同号',
    prop: 'scon_no',
    itemType: 'input',
    input: false,
    sortable: true,
    remoteSort: false,
    labelWidth: '180'
  },
  {
    label: '采购合同号',
    prop: 'podr_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  },
  {
    label: '主要供应商',
    prop: 'supp_abbr',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  },
  {
    label: '原始订单号',
    prop: 'scon_cust_ono',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: false,
      disabled: true
    }
  },
  {
    label: '新订单号',
    prop: 'scon_cust_no',
    itemType: 'input',
    input: false,
    sortable: true,
    remoteSort: false,
    labelWidth: '180',
    subItem: {
      copyAndPaste: true,
      required: true,
      maxLength: 20
    }
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: true
    }
  },
  {
    label: '新客户货号',
    prop: 'prod_new_cust_no',
    itemType: 'input',
    input: false,
    sortable: true,
    remoteSort: false,
    labelWidth: '180',
    subItem: {
      disabled: false
    }
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: true
    }
  },
  {
    label: '英文名称',
    prop: 'prod_ename',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      type: 'input'
    }
  },
  {
    label: '英文规格',
    prop: 'prod_espec',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      type: 'popoverInput'
    }
  },
  {
    label: '订单数量',
    prop: 'prod_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: true
    }
  },
  {
    label: '出运数量',
    prop: 'prod_ship',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: false
    }
  },
  {
    label: '数量单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      copyAndPaste: true,
      maxLength: 10
    }
  },
  {
    label: '单价(美元)',
    prop: 'prod_usd',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: true
    }
  },
  {
    label: '金额(美元)',
    prop: 'prod_subtotal',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      maxLength: 9,
      disabled: true
    }
  },
  {
    label: '装箱数',
    prop: 'prod_qpc',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      copyAndPaste: true,
      copyChange: true,
      type: 'input'
    }
  },
  {
    label: '箱数',
    prop: 'prod_box_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      maxLength: 10,
      disabled: false
    }
  },
  {
    label: '包装单位',
    prop: 'prod_box_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      copyAndPaste: true,
      disabled: false
    }
  },
  {
    label: '每箱毛重(KGS)',
    prop: 'prod_gweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      type: 'input'
    }
  },
  {
    label: '总毛重(KGS)',
    prop: 'prod_tgweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      maxLength: 7
    }
  },
  {
    label: '每箱净重(KGS)',
    prop: 'prod_nweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,

      maxLength: 11
    }
  },
  {
    label: '总净重(KGS)',
    prop: 'prod_tnweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,

      maxLength: 7
    }
  },
  {
    label: '长度(CM)',
    prop: 'prod_boxl',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,

      maxLength: 11
    }
  },
  {
    label: '宽度(CM)',
    prop: 'prod_boxw',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,

      maxLength: 7
    }
  },
  {
    label: '高度(CM)',
    prop: 'prod_boxh',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,

      maxLength: 7
    }
  },
  {
    label: '每箱体积(CBM)',
    prop: 'prod_vol',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,

      maxLength: 7
    }
  },
  {
    label: '总体积(CBM)',
    prop: 'prod_tvol',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,

      maxLength: 9
    }
  },
  {
    label: '备注',
    prop: 'tran_prod_remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      maxLength: 13
    }
  },
  {
    label: '验货费',
    prop: 'tran_prod_fee',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      type: 'input',
      disabled: false
    }
  }
];
export const baoguaTableProperties = [
  {
    label: '序号',
    prop: 'sub_index',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: '70',
    fixed: 'left'
  },
  {
    label: '是否主产品',
    prop: 'tran_part_main',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: '150',
    formatter: val => (val === 0 ? '否' : '是'),
    subItem: {
      required: true,
      disabled: false
    }
  },
  {
    label: '是否报关',
    prop: 'tran_part_claim',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: '150',
    formatter: val => (val === 0 ? '否' : '是'),
    subItem: {
      required: true,
      disabled: false
    }
  },
  {
    label: '起始箱号',
    prop: 'tran_part_ctns',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: true
    }
  },
  {
    label: '结束箱号',
    prop: 'tran_part_ctne',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: true
    }
  },
  {
    label: '采购合同号',
    prop: 'podr_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  },
  {
    label: '销售合同号',
    prop: 'scon_no',
    itemType: 'input',
    input: false,
    sortable: true,
    remoteSort: false,
    labelWidth: '180'
  },
  {
    label: '供应商简称',
    prop: 'supp_abbr',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: '180',
    subItem: {
      disabled: true
    }
  },
  {
    label: '原始订单号',
    prop: 'scon_cust_ono',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: true
    }
  },
  {
    label: '新订单号',
    prop: 'scon_cust_no',
    itemType: 'input',
    input: false,
    sortable: true,
    remoteSort: false,
    labelWidth: '180',
    subItem: {
      copyAndPaste: true,
      required: true,
      disabled: false
    }
  },
  {
    label: '原始客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: true
    }
  },
  {
    label: '新客户货号',
    prop: 'prod_new_cust_no',
    itemType: 'input',
    input: false,
    sortable: true,
    remoteSort: false,
    labelWidth: '180',
    subItem: {
      required: true
    }
  },
  {
    label: '所属品牌',
    prop: 'cust_brand',
    itemType: 'input',
    input: false,
    sortable: false,
    remoteSort: false,
    labelWidth: '180',
    visible: true,
    subItem: {
      copyAndPaste: true,
      required: true,
      type: 'select',
      options: []
    }
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: true
    }
  },
  {
    label: '中文名称',
    prop: 'prod_name',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: true
    }
  },
  {
    label: '部件名',
    prop: 'prod_partno',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: true
    }
  },
  {
    label: '英文名称',
    prop: 'prod_ename',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: false
    }
  },
  {
    label: '中文规格',
    prop: 'prod_spec',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: true,
      type: 'popoverInput'
    }
  },
  {
    label: '海关中文品名',
    prop: 'prod_hsname',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: false
    }
  },
  {
    label: '海关英文品名',
    prop: 'prod_ehsname',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: false
    }
  },
  {
    label: '海关编码',
    prop: 'prod_hscode',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '250',
    subItem: {
      copyAndPaste: true,
      required: true
    }
  },
  {
    label: '订单数量',
    prop: 'prod_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: true
    }
  },
  {
    label: '出运数量',
    prop: 'prod_ship',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true
    }
  },
  {
    label: '部件单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      copyAndPaste: true,
      required: true
    }
  },
  {
    label: '部件销售价(美元)',
    prop: 'prod_usd',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true
    }
  },
  {
    label: '部件折扣价(美元)',
    prop: 'prod_dusd',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: false
    }
  },
  {
    label: '金额(美元)',
    prop: 'prod_subtotal',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: false
    }
  },
  {
    label: '销售价差(美元)',
    prop: 'price_gap',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: false
    }
  },
  {
    label: '成品销售价',
    prop: 'prod_saprice',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: false
    }
  },
  {
    label: '采购单价',
    prop: 'prod_price',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      maxLength: 10
    }
  },
  {
    label: '采购金额',
    prop: 'scon_money',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    needOtherColumn: true,
    formatter: row => {
      row.scon_money = new BigNumber(row.prod_price).times(row.prod_ship).toFixed(3);
      return row.scon_money;
    }
  },
  {
    label: '装箱数',
    prop: 'prod_qpc',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      copyAndPaste: true,
      copyChange: true,
      required: true
    }
  },
  {
    label: '箱数',
    prop: 'prod_box_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: false
    }
  },
  {
    label: '包装单位',
    prop: 'prod_box_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      copyAndPaste: true,
      required: true,
      disabled: false
    }
  },
  {
    label: '每箱毛重(KGS)',
    prop: 'prod_gweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: false
    }
  },
  {
    label: '总毛重(KGS)',
    prop: 'prod_tgweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: false
    }
  },
  {
    label: '每箱净重(KGS)',
    prop: 'prod_nweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true
    }
  },
  {
    label: '总净重(KGS)',
    prop: 'prod_tnweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: false
    }
  },
  {
    label: '长*宽*高(CM)',
    prop: 'prod_boxl',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '300',
    subItem: {
      required: true,
      disabled: false
    },
    needOtherColumn: true,
    formatter: row => row.prod_boxl + '*' + row.prod_boxw + '*' + row.prod_boxh
  },
  {
    label: '每箱体积(CBM)',
    prop: 'prod_vol',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: false
    }
  },
  {
    label: '总体积(CBM)',
    prop: 'prod_tvol',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: true
    }
  },
  {
    label: '退税率',
    prop: 'prod_hsoutrate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: true
    }
  },
  {
    label: '增值税率',
    prop: 'prod_hsinrate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: true
    }
  },
  {
    label: '验货费',
    prop: 'tran_part_fee',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: true
    }
  }
];
export const shangjianTableProperties = [
  {
    label: '序号',
    prop: 'sub_index',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: '70',
    fixed: 'left'
  },
  {
    label: '起始箱号',
    prop: 'tran_insp_ctns',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: true
    }
  },
  {
    label: '结束箱号',
    prop: 'tran_insp_ctne',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      disabled: true
    }
  },
  {
    label: '销售合同号',
    prop: 'scon_no',
    itemType: 'input',
    input: false,
    sortable: true,
    remoteSort: false,
    labelWidth: '180'
  },
  {
    label: '采购合同号',
    prop: 'podr_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  },
  {
    label: '供应商简称',
    prop: 'supp_abbr',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180'
  },
  {
    label: '新订单号',
    prop: 'scon_cust_no',
    itemType: 'input',
    input: false,
    sortable: true,
    remoteSort: false,
    labelWidth: '180',
    subItem: {
      copyAndPaste: true,
      required: true,
      type: 'input'
    }
  },
  {
    label: '原始订单号',
    prop: 'scon_cust_ono',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: false,
      disabled: true
    }
  },
  {
    label: '客户货号',
    prop: 'prod_cust_no',
    itemType: 'input',
    input: false,
    sortable: true,
    labelWidth: '180',
    subItem: {
      required: true,
      maxLength: 20,
      disabled: true
    }
  },
  {
    label: '新客户货号',
    prop: 'prod_new_cust_no',
    itemType: 'input',
    input: false,
    sortable: true,
    remoteSort: false,
    labelWidth: '180',
    subItem: {
      maxLength: 20,
      type: 'input'
    }
  },
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      type: 'input'
    }
  },
  {
    label: '英文名称',
    prop: 'prod_ename',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: true
    }
  },
  {
    label: '英文规格',
    prop: 'prod_espec',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      type: 'popoverInput'
    }
  },
  {
    label: '订单数量',
    prop: 'prod_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      disabled: true
    }
  },
  {
    label: '出运数量',
    prop: 'prod_ship',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      maxLength: 10,
      type: 'input'
    }
  },
  {
    label: '装箱数',
    prop: 'prod_qpc',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      copyAndPaste: true,
      copyChange: true,
      required: true,
      type: 'input',
      maxLength: 10
    }
  },
  {
    label: '箱数',
    prop: 'prod_box_num',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      maxLength: 10,
      disabled: false
    }
  },
  {
    label: '数量单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      copyAndPaste: true,
      required: true
    }
  },
  {
    label: '单价(美元)',
    prop: 'prod_usd',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      type: 'input'
    }
  },
  {
    label: '部件折扣价',
    prop: 'prod_dusd',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      type: 'input',
      required: false,
      disabled: false,
      maxLength: 9,
      input: (val, row) => (row.prod_dusd = keep4Decimal(val))
    }
  },
  {
    label: '金额(美元)',
    prop: 'prod_subtotal',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      maxLength: 9
    }
  },
  {
    label: '包装单位',
    prop: 'prod_box_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      copyAndPaste: true,
      required: true
    }
  },
  {
    label: '每箱毛重(KGS)',
    prop: 'prod_gweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      type: 'input'
    }
  },
  {
    label: '总毛重(KGS)',
    prop: 'prod_tgweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      maxLength: 8
    }
  },
  {
    label: '每箱净重(KGS)',
    prop: 'prod_nweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      maxLength: 12
    }
  },
  {
    label: '总净重(KGS)',
    prop: 'prod_tnweight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      maxLength: 8
    }
  },
  {
    label: '长度(CM)',
    prop: 'prod_boxl',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      maxLength: 12
    }
  },
  {
    label: '宽度(CM)',
    prop: 'prod_boxw',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      maxLength: 7
    }
  },
  {
    label: '高度(CM)',
    prop: 'prod_boxh',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      maxLength: 7
    }
  },
  {
    label: '每箱体积(CBM)',
    prop: 'prod_vol',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      maxLength: 7
    }
  },
  {
    label: '总体积(CBM)',
    prop: 'prod_tvol',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      required: true,
      maxLength: 9
    }
  },
  {
    label: '备注',
    prop: 'tran_insp_remark',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '180',
    subItem: {
      maxLength: 13
    }
  }
];
export const tuoDialogTableProperties = [
  store.state.selection,
  store.state.index,
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '采购合同号', prop: 'podr_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '供应商简称', prop: 'supp_abbr', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '出运数量', prop: 'prod_ship', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '立方数', prop: 'prod_tvol', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '剩余数量', prop: 'residue_num', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
export const syncDialogTableProperties = [
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false },
  { label: '销售合同号', prop: 'scon_nos', itemType: 'input', input: true, sortable: false },
  { label: '客户订单号', prop: 'scon_cust_nos', itemType: 'input', input: true, sortable: false },
  { label: '公司抬头', prop: 'cptt_aname', itemType: 'input', input: false, sortable: false },
  { label: '出运金额', prop: 'tran_prtotal', itemType: 'input', input: false, sortable: false }
];
