<template>
  <el-row>
    <el-form ref="form" :model="formModel" :label-width="lableWidth">
      <el-row>
        <el-row class="btn-group">
          <slot :name="`header-btn-group-${name}`"></slot>
        </el-row>
        <div class="row-item" v-for="(formItem, index) in formModel.formList" :key="index" v-show="formItem.show">
          <el-col :md="split" class="vg_mb_8">
            <el-form-item
              :show-message="!formItem.hiddenMsg"
              :rules="formItem.disabled ? [] : formItem.rules"
              :label="formItem.label"
              :prop="'formList.' + index + '.model'"
              :style="formItemStyle"
            >
              <el-input
                v-bind="$attrs"
                v-on="$listeners"
                v-if="formItem.itemType === 'input'"
                :type="formItem.type"
                :maxlength="formItem.maxlength"
                :minlength="formItem.minlength"
                v-model="formItem.model"
                :placeholader="formItem.placeholder"
                :disabled="formItem.disabled"
                :auto-complete="formItem.autoComplete"
                :max="formItem.max"
                :min="formItem.min"
                :step="formItem.step"
                :name="formItem.name"
                :resize="formItem.resize"
                :autofocus="formItem.autofocus"
                :form="formItem.form"
                :rows="formItem.rows"
                :autosize="formItem.autosize"
                :size="formItem.size"
                :readonly="formItem.readonly"
                @blur="typeof formItem.blur === 'function' ? formItem.blur($event) : () => {}"
                @focus="typeof formItem.focus === 'function' ? formItem.focus($event) : () => {}"
                @change="typeof formItem.change === 'function' ? formItem.change($event) : () => {}"
              >
                <el-link type="primary" slot="append" v-if="formItem.inputAppend" @click="formItem.appendClick">{{
                  formItem.inputAppend
                }}</el-link>
              </el-input>
              <el-autocomplete
                v-if="formItem.itemType === 'autocomplete'"
                :placeholader="formItem.placeholder"
                :disabled="formItem.disabled"
                v-model.trim="formItem.model"
                :custom-item="formItem.customItem"
                :fetch-suggestions="formItem.fetchSuggestions"
                :popper-class="formItem.popperClass"
                :trigger-on-focus="formItem.triggerOnFocus"
                @select="typeof formItem.select === 'function' ? formItem.select($event) : () => {}"
              ></el-autocomplete>
              <el-date-picker
                v-if="formItem.itemType === 'date'"
                v-model="formItem.model"
                :disabled="formItem.disabled"
                type="datetime"
                :placeholder="formItem.label"
                :format="formItem.formatTime"
                @blur="typeof formItem.blur === 'function' ? formItem.blur($event) : () => {}"
              ></el-date-picker>
              <el-select
                :multiple="formItem.multiple"
                :clearable="formItem.clearable"
                :size="formItem.size"
                :multiple-limit="formItem.multipleLimit"
                :name="formItem.name"
                :placeholder="formItem.placeholder"
                :allow-create="formItem.allowCreate"
                :disabled="formItem.disabled"
                :filter-method="formItem.filterMethod"
                :filterable="formItem.filterable"
                :loading-text="formItem.loadingText"
                :no-data-text="formItem.noDataText"
                :popper-class="formItem.popperClass"
                :no-match-text="formItem.noMatchText"
                :loading="formItem.loading"
                :remote="formItem.remote"
                :remote-method="formItem.remoteMethod"
                v-model="formItem.model"
                v-if="formItem.itemType === 'select'"
                @change="typeof formItem.change === 'function' ? formItem.change($event) : () => {}"
                @visible-change="typeof formItem.visibleChange === 'function' ? formItem.visibleChange($event) : () => {}"
                @remove-tag="typeof formItem.removeTag === 'function' ? formItem.removeTag($event) : () => {}"
              >
                <el-option
                  v-for="item in formItem.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                  :style="selectOptionStyle"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </div>
      </el-row>
      <el-row class="btn-group" v-if="needBtn">
        <!--<el-button @click="clickCancel">重置</el-button>-->
        <el-button type="primary" @click="clickSubmit">保存</el-button>
      </el-row>
    </el-form>
  </el-row>
</template>
<script>
let vm = {};
//  import * as Model from './model'
export default {
  name: 'CcmsForm',
  data() {
    this.turnData(this.formList);
    return {
      vm: vm,
      refForm: {},
      formModel: {
        formList: this.formList
      }
    };
  },
  props: {
    formList: Array,
    needBtn: {
      type: Boolean,
      default: true
    },
    name: String,
    split: Number,
    formItemStyle: String,
    lableWidth: String,
    selectOptionStyle: String
  },
  watch: {
    formList(val) {
      this.formModel.formList = val;
      this.turnData(this.formModel.formList);
    }
  },
  mounted() {
    this.setAuth();
    this.refForm = this.$refs['form'];
  },
  methods: {
    //    通过一个prop字段设置另一个属性 prop tarPro要设置的目标属性值
    setProperByPro(prop, tarPro) {
      if (typeof tarPro !== 'object') {
        return;
      }
      for (let i = 0; i < this.formList.length; i++) {
        if (this.formList[i].prop === prop) {
          for (let key in tarPro) {
            this.formList[i][key] = tarPro[key];
          }
        }
      }
    },
    // 通过prop 获取表单字段对象
    getPropObjByPro(prop) {
      for (let i = 0; i < this.formList.length; i++) {
        if (this.formList[i].prop === prop) {
          return this.formList[i];
        }
      }
    },
    // 设置权限控制
    setAuth() {
      this.formList.map(item => {
        if (typeof item.dataAuth === 'function') {
          item.dataAuth();
        }
      });
    },
    // 点击确认按钮
    clickSubmit() {
      this.$refs['form'].validate(val => {
        if (val) {
          this.$emit('clickSubmit', this.getFormModel());
        }
      });
    },
    // 获取form model
    getFormModel() {
      let workObj = {};
      for (let i = 0; i < this.$refs['form'].model.formList.length; i++) {
        let obj = this.$refs['form'].model.formList[i];
        workObj[obj.prop] = obj.model;
      }
      return workObj;
    },
    // 获取form model //必要index字段
    getSpecialFormModel() {
      let formList = this.$refs['form'].model.formList;
      let map = formList.map(({ index }) => index);
      let max = Math.max(...map);
      let arr = [];
      if (max === 0) {
        let workObj = {};
        formList.forEach(item => (workObj[item.prop] = item.itemType === 'date' ? new Date(item.model).getTime() : item.model));
        arr.push(workObj);
      } else {
        for (let i = 0; i <= max; i++) {
          let workObj = {};
          let filter = formList.filter(({ index }) => index === i);
          filter.forEach(item => {
            workObj[item.prop] = item.itemType === 'date' ? new Date(item.model).getTime() : item.model;
          });
          arr.push(workObj);
        }
      }
      console.log(arr);
      return { list: arr };
    },
    // 重置表单
    reset() {
      this.$refs.form.resetFields();
    },
    // 点击取消按钮
    clickCancel() {
      this.$emit('clickCancel', this.$refs['form']);
    },
    // 当一个表单域从enable 变为disabled时 enabled错误信息不显示
    hiddenMsg() {
      for (let i = 0; i < this.formList.length; i++) {
        if (this.formList[i].disabled) {
          this.formList[i].hiddenMsg = true;
        } else {
          this.formList[i].hiddenMsg = false;
        }
      }
    },
    selectChange(fun, val) {
      if (typeof fun === 'function') {
        fun(val);
      }
      this.setAuth();
      this.hiddenMsg();
    },
    turnData(formList) {
      for (let i = 0; i < formList.length; i++) {
        let item = formList[i];
        if (item.show === undefined) {
          item.show = true;
        }
        if (item.disabled === undefined) {
          item.disabled = false;
        }
      }
    }
  }
};
</script>
