<template>
  <div class="vg_wrapper">
    <div class="vd_button_group">
      <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新 </el-button>
      <el-button size="small" type="info" @click="PrintTotalFor34">业务34部打印下载相关单据</el-button>
    </div>
    <search-table
      ref="multiTable"
      :data="tableData"
      :tableRowKey="`tran_part_id`"
      :columns="tableProperties"
      :needPagination="false"
    ></search-table>
    <el-dialog title="业务34部打印下载相关单据" :visible.sync="printDialogFlagFor34" width="500px" append-to-body>
      <el-form label-width="auto">
        <el-row>
          <el-col :md="24">
            <el-form-item label="报关发票:">
              <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintPart34">打印</el-button>
              <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportPart34">下载PDF </el-button>
              <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportPartExcel34"> 下载excel </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="报关装箱单:">
          <el-button size="small" icon="el-icon-printer" type="info" @click="clickPrintBox34">打印</el-button>
          <el-button icon="el-icon-bottom" size="small" type="primary" @click="clickExportBox34">下载PDF </el-button>
          <el-button icon="el-icon-bottom" size="small" type="success" @click="clickExportBoxExcel34">下载excel </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { myMixin } from '@/assets/js/mixin/tabMixin.js';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { tranAPI } from '@api/modules/tran';
import helper from '@assets/js/helper';

export default {
  name: 'PartGroup',
  mixins: [myMixin],
  components: {
    SearchTable
  },
  props: {
    tranForm: {
      type: Object,
      required: false
    }
  },
  created() {},
  data() {
    return {
      printDialogFlagFor34: false,
      tableData: [],
      tableProperties: [
        { prop: 'podr_no', label: '采购合同号', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'scon_no', label: '销售合同号', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_new_cust_no', label: '新客户货号', input: false, itemType: 'input', labelWidth: '150px' },
        {
          prop: 'tran_part_claim',
          label: '是否报关',
          input: false,
          itemType: 'input',
          formatter: val => {
            if (val === 1) {
              return '是';
            } else {
              return '否';
            }
          },
          labelWidth: '150px'
        },
        { prop: 'prod_hscode', label: '海关编码', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_hsname', label: '海关中文品名', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'supp_abbr', label: '供应商简称', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_ship', label: '出运数量', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_unit', label: '部件单位', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_qpc', label: '装箱数', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_box_num', label: '箱数', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_vol', label: '每箱体积', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_nweight', label: '每箱净重', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_gweight', label: '每箱毛重', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_tvol', label: '总体积', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_tnweight', label: '总净重', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_tgweight', label: '总毛重', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_usd', label: '部件销售价($)', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_subtotal', label: '金额($)', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_price', label: '采购单价($)', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'podr_price', label: '采购金额($)', input: false, itemType: 'input', labelWidth: '150px' },
        { prop: 'prod_dusd', label: '报关单价', input: false, itemType: 'input', labelWidth: '150px' }
      ]
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 获取数据
    getList() {
      get(tranAPI.getTranPartGroup, { tran_id: this.tranForm.tran_id })
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(err => console.error(err));
    },
    PrintTotalFor34() {
      this.printDialogFlagFor34 = true;
    },
    // 刷新
    buttonRefresh() {
      this.getList();
    },
    clickPrintPart34() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.tranForm.tran_id;
          get(tranAPI.export_tran_part_34, { tran_id: str, type: 0, tran_part_list: this.tableData })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  window.open('/erpfile' + res.data.data.pdf_url + '.pdf');
                } else {
                  window.open('/file' + res.data.data.pdf_url + '.pdf');
                }

                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(err => console.error(err));
    },
    clickExportPart34() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          post(tranAPI.export_tran_part_34, { tran_id: str, type: 0, tran_part_list: this.tableData })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItem(res.data.data.pdf_url, `${res.data.data.fileName}.pdf`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    },
    //导出excel
    clickExportPartExcel34() {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          post(tranAPI.export_tran_part_34, { tran_id: str, type: 1, tran_part_list: this.tableData })
            .then(res => {
              if (res.data.code === 0) {
                this.helper.downloadItemExcel(res.data.data.pdf_url, `${res.data.data.fileName}.xls`);
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    },
    // 报关装箱单
    clickPrintBox34() {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isLoading = true;
          const str = this.tranForm.tran_id;
          post(tranAPI.exportPartPackAll34, { tran_id: str, type: 0, tran_part_list: this.tableData })
            .then(res => {
              if (res.data.code === 0) {
                if (process.env.NODE_ENV === 'production') {
                  res.data.data.forEach(item => window.open('/erpfile' + item.pdf_url + '.pdf'));
                } else {
                  res.data.data.forEach(item => window.open('/file' + item.pdf_url + '.pdf'));
                }
                this.isLoading = false;
                return;
              }
              this.$message.error(res.data.msg);
              this.isLoading = false;
            })
            .catch(res => {
              this.$message.error(res.data.msg);
              this.isLoading = false;
            });
        })
        .catch(err => console.error(err));
    },
    //导出 下载·
    clickExportBox34() {
      this.$confirm('确定下载PDF?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          post(tranAPI.exportPartPackAll34, { tran_id: str, type: 0, tran_part_list: this.tableData })
            .then(res => {
              if (res.data.code === 0) {
                res.data.data.forEach(item => this.helper.downloadItem(item.pdf_url, `${item.fileName}.pdf`));
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    },
    //导出 excel·
    clickExportBoxExcel34() {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const str = this.tranForm.tran_id;
          post(tranAPI.exportPartPackAll34, { tran_id: str, type: 1, tran_part_list: this.tableData })
            .then(res => {
              if (res.data.code === 0) {
                res.data.data.forEach(item => this.helper.downloadItemExcel(item.pdf_url, `${item.fileName}.xls`));
                return;
              }
              this.$message.error(res.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .showRow {
  display: none;
}

::v-deep .fixed-cell {
  //  color:red !important
  background-color: #ccc !important;
}

::v-deep .el-form-item.is-required .el-form-item__label:before {
  content: '';
  display: none;
}

::v-deep .vd_sortColor {
  color: rgb(0, 196, 250);
}

.vd_dirc {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
</style>
