<template>
  <div class="vg_wrapper">
    <el-row>
      <el-col :md="24" style="margin: 5px">
        <el-button size="small" type="success" icon="el-icon-check" @click="confirmSelect()" class="vg_ml_16">确认选择 </el-button>
        <el-button size="small" type="danger" icon="el-icon-delete" @click="notConfirmSelect()" class="vg_ml_16"> 取消选择 </el-button>
        <el-button size="small" type="info" icon="el-icon-refresh-right" @click="buttonRefresh()">刷新</el-button>
      </el-col>
    </el-row>
    <el-row class="vg_mt_16">
      <el-col :md="24">
        <search-table
          ref="multiTable"
          :data="tableData"
          :columns="tableProperties"
          :tableRowKey="`podr_part_id`"
          v-loading="loadFlag"
          :totalPage="totalPage"
          @getTableData="getDequsNow"
          :need-search="true"
          @selection-change="handleSelectionChange"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get } from '@api/request';
import { tranAPI } from '@api/modules/tran';
import pubPagination from '@/components/common/pubPagination';
import { getDateNoTime } from '@assets/js/dateUtils';
import { debounce } from 'lodash';
import SearchTable from '@/components/table/SearchTableV2.vue';

export default {
  name: 'DequList',
  components: {
    SearchTable,
    pubPagination
  },
  props: {
    tranForm: {
      type: Object
    }
  },
  data() {
    return {
      tableProperties: [
        { prop: 'selection', type: 'selection', labelWidth: '48px' },
        { prop: 'index', type: 'index', labelWidth: '50px', label: '序号' },
        { prop: 'cptt_no', label: '公司代码', itemType: 'input', input: false },
        { prop: 'scon_no', label: '外销合同号', itemType: 'input', input: false },
        { prop: 'cptt_aname_en', label: '公司抬头', itemType: 'input', input: false },
        { prop: 'scon_cust_no', label: '客户订单号', itemType: 'input', input: false },
        { prop: 'cust_abbr', label: '客户简称', itemType: 'input', input: false },
        {
          prop: 'scon_cndate',
          label: '合同日期',
          itemType: 'date',
          formatter: val => getDateNoTime(val, true),
          sortable: true,
          input: false
        },
        { prop: 'prod_cust_no', label: '客户货号', itemType: 'input', input: true },
        { prop: 'prod_no', label: '我司货号', itemType: 'input', input: false },
        { prop: 'prod_poststfix', label: '货号后缀', itemType: 'input', input: false },
        { prop: 'supp_abbr', label: '供应商简称', itemType: 'input', input: true },
        {
          prop: 'scon_shdate',
          label: '装运日期',
          itemType: 'date',
          formatter: val => getDateNoTime(val, true),
          sortable: true,
          input: false
        },
        { prop: 'cust_dport', label: '目的港', itemType: 'input', input: false },
        { prop: 'prod_num', label: '订单数量', itemType: 'input', sortable: true, input: false },
        { prop: 'podr_part_num', label: '采购数量', itemType: 'input', sortable: true, input: false },
        { prop: 'prod_ship_already', label: '已出运数量', itemType: 'input', sortable: true, input: false },
        { prop: 'prod_ship', label: '未出运数量', itemType: 'input', sortable: false, input: false },
        {
          prop: 'prod_tvol',
          label: '总体积',
          itemType: 'input',
          sortable: false,
          input: false,
          needOtherColumn: true
        }
      ],
      loadFlag: true,
      totalPage: 0,
      tableData: [],
      multiSelection: []
    };
  },
  created() {},
  mounted() {
    this.initData();
  },
  methods: {
    getDateNoTime,
    initData() {
      this.getDequsNow();
    },
    // 查询方法
    getDequsNow: debounce(function () {
      this.loadFlag = true;
      let searchForm = this.$refs.multiTable.searchForm;
      searchForm.tran_id = this.tranForm.tran_id;
      searchForm.cust_id = this.tranForm.cust_id;
      searchForm.cust_abbr = this.tranForm.cust_abbr;
      searchForm.scon_nos = this.tranForm.scon_nos;
      searchForm.scon_cust_nos = this.tranForm.scon_cust_nos;
      searchForm.podr_part_ids = this.tranForm.tran_part_list.map(item => item.podr_part_id).join(',');
      get(tranAPI.getSconProdList, searchForm).then(res => {
        if (res.data.code === 0) {
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          setTimeout(() => {
            this.loadFlag = false;
          }, 500);
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message.error({ message: mg, type: tp });
        }
      });
    }, 1000),
    // 刷新
    buttonRefresh() {
      this.loadFlag = true;
      this.$refs.multiTable.searchForm = { page_no: 1 };
      this.$refs.multiTable.$refs.multiTable.clearSelection();
      this.$refs.multiTable.$refs.pubPagination.currentPage = 1;
      this.initData();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
    },
    //确认选择
    confirmSelect() {
      this.$emit('importData', this.multiSelection);
    },
    //取消选择
    notConfirmSelect() {
      this.$refs.multiTable.clearSelection();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_posi_o {
  position: relative;
}

::v-deep .vd_posi_t {
  position: absolute;
  top: 10px;
  left: 30px;
  font-size: 24px;
  color: red;
}

::v-deep .fixed-cell {
  background-color: #ccc !important;
}

.vd_dirc {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.vd_sortColor {
  color: rgb(0, 196, 250);
}
</style>
