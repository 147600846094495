import { get, post } from '@api/request';
import { custAPI } from '@/api/modules/cust';
import { suppAPI } from '@/api/modules/supp';
import { stffAPI } from '@api/modules/staff';
import { deptAPI } from '@/api/modules/department';
import { cpttAPI } from '@/api/modules/comptitle';
//供应商信息
export const useSuppMixin = {
  data() {
    return {
      suppOptionList: []
    };
  },
  created() {
    this.initSupp();
  },
  methods: {
    initSupp() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppOptionList = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};

//客户信息
export const useCustMixin = {
  data() {
    return {
      custOptionList: []
    };
  },
  created() {
    this.initCust();
  },
  methods: {
    initCust() {
      get(custAPI.getAllCustsV1, { status: 2 })
        .then(res => {
          if (res.data.code === 0) {
            this.custOptionList = res.data.data.list;
            return;
          }
          this.$message.error(res.data.code);
        })
        .catch(res => {
          this.$message.error(res.data.code);
        });
    }
  }
};

//员工信息
export const useStffMixin = {
  data() {
    return {
      stffOptionList: []
    };
  },
  created() {
    this.initStff();
  },
  methods: {
    initStff() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffOptionList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    }
  }
};

//部门信息
export const useDeptMixin = {
  data() {
    return {
      deptOptionList: []
    };
  },
  created() {
    this.initDept();
  },
  methods: {
    initDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptOptionList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    }
  }
};

//公司信息
export const useCpttMixin = {
  data() {
    return {
      cpttOptionList: []
    };
  },
  created() {
    this.initCptt();
  },
  methods: {
    initCptt() {
      get(cpttAPI.getAllCpttsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.cpttOptionList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    }
  }
};
